@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-sortPicker {
  background: var(--pbg3);
  margin: auto;
  width: 90%;
  min-height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.26667rem; }
  .module-sortPicker .sp-title {
    height: 1.49333rem;
    line-height: 1.49333rem;
    font-family: 'DIN,DIN-Medium';
    font-size: 0.48rem;
    color: var(--active);
    text-align: left;
    padding-left: 0.66667rem;
    border-bottom: 1px solid var(--pLine2); }
  .module-sortPicker .sp-listBox {
    margin-top: 0.53333rem; }
    .module-sortPicker .sp-listBox .sp-listBox-cellBox {
      margin-bottom: 0.53333rem;
      padding: 0 0.66667rem; }
      .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell {
        display: flex;
        align-items: center; }
        .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell .sp-listBox-cellBox-cell-tick {
          width: 0.32rem;
          height: 0.32rem;
          background: var(--pbg1);
          display: flex; }
          .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell .sp-listBox-cellBox-cell-tick img {
            width: 0.24rem; }
        .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell .sp-listBox-cellBox-cell-icon {
          margin-left: 0.2rem;
          width: 0.32rem;
          display: flex; }
          .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell .sp-listBox-cellBox-cell-icon.FIFA {
            width: 0.8rem; }
          .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell .sp-listBox-cellBox-cell-icon img {
            width: 100%; }
        .module-sortPicker .sp-listBox .sp-listBox-cellBox .sp-listBox-cellBox-cell .sp-listBox-cellBox-cell-text {
          font-family: 'DIN,DIN-Medium';
          font-size: 0.32rem;
          color: var(--pFont1);
          margin-left: 0.13333rem; }
  .module-sortPicker .sp-btnBox {
    padding: 0.4rem 0.66667rem 0;
    margin-top: 0.4rem;
    margin-bottom: 0.26667rem;
    border-top: 1px solid var(--pLine2); }
    .module-sortPicker .sp-btnBox .sp-btnBox-submit {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      height: 0.90667rem;
      line-height: 0.90667rem;
      border-radius: 0.04rem;
      background: var(--active);
      color: var(--pFont6);
      text-align: center; }
    .module-sortPicker .sp-btnBox .sp-btnBox-reset {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      height: 0.90667rem;
      line-height: 0.90667rem;
      border-radius: 0.04rem;
      background: transparent;
      color: var(--active);
      text-align: center; }
