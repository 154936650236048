@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  padding: 0 0.4rem;
  left: 0;
  right: 0;
  top: 0;
  height: 1.17333rem;
  z-index: 10;
  background-color: var(--bg1); }
  .module-header.position-detail {
    background-color: #fff; }
  .module-header.order-header {
    background-color: var(--bg3); }
  .module-header.white {
    background: var(--fff-bg); }
  .module-header.bg {
    background-color: transparent; }
    .module-header.bg .center, .module-header.bg .centerTab {
      color: var(--fff); }
  .module-header.safeView1 {
    top: 1.06667rem; }
  .module-header.page {
    background: transparent; }
  .module-header .left {
    position: absolute;
    left: 0.4rem;
    top: 0;
    height: 1.2rem;
    width: 2.66667rem;
    display: flex;
    align-items: center; }
    .module-header .left .imageBox {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      fill: var(--bg3); }
      .module-header .left .imageBox .C-Svg {
        flex-direction: row;
        justify-content: flex-start;
        zoom: 0.5; }
        .module-header .left .imageBox .C-Svg > div {
          display: flex; }
  .module-header .center, .module-header .centerTab {
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
    z-index: 100;
    position: relative;
    text-align: center;
    color: var(--headerFont);
    margin: 0 0.53333rem; }
  .module-header .centerTab {
    color: var(--fff); }
  .module-header .imageBox {
    fill: var(--fff); }
  .module-header .right {
    position: absolute;
    right: 0.26667rem; }

@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .include_ru {
    top: 1.06667rem; } }
