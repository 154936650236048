@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.am-action-sheet.am-action-sheet-normal.depthGroup-modal {
  background: transparent !important; }
  .am-action-sheet.am-action-sheet-normal.depthGroup-modal .am-action-sheet-body .am-action-sheet-button-list {
    border-top-left-radius: 0.64rem;
    border-top-right-radius: 0.64rem;
    overflow: hidden; }
    .am-action-sheet.am-action-sheet-normal.depthGroup-modal .am-action-sheet-body .am-action-sheet-button-list .am-action-sheet-button-list-item {
      background: var(--bg16);
      color: var(--mainFont);
      font-size: 0.42667rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500; }
      .am-action-sheet.am-action-sheet-normal.depthGroup-modal .am-action-sheet-body .am-action-sheet-button-list .am-action-sheet-button-list-item:before {
        background: var(#23262F, var(--t8)); }
      .am-action-sheet.am-action-sheet-normal.depthGroup-modal .am-action-sheet-body .am-action-sheet-button-list .am-action-sheet-button-list-item.am-action-sheet-destructive-button {
        color: var(--active); }
