@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.alertModel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: table; }
  .alertModel .alertCell {
    display: table-cell;
    vertical-align: middle; }
  .alertModel .alertBody {
    width: 80%;
    margin: 0 auto;
    min-height: 4.32rem;
    background: var(--fff-bg);
    border-radius: 0.13333rem; }
    .alertModel .alertBody .alertTitle {
      position: relative;
      text-align: center;
      font-size: 0rem; }
      .alertModel .alertBody .alertTitle .close {
        position: absolute;
        right: 0.4rem;
        top: 0.26667rem; }
    .alertModel .alertBody .alertMain {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      font-size: 0.42667rem;
      line-height: 0.42667rem;
      font-weight: 500;
      padding: 0.53333rem;
      flex: 1;
      min-height: 3.36rem;
      background: var(--fff-bg);
      color: var(--night-font1, var(--f5));
      border-bottom: 1px solid var(--night-inputBg, #f5f5f5);
      font-family: 'DIN,DIN-Medium'; }
      .alertModel .alertBody .alertMain input {
        border: 1px solid var(--f8);
        color: var(--f5); }
        .alertModel .alertBody .alertMain input::placeholder {
          color: var(--f9); }
      .alertModel .alertBody .alertMain .module-buy .module-buy-title {
        text-align: left;
        font-size: 0.4rem;
        color: var(--active);
        padding-bottom: 0.26667rem; }
      .alertModel .alertBody .alertMain .module-buy .module-buy-content {
        padding-top: 0.26667rem; }
        .alertModel .alertBody .alertMain .module-buy .module-buy-content .infoBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.8rem;
          line-height: 0.8rem; }
          .alertModel .alertBody .alertMain .module-buy .module-buy-content .infoBox .title {
            font-size: 0.34667rem; }
          .alertModel .alertBody .alertMain .module-buy .module-buy-content .infoBox .content {
            font-size: 0.34667rem; }
      .alertModel .alertBody .alertMain .module-buy .module-buy-finally {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
        line-height: 0.8rem;
        border-top: 1px solid rgba(39, 53, 79, 0.1);
        padding-top: 0.53333rem;
        margin-top: 0.26667rem; }
        .alertModel .alertBody .alertMain .module-buy .module-buy-finally .title {
          font-size: 0.34667rem; }
        .alertModel .alertBody .alertMain .module-buy .module-buy-finally .content {
          font-size: 0.34667rem; }
      .alertModel .alertBody .alertMain .alert-feeBox div {
        margin: 0 0.13333rem; }
      .alertModel .alertBody .alertMain .alert-feeBox .money {
        color: var(--f5);
        font-weight: bold; }
    .alertModel .alertBody .alertFoot {
      height: 1.17333rem;
      line-height: 1.17333rem;
      display: flex; }
      .alertModel .alertBody .alertFoot .alertButton {
        flex: 1;
        text-align: center;
        font-size: 0.42667rem;
        height: 1.17333rem;
        align-self: center; }
        .alertModel .alertBody .alertFoot .alertButton.active {
          height: 100%;
          color: var(--t6);
          background: var(--active); }
        .alertModel .alertBody .alertFoot .alertButton.cancel {
          height: 100%;
          color: var(--active);
          background: var(--fff-bg); }
  .alertModel .transfer {
    animation: animate 0.3s ease-out; }

@keyframes animate {
  0% {
    transform: translateY(100vh); }
  50% {
    transform: translateY(50vh); }
  100% {
    transform: translateY(0); } }
  .alertModel .move-out {
    animation: out 0.2s ease-in forwards; }

@keyframes out {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100vh); } }
