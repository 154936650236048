@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  display: flex;
  flex-direction: column;
  overflow: auto; }
  .page .header {
    background-color: var(--fff-bg); }
  .page .wrapper {
    padding-top: 1.17333rem;
    height: 100vh; }
    .page .wrapper img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: fill; }
  .page .footer {
    width: 100%;
    height: 1.6rem;
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0; }
    .page .footer .submitBtn {
      width: 100%;
      height: 1.2rem;
      line-height: 1.2rem;
      border-radius: 1.33333rem;
      color: var(--fff);
      text-align: center; }
