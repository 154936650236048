@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  background: #2e313a; }
  .page .header {
    background-color: var(--fff-bg); }
  .page .wrapper {
    margin-top: 1.17333rem;
    position: relative; }
    .page .wrapper img {
      pointer-events: none;
      width: 100%;
      display: block;
      height: 100%;
      object-fit: fill; }
    .page .wrapper .btn {
      position: absolute;
      z-index: 9999999999;
      left: 17%;
      top: 48%;
      width: 70%;
      height: 10%;
      color: black;
      opacity: 0;
      font-size: 10px; }
    .page .wrapper .btn2 {
      position: absolute;
      z-index: 9999999999;
      left: 0%;
      bottom: 0%;
      width: 100%;
      height: 6%;
      color: black;
      opacity: 0;
      font-size: 10px; }
