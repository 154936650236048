@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-positionView {
  height: 100%; }
  .module-positionView .pv-list {
    list-style: none;
    position: relative;
    min-height: 30vh; }
    .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox {
      margin-top: 0.53333rem;
      display: flex;
      align-items: center; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-side {
        font-size: 0.29333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #fff;
        display: inline-block;
        border-radius: 0.05333rem;
        margin-right: 0.13333rem;
        padding: 0 0.13333rem; }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-side.buy {
          background: var(--f1); }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-side.sell {
          background: var(--r1); }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-name {
        font-size: 0.42667rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        display: inline-block;
        margin-right: 0.21333rem;
        color: var(--mainFont); }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-type {
        font-size: 0.29333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #141416;
        padding: 2px 5px;
        display: inline-block;
        background: #FFD140;
        border-radius: 0.05333rem; }
    .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox {
      display: flex;
      justify-content: space-between;
      margin-top: 0.21333rem; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-title {
        color: #777E90;
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        line-height: 1; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-content {
        font-size: 0.48rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        line-height: 1;
        margin-top: 0.21333rem; }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-content.raise {
          color: var(--f1); }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-content.fall {
          color: var(--r1); }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit:last-child .pv-list-cellBox-cell-profitBox-profit-title {
        text-align: right; }
    .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox {
      margin-top: 0.08rem; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data {
        display: inline-block;
        width: 33%;
        margin-top: 0.32rem; }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-title {
          color: #777E90;
          font-size: 0.32rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          line-height: 1; }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-content {
          color: #E6E8EC;
          font-size: 0.32rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          line-height: 1;
          margin-top: 0.10667rem;
          font-family: 'DIN,DIN-Medium'; }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-margin {
          line-height: 1; }
          .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-margin .pv-list-cellBox-cell-dataBox-data-title {
            display: inline-block;
            position: relative; }
          .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-margin .pv-list-cellBox-cell-dataBox-data-btn {
            content: '+';
            color: #E6E8EC;
            border: 1px solid #E6E8EC;
            width: 0.4rem;
            height: 0.4rem;
            position: absolute;
            right: -0.53333rem;
            text-align: center; }
          .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-margin .pv-list-cellBox-cell-dataBox-data-margin-image {
            display: inline-block;
            border: 1px solid #E6E8EC;
            border-color: #E6E8EC;
            color: #E6E8EC;
            width: 0.26667rem;
            height: 0.26667rem;
            padding: 0.05333rem; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data:nth-child(3n) {
        text-align: right; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data:nth-child(3n-1) {
        padding-left: 0.53333rem; }
    .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-buttonBox {
      display: flex;
      margin-top: 0.21333rem;
      margin-bottom: 0.53333rem; }
      .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-buttonBox .pv-list-cellBox-cell-buttonBox-button {
        flex: 1;
        background: var(--bg18);
        color: var(--mainFont);
        border-radius: 0.08rem;
        font-size: 0.30667rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        padding: 0.18667rem 0.13333rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.17333rem;
        text-align: center; }
        .module-positionView .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-buttonBox .pv-list-cellBox-cell-buttonBox-button:last-child {
          margin-right: 0; }
    .module-positionView .pv-list .no_login {
      position: absolute;
      top: 2.13333rem;
      left: 50%;
      transform: translateX(-50%); }
      .module-positionView .pv-list .no_login a {
        text-decoration: underline;
        color: var(--active);
        margin: 0 0.13333rem; }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-side.buy {
  background: var(--f1); }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-infoBox .pv-list-cellBox-cell-infoBox-side.sell {
  background: var(--r1); }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-title {
  color: #B6BCC7; }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-content.raise {
  color: var(--f1); }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-profitBox .pv-list-cellBox-cell-profitBox-profit .pv-list-cellBox-cell-profitBox-profit-content.fall {
  color: var(--r1); }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-title {
  color: #B6BCC7; }

.module-positionView.light .pv-list .pv-list-cellBox .pv-list-cellBox-cell .pv-list-cellBox-cell-dataBox .pv-list-cellBox-cell-dataBox-data .pv-list-cellBox-cell-dataBox-data-content {
  color: #1E2129; }
