@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.coin-popover {
  left: 0.46667rem;
  right: 0.46667rem; }
  .coin-popover .cp-cell .am-popover-item-content {
    display: flex; }
    .coin-popover .cp-cell .am-popover-item-content .cp-cell-image {
      width: 0.32rem;
      display: flex; }
      .coin-popover .cp-cell .am-popover-item-content .cp-cell-image img {
        width: 100%; }
    .coin-popover .cp-cell .am-popover-item-content .cp-cell-title {
      font-family: 'DIN,DIN-Medium';
      font-size: 0.37333rem;
      color: var(--pFont4);
      margin-left: 0.13333rem; }

.module-coinPicker {
  padding: 0 0.4rem;
  margin-top: 0.4rem; }
  .module-coinPicker .cp-title {
    font-family: 'DIN,DIN-Medium';
    font-size: 0.37333rem;
    color: var(--pFont1); }
  .module-coinPicker .cp-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--pbg1);
    height: 0.90667rem;
    padding: 0 0.4rem; }
    .module-coinPicker .cp-btn .cp-btn-title {
      font-family: 'DIN,DIN-Medium';
      font-size: 0.37333rem;
      color: var(--pFont4);
      display: flex; }
      .module-coinPicker .cp-btn .cp-btn-title .cp-btn-title-image {
        width: 0.32rem;
        margin-right: 0.26667rem;
        display: flex; }
        .module-coinPicker .cp-btn .cp-btn-title .cp-btn-title-image img {
          width: 100%; }
    .module-coinPicker .cp-btn .cp-btn-image {
      width: 0.21333rem; }
      .module-coinPicker .cp-btn .cp-btn-image img {
        width: 100%; }
