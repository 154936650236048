@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

@keyframes modalShow {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes contentShow {
  from {
    bottom: 0; }
  to {
    bottom: 50%; } }

.wholePage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  animation: modalShow forwards 100ms linear; }
  .wholePage .page {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 60%;
    transform: translateX(-50%) translateY(50%);
    animation: contentShow forwards 250ms ease-out;
    padding: 0.53333rem;
    background-color: var(--bg2);
    color: var(--mainFont);
    border-radius: 0.26667rem;
    font-family: var(--pMFont);
    font-size: 0.37333rem;
    font-weight: 100;
    text-align: center; }
    .wholePage .page .header {
      font-family: 'DIN,DIN-Medium';
      font-size: 0.42667rem;
      line-height: 0.82667rem;
      padding-bottom: 0.26667rem; }
    .wholePage .page .btn {
      margin: auto;
      width: 2.16rem;
      height: 0.82667rem;
      padding: 0.13333rem 0.4rem;
      border-radius: 0.13333rem;
      background: var(--active);
      color: #141416; }
