@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-statistics {
  background: var(--pbg3);
  margin: 0 0.88rem;
  max-width: 90%;
  min-width: 8.24rem;
  display: flex;
  flex-direction: column; }
  .module-statistics .ms-title {
    height: 1.49333rem;
    border-bottom: 1px solid var(--pLine2);
    color: var(--active);
    font-size: 0.42667rem;
    font-family: 'DIN,DIN-Medium';
    display: flex;
    align-items: center;
    padding-left: 0.66667rem; }
  .module-statistics .ms-row {
    display: flex;
    justify-content: space-between;
    padding: 0 0.66667rem;
    margin-bottom: 0.4rem; }
    .module-statistics .ms-row .ms-row-title {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      color: var(--pFont1);
      flex: 1; }
    .module-statistics .ms-row .ms-row-content {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      color: var(--pFont4);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
  .module-statistics .ms-line {
    height: 1px;
    background: var(--pLine2);
    margin: 0 0.13333rem 0.4rem; }
  .module-statistics .ms-bottom {
    padding-bottom: 0.53333rem;
    border-top: 1px solid var(--pLine2); }
    .module-statistics .ms-bottom .ms-bottom-btn {
      height: 0.90667rem;
      background: var(--active);
      color: var(--pFont6);
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      margin: 0.4rem 0.66667rem 0;
      border-radius: 0.04rem;
      display: flex;
      align-items: center;
      justify-content: center; }
