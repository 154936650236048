@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.am-popover-arrow {
  left: 1.68rem !important; }

.popup {
  width: 80%;
  border-radius: 0.16rem;
  background-color: var(--fff-bg);
  padding: 0 0.72rem 0.66667rem 0.72rem; }
  .popup .title {
    font-size: 0.48rem;
    font-weight: 400;
    color: var(--active);
    margin: 0.53333rem 0;
    margin-bottom: 0.4rem;
    line-height: 0.48rem; }
  .popup .popContent {
    margin-top: 0.32rem;
    font-size: 0.37333rem;
    line-height: 0.37333rem; }
    .popup .popContent p {
      font-size: 0.32rem;
      font-weight: 400;
      color: var(--night-font1, var(--f5)); }
      .popup .popContent p i {
        font-family: 'DIN,DIN-Medium';
        font-weight: 500; }
      .popup .popContent p.settingsContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.21333rem; }
      .popup .popContent p.billingContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.21333rem; }
      .popup .popContent p.btm {
        padding-bottom: 0.26667rem;
        border-bottom: 0.02667rem solid var(--bg6); }
    .popup .popContent div {
      padding-left: 0;
      padding-right: 0; }
    .popup .popContent .billingRecord {
      height: 40vh;
      overflow: auto;
      scroll-behavior: smooth; }
  .popup .popFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.26667rem; }
    .popup .popFooter .confirmBtn {
      width: 100%;
      line-height: 1.01333rem;
      border-radius: 0.10667rem;
      margin-top: 0.26667rem;
      text-align: center;
      background: var(--active);
      font-size: 0.4rem;
      font-weight: 500;
      color: var(--btn-active-color); }
    .popup .popFooter .cancelBtn {
      width: 100%;
      line-height: 1.01333rem;
      border-radius: 0.10667rem;
      margin-top: 0.13333rem;
      text-align: center;
      background: var(--fff-bg);
      font-size: 0.4rem;
      font-weight: 500;
      color: var(--active); }

.list3 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.32rem; }
  .list3 .container {
    width: 100%;
    position: relative;
    min-height: 6.66667rem; }
  .list3 .empty {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 0.53333rem; }
  .list3 .item {
    width: 100%;
    padding: 0.26667rem 0;
    border-bottom: 0.02667rem solid var(--bg6); }
    .list3 .item .wrap {
      width: 100%;
      margin: 0.26667rem 0; }
      .list3 .item .wrap .item2, .list3 .item .wrap .item3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0.32rem; }
        .list3 .item .wrap .item2 .com1, .list3 .item .wrap .item3 .com1 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          flex: 1;
          font-size: 0.32rem;
          line-height: 0.32rem;
          font-weight: 400;
          color: var(--mainFont10);
          font-family: var(--pRFont);
          white-space: nowrap; }
          .list3 .item .wrap .item2 .com1 p:last-child, .list3 .item .wrap .item3 .com1 p:last-child {
            margin-top: 0.10667rem;
            font-family: 'DIN,DIN-Medium';
            font-weight: 500;
            color: var(--mainFontActive); }
          .list3 .item .wrap .item2 .com1 .grey, .list3 .item .wrap .item3 .com1 .grey {
            color: var(--mainFont10); }
          .list3 .item .wrap .item2 .com1 .incomeUp, .list3 .item .wrap .item3 .com1 .incomeUp {
            color: var(--f1) !important; }
          .list3 .item .wrap .item2 .com1 .incomeDown, .list3 .item .wrap .item3 .com1 .incomeDown {
            color: var(--r1) !important; }
          .list3 .item .wrap .item2 .com1 .count, .list3 .item .wrap .item3 .com1 .count {
            font-size: 0.32rem;
            line-height: 0.32rem;
            font-weight: 400;
            font-family: var(--pRFont);
            color: var(--mainFontActive); }
            .list3 .item .wrap .item2 .com1 .count i, .list3 .item .wrap .item3 .com1 .count i {
              font-family: 'DIN,DIN-Medium';
              font-weight: 500;
              color: var(--active);
              margin: 0 0.08rem; }
          .list3 .item .wrap .item2 .com1:last-child, .list3 .item .wrap .item3 .com1:last-child {
            align-items: flex-end; }
    .list3 .item .com2 {
      text-align: center;
      color: var(--mainFont3); }
    .list3 .item .com22 {
      width: 0.66667rem;
      text-align: left;
      color: var(--f1);
      font-size: 0.37333rem;
      font-weight: bold; }
    .list3 .item .item1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between; }
      .list3 .item .item1 div:nth-child(2) {
        color: var(--mainFont3);
        white-space: nowrap; }
      .list3 .item .item1 .item11 {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--mainFont3);
        white-space: nowrap; }
        .list3 .item .item1 .item11 :nth-child(1) {
          font-size: 0.42667rem;
          line-height: 0.42667rem;
          font-weight: 500;
          white-space: nowrap; }
        .list3 .item .item1 .item11 :nth-child(2) {
          display: inline-block;
          padding: 0.05333rem 0.10667rem;
          font-size: 0.29333rem;
          line-height: 0.29333rem;
          font-weight: 500;
          border-radius: 0.05333rem;
          margin-left: 0.13333rem;
          color: var(--fff); }
          .list3 .item .item1 .item11 :nth-child(2).up, .list3 .item .item1 .item11 .moduleBottom .holdList .listCon .holdRender .renderTop .down:nth-child(2), .moduleBottom .holdList .listCon .holdRender .renderTop .list3 .item .item1 .item11 .down:nth-child(2) {
            background-color: var(--f1); }
          .list3 .item .item1 .item11 :nth-child(2).down {
            background-color: var(--r1); }
    .list3 .item .item3 {
      margin-top: 0.26667rem; }

.moduleBottom {
  margin-top: 0.26667rem; }
  .moduleBottom .gameTabs {
    padding: 0.4rem 0.4rem 0.26667rem;
    border-bottom: 0.02667rem solid var(--bg6); }
  .moduleBottom .buyBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    background: var(--bg1);
    bottom: 0;
    z-index: 10;
    padding: 0 0.4rem;
    height: 1.33333rem;
    gap: 0.26667rem; }
    .moduleBottom .buyBox .buyButton, .moduleBottom .buyBox .down, .moduleBottom .buyBox .up, .moduleBottom .buyBox .holdList .listCon .holdRender .renderTop .down, .moduleBottom .holdList .listCon .holdRender .renderTop .buyBox .down {
      width: 50%;
      height: 1.01333rem;
      border-radius: 0.10667rem;
      margin: 0;
      color: var(--fff);
      text-align: center;
      font-size: 0.42667rem;
      line-height: 0.42667rem;
      font-weight: 500;
      line-height: 1.06667rem;
      font-family: var(--pMFont); }
    .moduleBottom .buyBox .down {
      background-color: var(--f1); }
    .moduleBottom .buyBox .up, .moduleBottom .buyBox .holdList .listCon .holdRender .renderTop .down, .moduleBottom .holdList .listCon .holdRender .renderTop .buyBox .down {
      background-color: var(--r1); }
  .moduleBottom .main1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--fff-bg);
    padding-bottom: 1.33333rem; }
    .moduleBottom .main1 .upDownCon {
      display: flex;
      flex-direction: column;
      height: 2.13333rem;
      width: 100%;
      padding: 0.26667rem 0.4rem;
      border-bottom: 1px solid var(--bg1); }
      .moduleBottom .main1 .upDownCon p {
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--mainFont3); }
        .moduleBottom .main1 .upDownCon p em {
          font-family: 'DIN,DIN-Medium';
          color: var(--active);
          font-size: 0.42667rem;
          line-height: 0.42667rem;
          font-weight: 500;
          margin-right: 0.13333rem; }
      .moduleBottom .main1 .upDownCon .listRender {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.26667rem; }
        .moduleBottom .main1 .upDownCon .listRender .itemRender {
          width: 1.65333rem;
          height: 0.74667rem;
          text-align: center;
          line-height: 0.74667rem;
          color: var(--f1);
          border-radius: 0.10667rem;
          border: 1px solid var(--f1); }
        .moduleBottom .main1 .upDownCon .listRender .itemRender1 {
          width: 1.65333rem;
          height: 0.74667rem;
          text-align: center;
          line-height: 0.74667rem;
          color: var(--r1);
          border-radius: 0.10667rem;
          background: var(--night-bg3, #fbfbfb);
          border: 1px solid var(--r1); }
        .moduleBottom .main1 .upDownCon .listRender .normalCash {
          font-family: 'DIN,DIN-Medium';
          width: 2.88rem;
          height: 0.69333rem;
          background: var(--bg1);
          text-align: center;
          line-height: 0.69333rem;
          border-radius: 0.10667rem;
          color: var(--mainFont3); }
        .moduleBottom .main1 .upDownCon .listRender .normalCash1 {
          font-family: 'DIN,DIN-Medium';
          width: 1.46667rem;
          height: 0.74667rem;
          background: var(--night-bg3, #fbfbfb);
          text-align: center;
          line-height: 0.74667rem;
          border-radius: 0.10667rem;
          color: var(--mainFont3); }
        .moduleBottom .main1 .upDownCon .listRender .selectCash {
          font-family: 'DIN,DIN-Medium';
          width: 2.88rem;
          height: 0.74667rem;
          background: var(--night-bg3, #fbfbfb);
          text-align: center;
          line-height: 0.69333rem;
          border-radius: 0.10667rem;
          color: var(--active);
          border: 1px solid var(--active); }
        .moduleBottom .main1 .upDownCon .listRender .selectCash1 {
          font-family: 'DIN,DIN-Medium';
          width: 1.46667rem;
          height: 0.74667rem;
          background: var(--fff-bg);
          text-align: center;
          line-height: 0.69333rem;
          border-radius: 0.10667rem;
          color: var(--active);
          border: 1px solid var(--active); }
    .moduleBottom .main1 .cashCon {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin: 0.13333rem 0; }
      .moduleBottom .main1 .cashCon .cashList {
        display: flex;
        height: 0.85333rem;
        line-height: 0.85333rem;
        font-size: 0.32rem;
        text-align: center; }
        .moduleBottom .main1 .cashCon .cashList .normalCash {
          width: 1.28rem;
          border: 0.02667rem solid var(--br);
          color: var(--f5);
          box-sizing: border-box;
          border-right: 0; }
          .moduleBottom .main1 .cashCon .cashList .normalCash:last-child {
            border-right: 0.02667rem solid var(--br); }
        .moduleBottom .main1 .cashCon .cashList .selectCash {
          width: 1.28rem;
          background-color: var(--bg7);
          border: 0.02667rem solid var(--bg7); }
    .moduleBottom .main1 .prizeCon {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 0rem 0.4rem;
      font-size: 0.32rem;
      height: 1.17333rem;
      color: var(--mainFont3); }
      .moduleBottom .main1 .prizeCon :nth-child(2) {
        font-family: 'DIN,DIN-Medium';
        color: var(--f5); }
      .moduleBottom .main1 .prizeCon img {
        margin-top: -0.05333rem; }
    .moduleBottom .main1 .balanceCon {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin: 0.13333rem 0;
      font-size: 0.37333rem;
      font-weight: 400; }
      .moduleBottom .main1 .balanceCon p:last-child {
        font-size: 0.4rem;
        font-weight: 500;
        color: var(--f5); }
  @media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
    .moduleBottom .buyBox {
      height: 1.73333rem;
      padding-bottom: 0.26667rem;
      padding-top: 0.13333rem; }
    .moduleBottom .main1 {
      padding-bottom: 1.73333rem; } }
  .moduleBottom .holdList {
    margin-top: 0.26667rem;
    height: 300px; }
    .moduleBottom .holdList .holdTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 1.2rem;
      padding: 0 0.26667rem;
      margin-bottom: 0.02667rem;
      background-color: var(--fff-bg); }
      .moduleBottom .holdList .holdTitle .leftTxt {
        font-size: 0.4rem;
        font-weight: bold;
        color: var(--f5); }
      .moduleBottom .holdList .holdTitle .rightCon {
        display: flex;
        align-items: center; }
        .moduleBottom .holdList .holdTitle .rightCon p {
          margin-right: 0.13333rem; }
    .moduleBottom .holdList .listCon {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .moduleBottom .holdList .listCon .holdRender {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 0 0.26667rem;
        height: 1.86667rem;
        background-color: var(--fff-bg);
        margin-bottom: 0.02667rem; }
        .moduleBottom .holdList .listCon .holdRender .renderTop {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: flex-start;
          height: 50%; }
          .moduleBottom .holdList .listCon .holdRender .renderTop .left {
            font-size: 0.42667rem;
            font-weight: bold;
            color: var(--f5); }
          .moduleBottom .holdList .listCon .holdRender .renderTop .up, .moduleBottom .holdList .listCon .holdRender .renderTop .down {
            width: 0.8rem;
            height: 0.45333rem;
            border: 0.02667rem solid var(--f1);
            color: var(--f1);
            text-align: center;
            font-size: 0.32rem;
            line-height: 0.45333rem;
            border-radius: 0.05333rem;
            margin-left: 0.13333rem; }
          .moduleBottom .holdList .listCon .holdRender .renderTop .down {
            color: var(--r1);
            border: 0.02667rem solid var(--r1); }
          .moduleBottom .holdList .listCon .holdRender .renderTop .countDonw {
            margin-left: auto; }
            .moduleBottom .holdList .listCon .holdRender .renderTop .countDonw i {
              width: 0.50667rem;
              height: 0.42667rem;
              background: #5D95FF;
              border-radius: 0.05333rem;
              color: var(--fff);
              font-size: 0.32rem; }
        .moduleBottom .holdList .listCon .holdRender .renderBottom {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: flex-start; }
          .moduleBottom .holdList .listCon .holdRender .renderBottom .openPrice, .moduleBottom .holdList .listCon .holdRender .renderBottom .curPrice {
            font-size: 0.37333rem;
            font-weight: 400;
            color: var(--mainFont3); }
            .moduleBottom .holdList .listCon .holdRender .renderBottom .openPrice i, .moduleBottom .holdList .listCon .holdRender .renderBottom .curPrice i {
              margin-left: 0.13333rem;
              color: var(--f5); }
          .moduleBottom .holdList .listCon .holdRender .renderBottom .curPrice {
            margin-left: 0.53333rem; }
          .moduleBottom .holdList .listCon .holdRender .renderBottom .curUp, .moduleBottom .holdList .listCon .holdRender .renderBottom .curDown {
            margin-left: auto;
            font-size: 0.42667rem;
            font-weight: 500;
            color: var(--f1); }
          .moduleBottom .holdList .listCon .holdRender .renderBottom .curDown {
            color: var(--r1); }
  .moduleBottom-content {
    display: flex;
    justify-content: center; }
    .moduleBottom-content .module-bottom-content-left {
      flex: 0.62;
      background: var(--fff-bg); }
      .moduleBottom-content .module-bottom-content-left .typeBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 0 0.4rem;
        position: relative;
        height: 1.14667rem; }
        .moduleBottom-content .module-bottom-content-left .typeBox .button {
          margin-left: 0.26667rem; }
        .moduleBottom-content .module-bottom-content-left .typeBox .select_mode {
          font-size: 0.37333rem;
          color: var(--f5);
          font-weight: bold;
          position: relative;
          height: 0.96rem;
          display: flex;
          align-items: center; }
          .moduleBottom-content .module-bottom-content-left .typeBox .select_mode::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0.53333rem;
            right: -0.4rem;
            transform: rotate(135deg);
            border: 0.13333rem solid transparent;
            border-bottom-color: var(--f5);
            z-index: 1; }
        .moduleBottom-content .module-bottom-content-left .typeBox .right-detail {
          font-size: 0.32rem;
          color: var(--f5);
          font-weight: bold; }
        .moduleBottom-content .module-bottom-content-left .typeBox .active {
          color: var(--active); }
      .moduleBottom-content .module-bottom-content-left .setting {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0.37333rem 0 0.26667rem 0.4rem;
        height: 0.96rem;
        border: 0.02667rem solid var(--bg6);
        color: var(--f5);
        font-size: 0.37333rem;
        font-weight: bold; }
      .moduleBottom-content .module-bottom-content-left .configBox {
        display: flex;
        position: relative;
        margin: 0rem 0 0.26667rem 0.4rem; }
        .moduleBottom-content .module-bottom-content-left .configBox .inputBox {
          flex: 1;
          margin: 0rem;
          background: var(--fff-bg); }
        .moduleBottom-content .module-bottom-content-left .configBox .levelBox {
          display: flex;
          justify-content: space-between;
          height: 0.98667rem;
          border: 1px solid var(--br);
          background: var(--fff-bg);
          border-radius: 2px;
          flex: 1; }
          .moduleBottom-content .module-bottom-content-left .configBox .levelBox .l-title {
            font-size: 0.34667rem;
            color: var(--f5);
            display: flex;
            align-items: center;
            margin-left: 0.13333rem; }
          .moduleBottom-content .module-bottom-content-left .configBox .levelBox .l-content {
            font-size: 0.37333rem;
            color: var(--fff);
            display: flex;
            align-items: center;
            padding-right: 0.66667rem;
            position: relative; }
            .moduleBottom-content .module-bottom-content-left .configBox .levelBox .l-content .lever {
              font-size: 0.37333rem;
              color: var(--fff); }
          .moduleBottom-content .module-bottom-content-left .configBox .levelBox .l-content:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5.5px 0 5.5px;
            border-color: var(--fff) transparent transparent transparent;
            position: absolute;
            right: 0.26667rem; }
          .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox {
            position: absolute;
            bottom: -3.81333rem;
            left: 0.26667rem;
            right: 0.26667rem;
            background: rgba(23, 32, 52, 0.2);
            border: 1px solid rgba(104, 132, 128, 0.2);
            border-radius: 0.10667rem;
            z-index: 2; }
            .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox .sl-title {
              font-size: 0.4rem;
              font-weight: 400;
              margin: 0.32rem;
              color: var(--fff); }
            .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox .selectLeverList {
              display: flex;
              flex-wrap: wrap;
              list-style: none;
              padding-left: 0;
              margin-bottom: 0; }
              .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox .selectLeverList .sl-cell {
                width: 33%;
                margin-bottom: 0.34667rem; }
                .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox .selectLeverList .sl-cell .sl-cell-content {
                  width: 2.53333rem;
                  height: 0.82667rem;
                  border: 1px solid var(--bg4);
                  border-radius: 4px;
                  margin: auto;
                  text-align: center;
                  line-height: 0.82667rem; }
                .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox .selectLeverList .sl-cell .cell-active {
                  border-color: var(--active); }
          .moduleBottom-content .module-bottom-content-left .configBox .levelBox .selectLeverBox-hidden {
            visibility: hidden; }
          .moduleBottom-content .module-bottom-content-left .configBox .levelBox .upTriangle:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 5.5px 6px 5.5px;
            border-color: transparent transparent var(--bg4) transparent;
            position: absolute;
            right: 25%;
            top: -7px; }
      .moduleBottom-content .module-bottom-content-left .margin {
        margin: 0 0 0.21333rem 0.4rem;
        font-size: 0.32rem;
        font-weight: bold; }
      .moduleBottom-content .module-bottom-content-left .inputBox {
        display: flex;
        height: 0.98667rem;
        margin: 0rem 0 0.26667rem 0.4rem;
        border: 0.02667rem solid var(--br);
        border-radius: 2px;
        padding: 0 0rem;
        background: var(--fff-bg);
        position: relative; }
        .moduleBottom-content .module-bottom-content-left .inputBox .title {
          display: flex;
          align-items: center;
          color: var(--f5);
          margin-left: 0.13333rem;
          font-size: 0.34667rem; }
        .moduleBottom-content .module-bottom-content-left .inputBox .content {
          display: flex;
          flex: 1;
          align-items: center;
          margin: 0 0.13333rem; }
          .moduleBottom-content .module-bottom-content-left .inputBox .content.levelBox {
            margin: 0; }
          .moduleBottom-content .module-bottom-content-left .inputBox .content .emulate-input {
            border: 0;
            margin: 0; }
            .moduleBottom-content .module-bottom-content-left .inputBox .content .emulate-input.limit input {
              text-align: center;
              font-size: 0.34667rem;
              margin: 0 0.85333rem;
              border-left: 0.02667rem solid var(--br);
              border-right: 0.02667rem solid var(--br); }
            .moduleBottom-content .module-bottom-content-left .inputBox .content .emulate-input input {
              color: var(--mainFont);
              border: 0;
              font-weight: bold;
              height: 0.96rem; }
            .moduleBottom-content .module-bottom-content-left .inputBox .content .emulate-input .unit {
              font-weight: bold;
              color: var(--mainFont3); }
          .moduleBottom-content .module-bottom-content-left .inputBox .content .cell {
            flex: 1;
            list-style: none;
            text-align: center;
            height: 0.96rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--mainFont);
            border-right: 0.02667rem solid var(--bg6); }
            .moduleBottom-content .module-bottom-content-left .inputBox .content .cell:last-child {
              border: 0; }
          .moduleBottom-content .module-bottom-content-left .inputBox .content .cell-active {
            background: var(--bg7);
            color: var(--active); }
      .moduleBottom-content .module-bottom-content-left .levelBox:before {
        width: 97%; }
      .moduleBottom-content .module-bottom-content-left .amountBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin: 0 0 0.26667rem 0.4rem; }
        .moduleBottom-content .module-bottom-content-left .amountBox .paymentBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 0.05333rem 0; }
          .moduleBottom-content .module-bottom-content-left .amountBox .paymentBox .title {
            color: var(--mainFont3);
            font-size: 0.32rem; }
          .moduleBottom-content .module-bottom-content-left .amountBox .paymentBox .content {
            font-size: 0.32rem;
            font-weight: 400;
            color: var(--f5);
            margin-left: 0.26667rem;
            display: flex;
            align-items: center; }
          .moduleBottom-content .module-bottom-content-left .amountBox .paymentBox .imageBox {
            display: flex;
            align-items: center;
            margin-left: 0.26667rem;
            cursor: pointer;
            fill: #A7A8AC; }
    .moduleBottom-content .module-bottom-content-right {
      flex: 0.38;
      background: var(--fff-bg); }
      .moduleBottom-content .module-bottom-content-right.right {
        flex: 1; }
      .moduleBottom-content .module-bottom-content-right .switch-fast-trade {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.14667rem; }
        .moduleBottom-content .module-bottom-content-right .switch-fast-trade .switch-fast-trade-h {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 0.53333rem;
          border: 0.02667rem solid var(--active);
          border-radius: 0.05333rem;
          margin: 0 0.42667rem;
          width: 100%; }
          .moduleBottom-content .module-bottom-content-right .switch-fast-trade .switch-fast-trade-h span {
            display: inline-block;
            margin-right: 0.05333rem;
            color: var(--active);
            font-size: 0.32rem; }
          .moduleBottom-content .module-bottom-content-right .switch-fast-trade .switch-fast-trade-h .C-Svg {
            width: 0.64rem;
            height: 0.64rem;
            zoom: 0.55; }
      .moduleBottom-content .module-bottom-content-right .price-number .title {
        display: flex;
        margin: 0.05333rem 0.42667rem; }
        .moduleBottom-content .module-bottom-content-right .price-number .title.tl {
          height: 0.8rem;
          margin: 0 0.42667rem;
          align-items: center; }
        .moduleBottom-content .module-bottom-content-right .price-number .title li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%; }
          .moduleBottom-content .module-bottom-content-right .price-number .title li span {
            font-size: 0.29333rem;
            font-weight: bold; }
        .moduleBottom-content .module-bottom-content-right .price-number .title .title-content {
          color: var(--t2); }
      .moduleBottom-content .module-bottom-content-right .price-number .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 1.73333rem; }
        .moduleBottom-content .module-bottom-content-right .price-number .content .ml {
          margin: 0rem 0.21333rem 0.08rem 0.42667rem;
          flex: 1; }
        .moduleBottom-content .module-bottom-content-right .price-number .content .mr {
          margin: 0rem 0.42667rem 0.08rem 0.21333rem;
          flex: 1; }
        .moduleBottom-content .module-bottom-content-right .price-number .content .sell-content li span {
          display: inline-block; }
          .moduleBottom-content .module-bottom-content-right .price-number .content .sell-content li span:last-child {
            color: var(--f5); }
            .moduleBottom-content .module-bottom-content-right .price-number .content .sell-content li span:last-child span {
              color: var(--mainFont3);
              margin-left: 0.66667rem; }
        .moduleBottom-content .module-bottom-content-right .price-number .content .buy-content li span {
          display: inline-block; }
          .moduleBottom-content .module-bottom-content-right .price-number .content .buy-content li span:first-child {
            color: var(--mainFont3); }
            .moduleBottom-content .module-bottom-content-right .price-number .content .buy-content li span:first-child span {
              margin-left: 0.66667rem;
              color: var(--f5); }
      .moduleBottom-content .module-bottom-content-right .price-number .sell-content {
        margin: 0.13333rem 0.42667rem; }
        .moduleBottom-content .module-bottom-content-right .price-number .sell-content li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 0.66667rem; }
          .moduleBottom-content .module-bottom-content-right .price-number .sell-content li span {
            font-size: 0.29333rem;
            font-weight: bold;
            color: var(--r1); }
      .moduleBottom-content .module-bottom-content-right .price-number .buy-content {
        margin: 0.53333rem 0.42667rem 0; }
        .moduleBottom-content .module-bottom-content-right .price-number .buy-content li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 0.66667rem; }
          .moduleBottom-content .module-bottom-content-right .price-number .buy-content li span {
            font-size: 0.29333rem;
            font-weight: bold;
            color: var(--f1); }
  .moduleBottom .functionBox {
    display: flex;
    height: 1.73333rem;
    background: var(--fff-bg);
    box-shadow: 0 2px 4px rgba(var(--bg1), 0.5); }
    .moduleBottom .functionBox.bottomBox {
      position: fixed;
      bottom: 0;
      width: 100%;
      box-shadow: 0px -5px 10px 0px rgba(204, 204, 204, 0.3);
      height: 1.6rem; }
    .moduleBottom .functionBox .goBox {
      flex: 1;
      display: flex; }
      .moduleBottom .functionBox .goBox .goButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1; }
        .moduleBottom .functionBox .goBox .goButton .imageBox {
          fill: var(--f5); }
        .moduleBottom .functionBox .goBox .goButton .buttonTitle {
          font-size: 0.37333rem;
          margin-top: 0.26667rem;
          color: var(--f5); }
  .moduleBottom .refreshListCon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0.53333rem 0.4rem 0.93333rem 0.4rem; }
    .moduleBottom .refreshListCon .refreshTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      font-family: var(--pRFont);
      color: var(--mainFont10); }
      .moduleBottom .refreshListCon .refreshTitle:not(:first-child) {
        padding-top: 0.74667rem; }
      .moduleBottom .refreshListCon .refreshTitle p:nth-child(2) {
        margin-left: 1.76rem; }
    .moduleBottom .refreshListCon .issue {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 0.26667rem;
      justify-content: space-between; }
      .moduleBottom .refreshListCon .issue div {
        text-align: left; }
        .moduleBottom .refreshListCon .issue div.big {
          flex: 1.2; }
        .moduleBottom .refreshListCon .issue div.small {
          flex: 0.7; }
        .moduleBottom .refreshListCon .issue div .details {
          color: var(--mainFontActive); }
          .moduleBottom .refreshListCon .issue div .details .trophy {
            margin-right: 0.05333rem; }
        .moduleBottom .refreshListCon .issue div img {
          width: 0.32rem;
          margin-top: -0.08rem; }
    .moduleBottom .refreshListCon .container {
      width: 100%;
      position: relative;
      min-height: 6.66667rem; }
      .moduleBottom .refreshListCon .container .listItem {
        margin: 0.21333rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-size: 0.32rem;
        line-height: 0.34667rem;
        font-weight: 400;
        font-family: 'DIN,DIN-Regular';
        color: var(--mainFont10); }
        .moduleBottom .refreshListCon .container .listItem .buyUp {
          color: var(--f1);
          margin-left: -1.2rem; }
          .moduleBottom .refreshListCon .container .listItem .buyUp img {
            width: 0.32rem;
            margin-top: -0.08rem; }
        .moduleBottom .refreshListCon .container .listItem .sellDown {
          color: var(--r1);
          margin-left: -1.2rem; }
          .moduleBottom .refreshListCon .container .listItem .sellDown img {
            width: 0.32rem;
            margin-top: -0.08rem; }

.alertTips {
  text-align: left;
  padding-left: 0.4rem;
  padding-right: 0.4rem; }
  .alertTips .contentBoxTitle {
    color: #FFD140;
    margin-bottom: 0.53333rem;
    font-size: 18px; }
  .alertTips .contentBox {
    display: flex;
    justify-content: space-between; }
    .alertTips .contentBox .titleFont {
      color: var(--mainFont);
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500; }
    .alertTips .contentBox .titleFontV2 {
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500;
      color: #FFD140; }
    .alertTips .contentBox .content {
      color: var(--mainFontActive);
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500;
      font-family: 'DIN,DIN-Medium'; }
    .alertTips .contentBox i {
      font-size: 0.32rem;
      line-height: 0.32rem;
      font-weight: 400;
      color: var(--mainFont10); }
    .alertTips .contentBox .up, .alertTips .contentBox .moduleBottom .holdList .listCon .holdRender .renderTop .down, .moduleBottom .holdList .listCon .holdRender .renderTop .alertTips .contentBox .down {
      color: var(--f1) !important; }
    .alertTips .contentBox .down {
      color: var(--r1) !important; }

.container2 {
  width: 100%;
  position: relative;
  min-height: 6.66667rem; }

.selectCon {
  display: flex;
  margin-left: auto;
  position: relative;
  z-index: 1; }
  .selectCon .selectBoxStyle {
    width: 2.93333rem !important;
    border-color: transparent !important;
    margin-left: 0.4rem;
    font-size: 0.32rem;
    font-family: var(--pMFont);
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--f8);
    padding: 0.13333rem 0.26667rem;
    border-radius: 3px;
    color: #E6E8EC;
    border-style: none;
    box-shadow: none; }
  .selectCon .dropdown {
    background-color: #23262F;
    max-height: 4rem;
    overflow: scroll; }
    .selectCon .dropdown .fiatCell {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      color: var(--mainFont10);
      padding: 0.13333rem 0.26667rem; }
      .selectCon .dropdown .fiatCell img {
        width: 0.45333rem;
        height: 0.45333rem; }
      .selectCon .dropdown .fiatCell p {
        font-size: 0.32rem;
        line-height: 0.32rem;
        font-weight: 500;
        margin-left: 0.13333rem; }

.container3 {
  width: 100%;
  position: relative;
  min-height: 16rem; }
