@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bg6);
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  height: 1.01333rem;
  margin-bottom: 0.66667rem;
  border-radius: 8px; }
  .module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell .cmlv-cellBox-cell-left {
    display: flex;
    align-items: center; }
    .module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell .cmlv-cellBox-cell-left .cmlv-cellBox-cell-left-image {
      width: 0.4rem;
      height: 0.4rem;
      margin-left: 0.4rem; }
      .module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell .cmlv-cellBox-cell-left .cmlv-cellBox-cell-left-image svg {
        width: 100%; }
    .module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell .cmlv-cellBox-cell-left .cmlv-cellBox-cell-left-text {
      font-size: 0.4rem;
      color: var(--mainFontActive);
      font-weight: 400;
      margin-left: 0.26667rem; }
  .module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell .cmlv-cellBox-cell-right {
    width: 0.13333rem;
    height: 0.21333rem;
    margin-right: 0.53333rem; }
    .module-cMethodListView .cmlv-cellBox .cmlv-cellBox-cell .cmlv-cellBox-cell-right svg {
      width: 100%; }
