@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-amountBox {
  padding: 0 0.4rem;
  margin-top: 0.4rem; }
  .module-amountBox .ab-row {
    display: flex;
    justify-content: space-between; }
    .module-amountBox .ab-row .ab-row-title {
      font-family: 'DIN,DIN-Medium';
      color: var(--pFont1); }
    .module-amountBox .ab-row .ab-row-min {
      font-family: 'DIN,DIN-Medium';
      color: var(--pFont1); }
  .module-amountBox .ab-amount {
    display: flex;
    background: var(--pbg1);
    margin-top: 0.2rem;
    height: 0.90667rem; }
    .module-amountBox .ab-amount .ab-amount-btn {
      display: flex;
      align-items: center; }
      .module-amountBox .ab-amount .ab-amount-btn:first-child {
        margin-left: 0.4rem; }
      .module-amountBox .ab-amount .ab-amount-btn:last-child {
        margin-right: 0.4rem; }
    .module-amountBox .ab-amount .ab-amount-inputBox {
      height: 100%;
      flex: 1; }
      .module-amountBox .ab-amount .ab-amount-inputBox input {
        background: transparent;
        border: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        font-family: 'DIN,DIN-Medium';
        font-size: 0.37333rem;
        color: var(--pFont4); }
        .module-amountBox .ab-amount .ab-amount-inputBox input::placeholder {
          color: var(--pFont3); }
