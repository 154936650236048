@charset "UTF-8";
.forward-enter {
  opacity: 0;
  transform: translateX(100%); }

.forward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 200ms; }

.forward-exit {
  opacity: 1;
  transform: translateX(0); }

.forward-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 200ms; }

/* 路由后退时的入场/离场动画 */
.back-enter {
  opacity: 0;
  transform: translateX(-100%); }

.back-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 200ms; }

.back-exit {
  opacity: 1;
  transform: translateX(0); }

.back-exit-active {
  opacity: 0;
  transform: translate(100%);
  transition: all 200ms; }
