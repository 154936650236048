@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.pageContent {
  background-color: var(--bg2);
  height: 100vh;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  padding: 1.6rem 0 0.34667rem;
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .pageContent .tradeTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: var(--night-font1, var(--f5));
    margin-bottom: 0.26667rem;
    padding: 0 0.4rem;
    font-size: 0.37333rem;
    font-weight: 400; }
    .pageContent .tradeTitle .buyAndSell {
      display: flex; }
      .pageContent .tradeTitle .buyAndSell .textNormal, .pageContent .tradeTitle .buyAndSell .textSelect {
        font-size: 0.48rem;
        line-height: 0.48rem;
        font-weight: 500;
        color: var(--mainFont10);
        font-family: var(--pMFont); }
        .pageContent .tradeTitle .buyAndSell .textNormal:last-child, .pageContent .tradeTitle .buyAndSell .textSelect:last-child {
          margin-left: 0.53333rem; }
      .pageContent .tradeTitle .buyAndSell .textSelect {
        color: var(--mainFontActive); }
    .pageContent .tradeTitle .filterMoney {
      width: 2.32rem;
      height: 0.8rem;
      background: var(--bg6);
      border-radius: 0.4rem;
      font-family: 'DIN,DIN-Medium';
      color: var(--mainFont10);
      padding: 0 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .pageContent .tradeTitle .filterMoney .iconStyle {
        margin-left: 0.05333rem;
        margin-top: -0.08rem; }
    .pageContent .tradeTitle .note {
      font-family: 'DIN,DIN-Regular';
      padding-right: 0.4rem;
      font-size: 0.32rem;
      color: var(--night-tipsDisplay, #848e9c); }
  .pageContent .tabContainer {
    border-bottom: 0.02667rem solid var(--bg6);
    margin-top: 0.48rem;
    padding-bottom: 0.26667rem; }
    .pageContent .tabContainer .tabs {
      width: 100%;
      padding: 0 0.4rem;
      font-size: 0.4rem;
      color: var(--f6);
      font-family: 'DIN,DIN-Medium'; }
  .pageContent .filterArea {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0.26667rem 0.4rem;
    color: var(--mainFont10);
    font-size: 0.32rem;
    font-weight: 400;
    border-bottom: 0.02667rem solid var(--bg6); }
    .pageContent .filterArea .leftFilter {
      display: flex;
      font-family: var(--pRFont); }
      .pageContent .filterArea .leftFilter .unShowFilterSty, .pageContent .filterArea .leftFilter .filterSty {
        display: flex;
        align-items: center;
        margin-right: 0.4rem;
        white-space: nowrap; }
        .pageContent .filterArea .leftFilter .unShowFilterSty img, .pageContent .filterArea .leftFilter .filterSty img {
          width: 0.22667rem;
          margin-left: 0.13333rem; }
      .pageContent .filterArea .leftFilter .filterSty {
        color: var(--active); }
        .pageContent .filterArea .leftFilter .filterSty img {
          transform: rotateX(180deg);
          margin-top: -0.05333rem; }
      .pageContent .filterArea .leftFilter .hasMoney img {
        margin-top: 0.05333rem;
        transform: rotateX(0deg); }
    .pageContent .filterArea .rightFilter {
      display: flex;
      align-items: center;
      white-space: nowrap; }
      .pageContent .filterArea .rightFilter img {
        margin-left: 0.13333rem; }
    .pageContent .filterArea .inputCon {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      font-family: 'DIN,DIN-Regular';
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      align-items: center;
      width: 100%;
      border-radius: 0.10667rem; }
      .pageContent .filterArea .inputCon input {
        display: block;
        width: 100%;
        padding: 0.26667rem;
        border: none;
        outline: none;
        font-size: 0.37333rem;
        background: transparent;
        color: var(--mainFont);
        height: 1.01333rem;
        border-top-left-radius: 0.10667rem;
        border-bottom-left-radius: 0.10667rem;
        background-color: red;
        color: var(--night-font10, #eeeff2);
        font-size: 0.34667rem; }
        .pageContent .filterArea .inputCon input::placeholder {
          font-size: 0.29333rem;
          font-weight: 400;
          color: var(--night-placeHolder, var(--f9)); }
        .pageContent .filterArea .inputCon input::placeholder {
          color: var(--night-placeHolder, var(--f16));
          font-size: 0.34667rem;
          font-weight: 500; }
      .pageContent .filterArea .inputCon label {
        position: absolute;
        top: 0.34667rem;
        right: 0.26667rem;
        font-size: 0.37333rem;
        font-weight: 500;
        color: var(--f6); }
      .pageContent .filterArea .inputCon .currencyStyleV2 {
        font-family: 'DIN,DIN-Medium';
        background-color: var(--night-font10, #eeeff2);
        line-height: 0.98667rem;
        border-top-right-radius: 0.10667rem;
        border-bottom-right-radius: 0.10667rem;
        padding-right: 0.26667rem;
        padding-top: 0.02667rem;
        color: var(--night-font1, var(--f5)); }
      .pageContent .filterArea .inputCon .currencyStyleV3 {
        font-family: 'DIN,DIN-Medium';
        display: flex;
        justify-content: space-between;
        background-color: var(--fff-bg);
        line-height: 1.01867rem;
        border-top-right-radius: 0.10667rem;
        border-bottom-right-radius: 0.10667rem;
        padding-right: 0.26667rem;
        color: var(--night-font1, var(--f5)); }
    .pageContent .filterArea .popArea {
      position: absolute;
      left: 0;
      top: 1.01333rem;
      z-index: 100; }
  .pageContent .popOut {
    background-color: var(--bg14);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0.53333rem 0.4rem;
    color: var(--bg6); }
    .pageContent .popOut .popOutInput {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      font-family: 'DIN,DIN-Regular';
      border-radius: 0.10667rem;
      background-color: var(--bg6); }
      .pageContent .popOut .popOutInput input {
        display: block;
        width: 100%;
        padding: 0.26667rem;
        border: none;
        outline: none;
        font-size: 0.37333rem;
        background: transparent;
        color: var(--mainFont);
        height: 1.01333rem;
        background-color: transparent;
        color: var(--mainFont);
        font-size: 0.4rem;
        line-height: 0.4rem;
        font-weight: 500; }
        .pageContent .popOut .popOutInput input::placeholder {
          font-size: 0.29333rem;
          font-weight: 400;
          color: var(--night-placeHolder, var(--f9)); }
        .pageContent .popOut .popOutInput input::placeholder {
          color: var(--mainFont10);
          font-size: 0.34667rem;
          font-weight: 500; }
      .pageContent .popOut .popOutInput .actionTxt {
        font-family: 'DIN,DIN-Medium';
        font-size: 0.4rem;
        line-height: 0.4rem;
        font-weight: 500;
        color: var(--mainFontActive);
        margin-right: 0.64rem; }
    .pageContent .popOut .popOutAmountCon {
      display: flex;
      width: 100%;
      flex-wrap: wrap; }
      .pageContent .popOut .popOutAmountCon .numBtn, .pageContent .popOut .popOutAmountCon .activeNumBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.90667rem;
        height: 1.01333rem;
        background: var(--bg6);
        border-radius: 0.08rem;
        font-size: 0.4rem;
        line-height: 0.4rem;
        font-weight: 500;
        font-family: 'DIN,DIN-Medium';
        color: var(--mainFont10);
        margin-top: 0.24rem;
        border: 0.02667rem solid transparent; }
        .pageContent .popOut .popOutAmountCon .numBtn:not(:nth-child(3n)), .pageContent .popOut .popOutAmountCon .activeNumBtn:not(:nth-child(3n)) {
          margin-right: 0.24rem; }
      .pageContent .popOut .popOutAmountCon .activeNumBtn {
        color: var(--mainFontActive);
        border: 0.02667rem solid var(--active); }
      .pageContent .popOut .popOutAmountCon .popOutAmount {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-family: 'DIN,DIN-Regular';
        font-size: 0.4rem;
        font-weight: 400;
        margin-top: 0.26667rem;
        border-color: #ffd140; }
        .pageContent .popOut .popOutAmountCon .popOutAmount .popOutAmountSty {
          background-color: var(--night-font10, #eeeff2);
          width: 25vw;
          text-align: center;
          padding: 0.13333rem;
          border-radius: 0.13333rem; }
        .pageContent .popOut .popOutAmountCon .popOutAmount .popOutAmountStySelected {
          background-color: var(--night-font10, #eeeff2);
          border: 1px solid;
          border-color: #ffd140;
          width: 25vw;
          text-align: center;
          padding: 0.13333rem;
          border-radius: 0.13333rem; }
    .pageContent .popOut .popOutTransferCon {
      display: flex;
      flex-wrap: wrap; }
    .pageContent .popOut .popOutTips {
      text-align: center;
      font-family: var(--pRFont);
      font-size: 0.32rem;
      font-weight: 400;
      margin-top: 0.4rem;
      margin-bottom: 0.13333rem;
      color: var(--night-tipsDisplay, #848e9c); }
    .pageContent .popOut .popButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between; }
      .pageContent .popOut .popButton .popButtonStyle {
        margin-top: 0.53333rem;
        height: 0.93333rem;
        width: 48%; }
      .pageContent .popOut .popButton .popButtonStyleReset {
        margin-top: 0.53333rem;
        height: 0.93333rem;
        width: 48%;
        background-color: var(--bg6);
        color: var(--mainFont); }
  .pageContent .transferBtn, .pageContent .activeTransferBtn {
    width: 2.90667rem;
    height: 1.01333rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg6);
    border-radius: 0.10667rem;
    color: var(--mainFontActive);
    margin-bottom: 0.24rem;
    font-size: 0.37333rem;
    line-height: 0.37333rem;
    font-weight: 400;
    border: 0.02667rem solid transparent; }
    .pageContent .transferBtn:not(:nth-child(3)), .pageContent .activeTransferBtn:not(:nth-child(3)) {
      margin-right: 0.24rem; }
    .pageContent .transferBtn:last-child, .pageContent .activeTransferBtn:last-child {
      margin-bottom: 0; }
  .pageContent .activeTransferBtn {
    border-color: var(--active);
    position: relative; }
    .pageContent .activeTransferBtn img {
      position: absolute;
      right: 0;
      bottom: -0.02667rem; }
  .pageContent .itemCon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0.13333rem 0;
    border-bottom: 0.02667rem solid var(--bg6); }
    .pageContent .itemCon .textStyle2 {
      font-size: 0.34667rem;
      color: var(--night-tipsDisplay, #848e9c); }
      .pageContent .itemCon .textStyle2 .rateSty {
        font-family: 'DIN,DIN-Medium';
        font-size: 0.58667rem;
        color: var(--night-font1, var(--f5)); }
      .pageContent .itemCon .textStyle2 .moneySty {
        font-family: 'DIN,DIN-Regular';
        font-size: 0.37333rem;
        color: var(--night-font1, var(--f5));
        margin-left: 0.13333rem; }
      .pageContent .itemCon .textStyle2 .infoSty {
        margin-right: 0.4rem; }
    .pageContent .itemCon .titleStyle4 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      color: var(--night-font1, var(--f5));
      margin-bottom: 0.26667rem;
      margin-top: 0.13333rem;
      font-size: 0.37333rem;
      font-weight: 400; }
      .pageContent .itemCon .titleStyle4 .note {
        font-family: 'DIN,DIN-Regular';
        font-size: 0.32rem;
        color: var(--night-tipsDisplay, #848e9c); }
      .pageContent .itemCon .titleStyle4 .imageBox img {
        width: 0.48rem;
        height: 0.48rem;
        border-radius: 0.48rem;
        margin-top: -0.08rem; }
      .pageContent .itemCon .titleStyle4 .userName {
        font-family: 'DIN,DIN-Regular'; }
      .pageContent .itemCon .titleStyle4 .infoSty {
        margin-right: 0.4rem;
        color: var(--night-tipsDisplay, #848e9c);
        font-size: 0.32rem;
        font-weight: 400; }
      .pageContent .itemCon .titleStyle4 .titleStyle4Left {
        display: flex;
        align-items: center; }
      .pageContent .itemCon .titleStyle4 .dot {
        margin: 0 0.13333rem;
        font-size: 0.34667rem;
        display: flex;
        align-items: center; }
        .pageContent .itemCon .titleStyle4 .dot > span {
          margin: 0 0.02667rem; }
        .pageContent .itemCon .titleStyle4 .dot .redDot {
          height: 0.26667rem;
          width: 0.26667rem;
          background-color: red;
          border-radius: 50%; }
        .pageContent .itemCon .titleStyle4 .dot .greenDot {
          height: 0.26667rem;
          width: 0.26667rem;
          background-color: #7afa7a;
          border-radius: 50%; }
    .pageContent .itemCon .titleStyle6 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      color: var(--night-font1, var(--f5));
      font-size: 0.37333rem;
      font-weight: 400; }
      .pageContent .itemCon .titleStyle6 .note {
        font-family: 'DIN,DIN-Regular';
        font-size: 0.32rem;
        color: var(--night-tipsDisplay, #848e9c); }
      .pageContent .itemCon .titleStyle6 .imageBox img {
        width: 0.61333rem;
        height: 0.61333rem;
        border-radius: 0.61333rem; }
      .pageContent .itemCon .titleStyle6 .infoSty {
        margin-right: 0.4rem;
        color: var(--night-tipsDisplay, #848e9c);
        font-size: 0.32rem;
        font-weight: 400; }
      .pageContent .itemCon .titleStyle6 .moneySty {
        font-family: 'DIN,DIN-Regular';
        font-size: 0.32rem;
        color: var(--night-font1, var(--f5));
        margin-left: 0.13333rem; }
      .pageContent .itemCon .titleStyle6 .buttonBuy, .pageContent .itemCon .titleStyle6 .buttonSell, .pageContent .itemCon .titleStyle6 .buttonDisable {
        background-color: #2cbc70;
        color: white;
        text-align: center;
        font-size: 0.4rem;
        padding: 0.05333rem;
        padding-left: 0.21333rem;
        padding-right: 0.21333rem;
        padding-top: 0.08rem;
        margin-top: -0.26667rem;
        border-radius: 2pt; }
      .pageContent .itemCon .titleStyle6 .buttonSell {
        background-color: #f84960; }
      .pageContent .itemCon .titleStyle6 .buttonDisable {
        background-color: #848e9c; }
  .pageContent .listCon {
    flex: 1;
    padding-left: 0.4rem; }
  .pageContent .submitBtnCon {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    margin-bottom: 0.26667rem;
    position: absolute;
    width: 100%;
    padding-bottom: 0.26667rem; }
  .pageContent .submitBtnConEng {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    margin-bottom: 0.26667rem;
    position: absolute;
    width: 100%;
    bottom: 0; }
  .pageContent .errotips {
    text-align: left;
    width: 100%;
    font-size: 0.32rem;
    font-weight: 300;
    padding-left: 0.4rem;
    color: var(--night-tipsDisplay, #777e90);
    margin-top: 0.66667rem;
    margin-bottom: 0.8rem; }
    .pageContent .errotips .noticeStyle {
      color: var(--active) !important; }
  .pageContent .paymentTips {
    text-align: left;
    width: 100%;
    color: var(--t11);
    font-size: 0.32rem;
    font-weight: 300;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    color: var(--night-tipsDisplay, #777e90); }
    .pageContent .paymentTips .noticeStyle {
      color: var(--active) !important; }
  .pageContent .titleStyle {
    padding-left: 0.4rem;
    color: var(--night-font1, var(--f5));
    margin-bottom: 0.13333rem; }
  .pageContent .titleStyle5 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--night-font1, var(--f5));
    margin-bottom: 0.26667rem;
    margin-top: 0.4rem;
    padding-right: 0.4rem;
    font-size: 0.37333rem;
    line-height: 0.37333rem;
    font-weight: 500;
    width: 100%;
    white-space: nowrap; }
    .pageContent .titleStyle5 .note {
      display: flex;
      align-items: center;
      font-family: var(--pMFont);
      color: var(--mainFont10);
      margin-right: 0.32rem;
      font-size: 0.32rem;
      width: 18.5vw; }
      .pageContent .titleStyle5 .note img {
        margin-right: 0.13333rem; }
  .pageContent .bannerStyle {
    width: 100%;
    height: 100%;
    margin-bottom: 0.26667rem; }
  .pageContent .inputCon {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-family: 'DIN,DIN-Regular';
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    align-items: center;
    width: 100%;
    border-radius: 0.10667rem; }
    .pageContent .inputCon input {
      display: block;
      width: 100%;
      padding: 0.26667rem;
      border: none;
      outline: none;
      font-size: 0.37333rem;
      background: transparent;
      color: var(--mainFont);
      height: 1.01333rem;
      border-top-left-radius: 0.10667rem;
      border-bottom-left-radius: 0.10667rem;
      background-color: var(--fff-bg);
      color: var(--night-font1, var(--f5));
      font-size: 0.34667rem; }
      .pageContent .inputCon input::placeholder {
        font-size: 0.29333rem;
        font-weight: 400;
        color: var(--night-placeHolder, var(--f9)); }
      .pageContent .inputCon input::placeholder {
        color: var(--night-placeHolder, var(--f16));
        font-size: 0.34667rem;
        font-weight: 500; }
    .pageContent .inputCon label {
      position: absolute;
      top: 0.34667rem;
      right: 0.26667rem;
      font-size: 0.37333rem;
      font-weight: 500;
      color: var(--f6); }
    .pageContent .inputCon .currencyStyleV2 {
      font-family: 'DIN,DIN-Medium';
      background-color: var(--fff-bg);
      line-height: 1.01867rem;
      border-top-right-radius: 0.10667rem;
      border-bottom-right-radius: 0.10667rem;
      padding-right: 0.26667rem;
      color: var(--night-font1, var(--f5)); }
    .pageContent .inputCon .currencyStyleV3 {
      font-family: 'DIN,DIN-Medium';
      display: flex;
      justify-content: space-between;
      background-color: var(--fff-bg);
      line-height: 1.01867rem;
      border-top-right-radius: 0.10667rem;
      border-bottom-right-radius: 0.10667rem;
      padding-right: 0.26667rem;
      color: var(--night-font1, var(--f5)); }
  .pageContent .depositNote {
    background: var(--night-deposit-note-bg, #fdf6e5);
    color: var(--active);
    font-size: 0.33333rem;
    padding: 0 0.4rem;
    height: 0.93333rem;
    line-height: 0.93333rem; }
    .pageContent .depositNote span {
      margin-left: 0.13333rem;
      position: relative;
      top: 0.02667rem; }
  .pageContent .pageItem {
    background-color: var(--fff-bg);
    margin: 0.18667rem 0.4rem;
    padding: 0.08rem 0.4rem 0.66667rem;
    border-radius: 0.13333rem;
    margin-bottom: 0.26667rem;
    position: relative; }
    @supports (clip-path: inset(50%)) {
      .pageContent .pageItem .itemTips {
        display: block;
        height: 20px;
        width: 20px;
        background-color: #ffd140;
        border: inherit;
        position: absolute;
        bottom: 0;
        right: 0;
        clip-path: polygon(0% 0%, 100% 100%, 0% 99%);
        transform: rotate(-90deg);
        border-radius: 0 0 0 0.25em; }
      .pageContent .pageItem .tick {
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
        display: inline-block;
        line-height: 1.5em;
        width: 1.8em;
        height: 0.5em;
        padding-left: 0.02667rem;
        padding-top: 0.05333rem;
        text-align: right;
        transform: rotate(90deg);
        color: black; } }
    .pageContent .pageItem .row {
      display: flex;
      justify-content: space-between;
      flex-direction: row; }
      .pageContent .pageItem .row .left {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: var(--night-font1, var(--f5));
        font-size: 0.37333rem;
        font-weight: 600;
        line-height: 0.37333rem;
        width: auto; }
        .pageContent .pageItem .row .left .imgStyle {
          width: 100%;
          height: 15px; }
      .pageContent .pageItem .row .rightTxt {
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--f6);
        font-size: 0.32rem;
        line-height: 0.32rem; }
        .pageContent .pageItem .row .rightTxt .imgStyle {
          height: 15px;
          width: 15px;
          margin-right: 0.13333rem; }
      .pageContent .pageItem .row .info {
        color: var(--f5);
        font-size: 0.32rem; }
    .pageContent .pageItem .itemInfo .left {
      color: var(--f5); }
      .pageContent .pageItem .itemInfo .left .infoRow {
        margin-top: 0.4rem;
        font-size: 0.32rem;
        line-height: 0.32rem;
        color: var(--mainFont); }
        .pageContent .pageItem .itemInfo .left .infoRow .sub {
          display: inline-block;
          color: var(--f6); }
        .pageContent .pageItem .itemInfo .left .infoRow:nth-child(2) {
          margin-top: 0.26667rem; }
      .pageContent .pageItem .itemInfo .left .infoRowV2 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 0.4rem;
        font-size: 0.32rem;
        line-height: 0.32rem;
        color: var(--mainFont); }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .sub {
          display: inline-block;
          color: var(--night-tipsDisplay, #777e90);
          font-size: 0.29333rem;
          font-weight: 400; }
          .pageContent .pageItem .itemInfo .left .infoRowV2 .sub .subCon {
            display: flex;
            justify-content: space-between; }
            .pageContent .pageItem .itemInfo .left .infoRowV2 .sub .subCon .subAlign {
              margin-top: 0.03467rem;
              font-size: 0.29333rem;
              font-weight: 400; }
            .pageContent .pageItem .itemInfo .left .infoRowV2 .sub .subCon .imgStyle {
              width: 12px;
              height: 12px;
              margin-right: 0.13333rem; }
          .pageContent .pageItem .itemInfo .left .infoRowV2 .sub .subText {
            font-family: 'DIN,DIN-Regular'; }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .singleColumnStyle {
          margin-top: 0.26667rem;
          text-align: center;
          font-size: 16px; }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .columnStyle {
          display: flex;
          flex-direction: column;
          justify-content: flex-start; }
          .pageContent .pageItem .itemInfo .left .infoRowV2 .columnStyle .columnText {
            margin-top: 0.26667rem; }
        .pageContent .pageItem .itemInfo .left .infoRowV2:nth-child(2) {
          margin-top: 0.26667rem; }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .rightRateV2 {
          padding-right: 0.8rem;
          color: var(--f6); }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .rightRate {
          padding-right: 0.05333rem;
          color: var(--active);
          font-size: 0.48rem;
          font-weight: 500;
          font-family: 'DIN,DIN-Medium'; }
          .pageContent .pageItem .itemInfo .left .infoRowV2 .rightRate .rightCurrency {
            font-size: 10px;
            color: var(--f6); }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .subText {
          color: var(--mainFont); }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .subTextV2 {
          color: var(--night-tipsDisplay, #777e90); }
        .pageContent .pageItem .itemInfo .left .infoRowV2 .title {
          color: var(--night-font1, var(--f5));
          font-size: 0.38667rem;
          font-weight: 400;
          font-weight: 600;
          line-height: 0.37333rem; }
          .pageContent .pageItem .itemInfo .left .infoRowV2 .title .imgStyle {
            width: 47px;
            height: 15px;
            line-height: 0.37333rem;
            margin-top: -1%;
            margin-left: 0.26667rem; }
      .pageContent .pageItem .itemInfo .left .payIcon {
        padding-top: 0.26667rem; }
    .pageContent .pageItem .itemInfo .right {
      text-align: end; }
      .pageContent .pageItem .itemInfo .right .rightRate {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 0.48rem;
        font-weight: bold;
        color: var(--active);
        position: relative;
        top: 0.74667rem;
        line-height: 0.26667rem;
        width: 100%; }
      .pageContent .pageItem .itemInfo .right .rightRateV2 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--f6);
        position: relative;
        top: 0.48rem;
        line-height: 0.26667rem;
        width: 100%; }
      .pageContent .pageItem .itemInfo .right .rightCurrency {
        font-size: 0.26667rem;
        font-weight: 400;
        color: var(--f6); }
      .pageContent .pageItem .itemInfo .right .buy {
        width: 2.02667rem;
        background-color: #2cbc70;
        color: var(--fff);
        padding: 0.13333rem 0;
        text-align: center;
        border-radius: 0.10667rem;
        position: relative;
        top: 0.8rem; }
  .pageContent .pageItemEng {
    position: relative;
    background-color: var(--fff-bg);
    margin: 0.18667rem 0.4rem 0 0.26667rem;
    padding: 0.05333rem 0.26667rem 0.4rem;
    border-radius: 0.13333rem; }
    @supports (clip-path: inset(50%)) {
      .pageContent .pageItemEng .itemTips {
        display: block;
        height: 20px;
        width: 20px;
        background-color: #ffd140;
        border: inherit;
        position: absolute;
        bottom: 0;
        right: 0;
        clip-path: polygon(0% 0%, 100% 100%, 0% 99%);
        transform: rotate(-90deg);
        border-radius: 0 0 0 0.25em; }
      .pageContent .pageItemEng .tick {
        font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
        display: inline-block;
        line-height: 1.5em;
        width: 1.8em;
        height: 0.5em;
        padding-left: 0.02667rem;
        padding-top: 0.05333rem;
        text-align: right;
        transform: rotate(90deg);
        color: black; } }
    .pageContent .pageItemEng .row {
      display: flex;
      justify-content: space-between;
      flex-direction: row; }
      .pageContent .pageItemEng .row .left {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: var(--night-font1, var(--f5));
        font-size: 0.37333rem;
        font-weight: 600;
        line-height: 0.37333rem;
        width: auto; }
        .pageContent .pageItemEng .row .left .imgStyle {
          width: 100%;
          height: 15px; }
      .pageContent .pageItemEng .row .rightTxt {
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--f6);
        font-size: 0.32rem;
        line-height: 0.32rem; }
        .pageContent .pageItemEng .row .rightTxt .imgStyle {
          height: 15px;
          width: 15px;
          margin-right: 0.13333rem; }
      .pageContent .pageItemEng .row .info {
        color: var(--f5);
        font-size: 0.32rem; }
    .pageContent .pageItemEng .itemInfo .left {
      color: var(--f5); }
      .pageContent .pageItemEng .itemInfo .left .infoRow {
        margin-top: 0.4rem;
        font-size: 0.32rem;
        line-height: 0.32rem;
        color: var(--mainFont); }
        .pageContent .pageItemEng .itemInfo .left .infoRow .sub {
          display: inline-block;
          color: var(--f6); }
        .pageContent .pageItemEng .itemInfo .left .infoRow:nth-child(2) {
          margin-top: 0.26667rem; }
      .pageContent .pageItemEng .itemInfo .left .infoRowV2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-size: 0.32rem;
        line-height: 0.32rem;
        font-weight: 500;
        margin-top: 0.4rem;
        color: var(--mainFont); }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .sub {
          display: inline-block;
          color: var(--f6); }
          .pageContent .pageItemEng .itemInfo .left .infoRowV2 .sub .subCon {
            display: flex;
            justify-content: space-between; }
            .pageContent .pageItemEng .itemInfo .left .infoRowV2 .sub .subCon .subAlign {
              margin-top: 0.03467rem; }
            .pageContent .pageItemEng .itemInfo .left .infoRowV2 .sub .subCon .imgStyle {
              width: 12px;
              height: 12px;
              margin-right: 0.13333rem; }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .singleColumnStyle {
          text-align: center;
          font-size: 16px;
          padding-left: 0.13333rem; }
          .pageContent .pageItemEng .itemInfo .left .infoRowV2 .singleColumnStyle img {
            width: 0.90667rem;
            height: 0.90667rem;
            border-radius: 0.90667rem;
            margin-right: 0.26667rem; }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .columnStyle {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding-right: 0.13333rem;
          text-align: right;
          font-family: 'DIN,DIN-Regular'; }
          .pageContent .pageItemEng .itemInfo .left .infoRowV2 .columnStyle .columnText {
            margin-top: 0.26667rem; }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2:nth-child(2) {
          margin-top: 0.26667rem; }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .rightRateV2 {
          padding-right: 0.8rem;
          color: var(--f6); }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .rightRate {
          padding-right: 0.05333rem;
          font-weight: bold;
          color: var(--active);
          font-size: 18px; }
          .pageContent .pageItemEng .itemInfo .left .infoRowV2 .rightRate .rightCurrency {
            font-size: 10px;
            color: var(--f6); }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .subText {
          color: var(--mainFont); }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .subTextV2 {
          color: var(--f6); }
        .pageContent .pageItemEng .itemInfo .left .infoRowV2 .title {
          color: var(--night-font1, var(--f5));
          font-size: 0.37333rem;
          font-weight: 600;
          line-height: 0.37333rem; }
          .pageContent .pageItemEng .itemInfo .left .infoRowV2 .title .imgStyle {
            width: 47px;
            height: 15px;
            line-height: 0.37333rem;
            margin-top: -1%;
            margin-left: 0.26667rem; }
      .pageContent .pageItemEng .itemInfo .left .payIcon {
        padding-top: 0.26667rem; }
    .pageContent .pageItemEng .itemInfo .right {
      text-align: end; }
      .pageContent .pageItemEng .itemInfo .right .rightRate {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 0.48rem;
        font-weight: bold;
        color: var(--active);
        position: relative;
        top: 0.74667rem;
        line-height: 0.26667rem;
        width: 100%; }
      .pageContent .pageItemEng .itemInfo .right .rightRateV2 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--f6);
        position: relative;
        top: 0.48rem;
        line-height: 0.26667rem;
        width: 100%; }
      .pageContent .pageItemEng .itemInfo .right .rightCurrency {
        font-size: 0.26667rem;
        font-weight: 400;
        color: var(--f6); }
      .pageContent .pageItemEng .itemInfo .right .buy {
        width: 2.02667rem;
        background-color: #2cbc70;
        color: var(--fff);
        padding: 0.13333rem 0;
        text-align: center;
        border-radius: 0.10667rem;
        position: relative;
        top: 0.8rem; }

.sideBarCon {
  background-color: var(--bg2);
  height: 100%;
  width: 90vw;
  border-top-left-radius: 0.53333rem;
  border-bottom-left-radius: 0.53333rem;
  padding: 0.53333rem 0.4rem; }
  .sideBarCon .sideTitle {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 0.42667rem;
    line-height: 0.42667rem;
    font-weight: 500;
    font-family: var(--pMFont);
    color: var(--mainFontActive); }
    .sideBarCon .sideTitle .sideTitleStyle {
      font-family: var(--pRFont);
      font-size: 0.4rem;
      font-weight: 400;
      color: var(--mainFont10);
      padding: 0.26667rem 0; }
    .sideBarCon .sideTitle .closeBtn {
      position: absolute;
      right: 0; }
  .sideBarCon .titleSty {
    font-family: var(--pRFont);
    font-size: 0.4rem;
    font-weight: 400;
    color: var(--mainFont10);
    padding: 0.53333rem 0; }
  .sideBarCon .totalInputCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-family: 'DIN,DIN-Regular';
    padding: 0;
    background-color: var(--bg6);
    border-radius: 0.10667rem; }
    .sideBarCon .totalInputCon input {
      display: block;
      width: 100%;
      padding: 0.26667rem;
      border: none;
      outline: none;
      font-size: 0.37333rem;
      background: transparent;
      color: var(--mainFont);
      height: 1.01333rem;
      background-color: transparent;
      color: var(--mainFontActive);
      font-size: 0.4rem;
      line-height: 0.4rem;
      font-weight: 500; }
      .sideBarCon .totalInputCon input::placeholder {
        font-size: 0.29333rem;
        font-weight: 400;
        color: var(--night-placeHolder, var(--f9)); }
      .sideBarCon .totalInputCon input::placeholder {
        color: var(--mainFont10);
        font-size: 0.4rem;
        font-weight: 500; }
    .sideBarCon .totalInputCon .flat {
      font-size: 0.4rem;
      line-height: 0.4rem;
      font-weight: 500;
      font-family: 'DIN,DIN-Medium';
      color: var(--mainFontActive);
      margin-right: 0.4rem; }
  .sideBarCon .paymentType {
    display: flex;
    flex-wrap: wrap; }
    .sideBarCon .paymentType .transferBtn, .sideBarCon .paymentType .pageContent .activeTransferBtn, .pageContent .sideBarCon .paymentType .activeTransferBtn {
      width: 2.53333rem; }
  .sideBarCon .adTips {
    font-size: 0.32rem;
    line-height: 0.32rem;
    font-weight: 500;
    font-family: var(--pMFont);
    color: var(--mainFontActive); }
  .sideBarCon .filterBtnArea {
    position: fixed;
    left: 0;
    bottom: 2.13333rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between; }
    .sideBarCon .filterBtnArea .submitBtnReStyle, .sideBarCon .filterBtnArea .submitBtnStyle {
      display: flex;
      width: 90%;
      background-color: var(--bg6);
      color: var(--mainFont);
      font-family: var(--pRFont);
      font-size: 0.37333rem;
      font-weight: 400;
      margin: 0 0.4rem; }
    .sideBarCon .filterBtnArea .submitBtnStyle {
      background-color: var(--active);
      color: var(--btn-active-color);
      font-family: var(--pRFont);
      font-size: 0.37333rem;
      font-weight: 400; }

.more {
  width: 100%;
  height: 1.17333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 0.53333rem;
  font-size: 0.37333rem;
  font-weight: 400; }
  .more span {
    color: #000;
    background: var(--active);
    padding: 0.15rem 1rem;
    border-radius: 5px;
    font-size: 0.37333rem;
    font-weight: 400; }

.popContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  width: 85%;
  background: var(--bg2);
  border-radius: 0.26667rem;
  padding: 0.66667rem; }
  .popContent .title {
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: 25;
    font-family: var(--pMFont);
    color: var(--active);
    margin-bottom: 0.8rem; }
  .popContent .warnings {
    font-size: 0.37333rem;
    line-height: 0.53333rem;
    font-weight: 500;
    font-family: 'DIN,DIN-Medium';
    color: var(--mainFontActive);
    letter-spacing: -0.016rem; }
  .popContent .btnArea {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 0.53333rem; }
    .popContent .btnArea .btn {
      background-color: var(--active);
      color: var(--btn-active-color);
      border-radius: 0.08rem;
      padding: 0.26667rem;
      text-align: center;
      font-size: 0.42667rem;
      line-height: 0.61333rem;
      font-weight: 500;
      font-family: var(--pMFont); }

.fiatMask {
  width: 100%;
  background-color: var(--fff-bg);
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  animation: detailAni 200ms ease-in forwards;
  padding: 0.45333rem 0.4rem;
  margin-top: 1.17333rem; }

@keyframes detailAni {
  0% {
    bottom: -100%; }
  100% {
    bottom: 0; } }
  .fiatMask .fiatList {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center; }
    .fiatMask .fiatList .fiatItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 1.28rem;
      color: var(--mainFont);
      font-family: 'DIN,DIN-Medium';
      border-bottom: 1px solid var(--bg6); }
      .fiatMask .fiatList .fiatItem .left {
        display: flex;
        align-items: center;
        color: var(--mainFontActive);
        font-size: 0.42667rem;
        line-height: 0.42667rem;
        font-weight: 500;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500; }
        .fiatMask .fiatList .fiatItem .left img {
          width: 0.8rem;
          margin-right: 0.4rem; }
        .fiatMask .fiatList .fiatItem .left i {
          display: inline-block;
          width: 0.4rem;
          margin-right: 1.06667rem;
          text-align: center; }
      .fiatMask .fiatList .fiatItem .hide {
        display: none; }
  .fiatMask .maskHeader {
    display: flex;
    justify-content: flex-end;
    font-size: 0.42667rem;
    line-height: 0.42667rem;
    font-weight: 500;
    color: var(--active);
    margin-top: 1.17333rem; }
  .fiatMask .maskTitle {
    font-size: 0.58667rem;
    line-height: 0.58667rem;
    font-weight: 500;
    font-family: var(--pMFont);
    color: var(--mainFontActive);
    margin: 0.53333rem 0; }
