@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  color: white; }
  .page .header {
    background-color: var(--fff-bg); }
  .page .wrapper {
    padding-top: 1.17333rem;
    position: relative;
    font-family: PingFangSC-Regular; }
    .page .wrapper img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: fill; }
    .page .wrapper .table {
      margin: 0 0.66667rem;
      font-size: 0.28rem; }
      .page .wrapper .table .preamble {
        font-size: 0.36rem;
        padding-top: 0.53333rem; }
      .page .wrapper .table .headers {
        text-align: center;
        font-weight: 500;
        font-size: 0.48rem;
        padding-top: 0.8rem;
        padding-bottom: 0.4rem; }
      .page .wrapper .table .headers:first-of-type {
        padding-top: 0.4rem; }
    .page .wrapper table {
      border-collapse: collapse;
      width: 100%; }
      .page .wrapper table th, .page .wrapper table td {
        border: 1px solid;
        padding: 0.08rem;
        font-weight: 500; }
  .page .footer {
    font-size: 0.24rem;
    color: grey;
    padding-top: 0.4rem;
    height: 2.66667rem; }
