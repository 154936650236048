@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-dialog-google {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: var(--bg2); }
  .components-dialog-google .title {
    color: var(--t1);
    font-size: 0.42667rem;
    margin-top: 0.8rem; }
  .components-dialog-google .sub-title {
    margin-top: 0.8rem;
    font-size: 0.37333rem; }
  .components-dialog-google main {
    border-top: 3px solid var(--l1);
    padding-top: 1.33333rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem; }
    .components-dialog-google main img {
      width: 2.13333rem;
      border: 2px solid var(--t2);
      padding: 0.26667rem;
      border-radius: 50%; }
    .components-dialog-google main .ipt {
      width: 1.06667rem;
      height: 1.06667rem;
      line-height: 1.06667rem;
      background-color: var(--l1);
      border: 0 none;
      box-shadow: inset 0px 0px 3px 1px rgba(225, 197, 81, 0.8);
      text-align: center;
      border-radius: 2px;
      color: var(--t1);
      font-size: 0.42667rem; }
    .components-dialog-google main .error .ipt {
      box-shadow: inset 0px 0px 3px 1px rgba(213, 95, 88, 0.8); }
    .components-dialog-google main .msg {
      color: var(--r1);
      margin-top: 0.26667rem; }
    .components-dialog-google main input {
      color: transparent;
      background-color: transparent;
      border: 0 none; }
    .components-dialog-google main span {
      display: none; }
  .components-dialog-google .flash span {
    display: block;
    animation: flash 2s infinite; }

@keyframes flash {
  0% {
    opacity: .8; }
  25% {
    opacity: .6; }
  50% {
    opacity: .2; }
  75% {
    opacity: .6; }
  100% {
    opacity: .8; } }
