@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  color: var(--mainFont);
  overflow-x: hidden;
  font-family: "IBMPlexSans-Regular"; }
  .page .header {
    font-size: 0.42667rem;
    padding: 0.42667rem; }
  .page .rewardsCon {
    margin: 1.6rem 0.42667rem 0; }
    .page .rewardsCon .banner {
      display: flex;
      margin: 0 0.42667rem;
      margin-bottom: 0.53333rem;
      position: relative; }
      .page .rewardsCon .banner .titleText {
        width: 65%; }
        .page .rewardsCon .banner .titleText .title {
          font-size: 0.69333rem;
          margin-bottom: 0.21333rem; }
        .page .rewardsCon .banner .titleText .subtitle {
          font-size: 0.42667rem;
          color: #888888; }
      .page .rewardsCon .banner img {
        position: absolute;
        width: 65%;
        top: 12%;
        right: -15%;
        pointer-events: none; }
    .page .rewardsCon .rewardTabs {
      margin: 0.42667rem 0.42667rem 0 0.42667rem;
      display: flex;
      color: #888888;
      font-size: 0.42667rem;
      white-space: nowrap; }
      .page .rewardsCon .rewardTabs > div {
        margin-right: 0.42667rem;
        padding-bottom: 0.21333rem; }
      .page .rewardsCon .rewardTabs .active {
        border-bottom: 2px solid var(--mainFont);
        color: var(--mainFont); }
    .page .rewardsCon .rewardStamp {
      margin: 0.85333rem 0; }
      .page .rewardsCon .rewardStamp .noData {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 300px; }
      .page .rewardsCon .rewardStamp .stamp {
        border: 1px solid #333333;
        border-radius: 0.16rem;
        padding: 0.42667rem;
        margin-bottom: 0.53333rem;
        background-color: rgba(39, 39, 39, 0.3); }
        .page .rewardsCon .rewardStamp .stamp .text {
          font-size: 0.42667rem;
          margin-bottom: 0.10667rem; }
        .page .rewardsCon .rewardStamp .stamp .subText {
          color: #7C7E87;
          font-size: 0.34667rem; }
        .page .rewardsCon .rewardStamp .stamp .description {
          display: flex;
          margin-top: 0.69333rem; }
          .page .rewardsCon .rewardStamp .stamp .description .amount {
            font-size: 0.58667rem;
            color: #49CDEE;
            font-weight: 900; }
        .page .rewardsCon .rewardStamp .stamp .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          padding: 0.13333rem 0;
          width: 2.50667rem;
          color: var(--mainFont);
          font-size: 0.37333rem;
          white-space: nowrap;
          background-color: transparent;
          border-radius: 0.21333rem;
          border: 1px solid var(--mainFont); }
          .page .rewardsCon .rewardStamp .stamp .btn img {
            width: 0.21333rem;
            height: 0.21333rem;
            margin: 0; }
        .page .rewardsCon .rewardStamp .stamp.claim .btn {
          color: #00F2ED;
          border: 1px solid #00F2ED; }
        .page .rewardsCon .rewardStamp .stamp.unlocked .btn {
          border: 1px transparent;
          color: #888888; }
  .page .faqCon {
    padding: 0.4rem 0;
    border-radius: 0.53333rem;
    color: #E7E9EC; }
    .page .faqCon .title {
      font-size: 0.48rem;
      margin: 0 0.42667rem;
      margin-bottom: -0.26667rem;
      color: #E7E9EC; }
    .page .faqCon details {
      padding: 0.42667rem 0;
      margin: 0 0.42667rem;
      border-bottom: 1px solid #333333; }
      .page .faqCon details:not([open]) {
        animation-name: fold-in;
        animation-duration: .3s; }
      .page .faqCon details summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.32rem 0;
        font-size: 0.4rem; }
      .page .faqCon details summary::-webkit-details-marker,
      .page .faqCon details summary::marker {
        content: "";
        display: none; }
      .page .faqCon details summary::after {
        content: url("../../../assets/svg/newIcon/arrow_down.svg");
        font-size: 0.42667rem;
        margin: 0 0.21333rem;
        cursor: pointer; }
      .page .faqCon details .content {
        color: #7C7E87;
        font-size: 0.34667rem; }
    .page .faqCon details[open] summary::after {
      content: url("../../../assets/svg/newIcon/arrow_up.svg"); }
    .page .faqCon details[open] .content {
      animation-name: fold-out;
      animation-duration: .3s; }

@keyframes fold-out {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fold-in {
  0% {
    margin-bottom: 0.42667rem; }
  100% {
    margin-bottom: 0; } }
  .page .rulesCon {
    margin: 0.8rem 0.4rem 1.86667rem; }
    .page .rulesCon .title {
      padding-bottom: 0.37333rem;
      font-size: 0.48rem; }
    .page .rulesCon ol {
      list-style-type: none;
      padding: 0; }
      .page .rulesCon ol li {
        font-size: 0.34667rem;
        color: #7C7E87; }
  .page.light {
    background-color: #F2F4F6;
    color: #0A0C18 !important; }
    .page.light .header {
      background-color: #F2F4F6; }
    .page.light .title span {
      color: #526AEA; }
    .page.light .rewardsCon .rewardTabs {
      margin: 0.42667rem 0.42667rem 0 0.42667rem;
      display: flex;
      color: #A4A8AB; }
      .page.light .rewardsCon .rewardTabs > div {
        margin-right: 0.42667rem;
        padding-bottom: 0.21333rem; }
      .page.light .rewardsCon .rewardTabs .active {
        border-bottom: 2px solid #0A0C18;
        color: #0A0C18; }
    .page.light .rewardsCon .rewardStamp .stamp {
      background-color: #E7E9EC; }
    .page.light .faqCon {
      color: #0A0C18; }
      .page.light .faqCon .title {
        color: #0A0C18; }
      .page.light .faqCon details {
        color: #0A0C18;
        border-bottom: 0.5px solid rgba(191, 191, 191, 0.5); }
        .page.light .faqCon details.last {
          border-bottom: none; }
        .page.light .faqCon details summary::after {
          content: url("../../../assets/svg/newIcon/arrow_down_light.svg"); }
      .page.light .faqCon details[open] summary::after {
        content: url("../../../assets/svg/newIcon/arrow_up_light.svg"); }
