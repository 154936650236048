@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.pageTrade {
  display: flex;
  flex-direction: column;
  background-color: var(--bg12);
  position: relative; }
  .pageTrade .am-tabs-tab-bar-wrap {
    position: sticky !important;
    top: 1.17333rem !important; }
  .pageTrade .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 0.08rem;
    border: 0.02667rem solid var(--line-bg4);
    font-family: 'DIN,DIN-Medium'; }
    .pageTrade .tabs div {
      width: 2.13333rem;
      height: 0.74667rem;
      text-align: center;
      line-height: 0.74667rem;
      color: var(--mainFont10); }
      .pageTrade .tabs div.active {
        color: var(--mainFontActive);
        background: var(--night-bg2, var(--bg9)); }
  .pageTrade .tradeHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0.4rem;
    height: 1.06667rem;
    border-bottom: 0.02667rem solid var(--bg6); }
    .pageTrade .tradeHeader .gameTabs {
      width: 4rem;
      font-size: 0.42667rem;
      line-height: 0.42667rem;
      font-weight: 500;
      font-family: 'DIN,DIN-Medium';
      color: var(--mainFontActive); }
  .pageTrade .main {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    background-color: var(--bg12);
    flex: 1; }
    .pageTrade .main .infoTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 0.4rem;
      margin: 0.26667rem 0; }
      .pageTrade .main .infoTitle .rightInfo {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .pageTrade .main .infoTitle .rightInfo .subInfo, .pageTrade .main .infoTitle .rightInfo .titleLeft .rateInfo, .pageTrade .main .infoTitle .titleLeft .rightInfo .rateInfo {
          margin-right: 0.26667rem;
          font-weight: bolder;
          font-family: 'DIN,DIN-Medium'; }
          .pageTrade .main .infoTitle .rightInfo .subInfo.raise, .pageTrade .main .infoTitle .rightInfo .titleLeft .raise.rateInfo, .pageTrade .main .infoTitle .titleLeft .rightInfo .raise.rateInfo {
            color: var(--f1); }
          .pageTrade .main .infoTitle .rightInfo .subInfo.low, .pageTrade .main .infoTitle .rightInfo .titleLeft .low.rateInfo, .pageTrade .main .infoTitle .titleLeft .rightInfo .low.rateInfo {
            color: var(--r1); }
        .pageTrade .main .infoTitle .rightInfo .rateInfo {
          padding: 0.02667rem 0.10667rem;
          font-size: 0.26667rem;
          border-radius: 0.10667rem;
          font-family: 'DIN,DIN-Medium'; }
          .pageTrade .main .infoTitle .rightInfo .rateInfo.raise {
            color: var(--f1); }
          .pageTrade .main .infoTitle .rightInfo .rateInfo.low {
            color: var(--r1); }
      .pageTrade .main .infoTitle .titleLeft {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end; }
        .pageTrade .main .infoTitle .titleLeft .mainInfo {
          font-size: 0.53333rem;
          line-height: 0.53333rem;
          font-weight: 600;
          font-family: 'DIN,DIN-Medium';
          color: var(--f5); }
        .pageTrade .main .infoTitle .titleLeft .countDown {
          margin-left: 0.13333rem;
          font-family: 'DIN,DIN-Medium';
          font-size: 0.32rem;
          line-height: 0.32rem;
          font-weight: 400;
          color: var(--mainFont10); }
          .pageTrade .main .infoTitle .titleLeft .countDown i {
            color: var(--mainFontActive); }
        .pageTrade .main .infoTitle .titleLeft .subInfo, .pageTrade .main .infoTitle .titleLeft .rateInfo {
          margin-top: 0.26667rem;
          margin-left: 0.13333rem;
          font-size: 0.32rem;
          line-height: 0.32rem;
          font-weight: 400; }
        .pageTrade .main .infoTitle .titleLeft .raise {
          color: var(--f1); }
        .pageTrade .main .infoTitle .titleLeft .low {
          color: var(--r1); }
        .pageTrade .main .infoTitle .titleLeft .rateInfo {
          border-radius: 0.10667rem;
          background: rgba(0, 207, 140, 0.1); }
      .pageTrade .main .infoTitle .titleRight {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        align-items: flex-end; }
        .pageTrade .main .infoTitle .titleRight i {
          display: inline-block;
          text-align: center;
          width: 0.48rem;
          height: 0.48rem;
          background: #5d95ff;
          color: white;
          border-radius: 4px;
          margin: 0 0.05333rem; }
    .pageTrade .main .chartCon {
      position: relative;
      overflow: hidden;
      height: 9.06667rem; }
      .pageTrade .main .chartCon.nicChart {
        height: 410px; }
      .pageTrade .main .chartCon .curPrice {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        width: 1.46667rem;
        height: 92%; }
        .pageTrade .main .chartCon .curPrice p {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          color: var(--mainFontActive);
          font-family: 'DIN,DIN-Medium';
          font-size: 0.32rem;
          line-height: 0.32rem;
          font-weight: 400;
          height: 0.85333rem; }
      .pageTrade .main .chartCon .chartChange {
        position: absolute;
        right: 0.13333rem;
        top: 0rem;
        height: 0.88rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
        width: 10%;
        font-size: 0.32rem;
        line-height: 0.32rem;
        font-weight: 500;
        color: var(--mainFont10);
        z-index: 8; }
        .pageTrade .main .chartCon .chartChange:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 0.53333rem;
          right: -0.13333rem;
          transform: rotate(135deg);
          border: 5px solid transparent;
          border-bottom-color: var(--f6);
          z-index: 1; }
      .pageTrade .main .chartCon .closePanel {
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2); }
        .pageTrade .main .chartCon .closePanel .closeTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: 0.42667rem;
          line-height: 0.42667rem;
          font-weight: 500;
          color: var(--fff);
          pointer-events: none; }
    .pageTrade .main :global .tradingView {
      border: 0 none;
      width: 100%;
      flex: 1;
      height: 7.04rem;
      position: relative; }
      .pageTrade .main :global .tradingView > div {
        height: 7.04rem; }
        .pageTrade .main :global .tradingView > div.simpleView {
          height: 380px; }
      .pageTrade .main :global .tradingView #tradingView {
        width: 100%;
        height: 7.04rem; }
      .pageTrade .main :global .tradingView .modal {
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        color: white;
        font-size: 0.48rem; }
  .pageTrade .quote {
    display: flex;
    flex-direction: column;
    height: 2.53333rem;
    padding: 0.16rem 0.26667rem; }
    .pageTrade .quote .priceInfoBox {
      display: flex;
      justify-content: space-between;
      height: 0.96rem;
      align-items: center; }
      .pageTrade .quote .priceInfoBox .priceBox {
        display: flex;
        align-items: center;
        height: 0.58667rem; }
        .pageTrade .quote .priceInfoBox .priceBox .price {
          font-size: 0.74667rem;
          font-weight: bold; }
        .pageTrade .quote .priceInfoBox .priceBox .imageBox {
          display: flex;
          margin-left: 0.26667rem; }
      .pageTrade .quote .priceInfoBox .additionBox {
        display: flex; }
        .pageTrade .quote .priceInfoBox .additionBox .rate {
          font-size: 0.37333rem;
          display: flex;
          align-items: center; }
        .pageTrade .quote .priceInfoBox .additionBox .percent {
          font-size: 0.37333rem;
          margin-left: 0.53333rem;
          align-items: center;
          display: flex; }
      .pageTrade .quote .priceInfoBox .raise {
        color: var(--f1);
        fill: var(--f1); }
      .pageTrade .quote .priceInfoBox .fall {
        color: var(--r1);
        fill: var(--r1); }
      .pageTrade .quote .priceInfoBox .rotate {
        transform: rotate(180deg); }
    .pageTrade .quote .infoList {
      display: flex;
      flex: 1;
      justify-content: space-between; }
      .pageTrade .quote .infoList .infoBox {
        display: flex;
        align-items: center; }
        .pageTrade .quote .infoList .infoBox .title {
          font-size: 0.32rem;
          color: var(--f6); }
        .pageTrade .quote .infoList .infoBox .content {
          color: var(--f5);
          text-align: right;
          font-size: 0.32rem;
          margin-left: 0.26667rem; }
        .pageTrade .quote .infoList .infoBox .raise {
          color: var(--f1);
          fill: var(--f1); }
        .pageTrade .quote .infoList .infoBox .fall {
          color: var(--r1);
          fill: var(--r1); }
  .pageTrade .chart-action {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    color: var(--t1);
    display: flex; }
    .pageTrade .chart-action .resolution,
    .pageTrade .chart-action .ma,
    .pageTrade .chart-action .sub-ma {
      position: relative;
      width: 1.6rem; }
    .pageTrade .chart-action .name {
      position: relative; }
      .pageTrade .chart-action .name i {
        position: relative;
        margin-left: 0.26667rem;
        top: 0.16rem;
        width: 0;
        height: 0;
        display: inline-block;
        border: 0.13333rem solid transparent;
        border-top-color: var(--t1);
        transform: rotate(-45deg); }
      .pageTrade .chart-action .name i:after {
        content: ""; }
    .pageTrade .chart-action div {
      line-height: 0.85333rem; }
    .pageTrade .chart-action ul,
    .pageTrade .chart-action li {
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: center; }
    .pageTrade .chart-action ul {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0.85333rem;
      background-color: var(--bg1);
      z-index: 999999; }
  .pageTrade .am-tabs-default-bar {
    background-color: transparent !important; }
  .pageTrade .am-tabs-default-bar-tab::after {
    display: none !important; }
  .pageTrade .am-tabs-default-bar-underline {
    display: none; }
  .pageTrade .am-tabs-default-bar-content {
    display: flex;
    justify-content: flex-end; }
  .pageTrade .am-tabs-default-bar-tab {
    width: auto !important;
    margin-right: 0.53333rem;
    line-height: 0.85333rem !important;
    height: 0.85333rem !important; }
  .pageTrade .am-tabs-default-bar-tab-active {
    color: var(--active) !important; }
  .pageTrade .fast,
  .pageTrade .professional {
    background-color: var(--bg2);
    max-width: 100%;
    overflow: hidden;
    color: var(--t1); }
  .pageTrade .fast,
  .pageTrade .professional {
    padding-bottom: 1.6rem;
    padding-top: 0.13333rem; }
    .pageTrade .fast div,
    .pageTrade .professional div {
      line-height: 0.85333rem; }
    .pageTrade .fast .block,
    .pageTrade .professional .block {
      border: 1px solid var(--l1);
      margin: 0.13333rem 0.26667rem;
      border-radius: 0.05333rem; }
    .pageTrade .fast .label,
    .pageTrade .professional .label {
      padding-left: 0.13333rem; }
    .pageTrade .fast .des,
    .pageTrade .professional .des {
      padding-right: 0.13333rem; }
    .pageTrade .fast .amount,
    .pageTrade .professional .amount {
      padding-left: 0.13333rem;
      padding-right: 0.13333rem; }
    .pageTrade .fast input,
    .pageTrade .professional input {
      border: 0 none;
      outline: 0 none;
      background-color: transparent; }
    .pageTrade .fast .lever div,
    .pageTrade .professional .lever div {
      text-align: center; }
    .pageTrade .fast .lever .item:first-child,
    .pageTrade .professional .lever .item:first-child {
      border-left: 1px solid var(--l1); }
    .pageTrade .fast .lever .item,
    .pageTrade .professional .lever .item {
      border-right: 1px solid var(--l1);
      margin: 0; }
    .pageTrade .fast .lever .item:last-child,
    .pageTrade .professional .lever .item:last-child {
      border-right: 0 none; }
    .pageTrade .fast .lever .active,
    .pageTrade .professional .lever .active {
      background-color: var(--active);
      border-right: 0 none;
      color: var(--bg1); }
  .pageTrade .fast-action,
  .pageTrade .professional-action {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0; }
  .pageTrade .fast .btn-group div {
    line-height: 0.53333rem;
    text-align: center;
    font-size: 0.42667rem;
    line-height: 150%; }
  .pageTrade .fast .btn-group .buy,
  .pageTrade .fast .btn-group .sell {
    padding-top: 0.13333rem;
    padding-bottom: 0.13333rem; }
  .pageTrade .fast .btn-group .buy {
    background-color: var(--f1);
    color: var(--fff); }
  .pageTrade .fast .btn-group .sell {
    background-color: var(--r1);
    color: var(--fff); }
  .pageTrade .lever-list {
    position: relative; }
    .pageTrade .lever-list .list {
      position: absolute;
      z-index: 99;
      right: -1px;
      top: -1px;
      width: 100%;
      background-color: var(--bg1);
      border: 1px solid var(--l1);
      text-align: center; }
      .pageTrade .lever-list .list div {
        border-bottom: 1px solid var(--l1); }
      .pageTrade .lever-list .list div:last-child {
        border-bottom: 0 none; }
  .pageTrade .professional .limit {
    background-color: var(--l1); }
  .pageTrade .professional .btn-group {
    padding-left: 0.26667rem;
    padding-right: 0.26667rem; }
    .pageTrade .professional .btn-group .buy,
    .pageTrade .professional .btn-group .sell {
      background: transparent;
      border: 0 none;
      display: block;
      width: 100%;
      border: 1px solid transparent;
      border-radius: 2px; }
    .pageTrade .professional .btn-group .buy {
      border-color: var(--f1);
      background-color: var(--f2); }
    .pageTrade .professional .btn-group .buy.active {
      color: var(--fff);
      background-color: var(--f1); }
    .pageTrade .professional .btn-group .sell {
      border-color: var(--r1);
      background-color: var(--r1); }
    .pageTrade .professional .btn-group .sell.active {
      color: var(--fff);
      background-color: var(--r1); }
  .pageTrade .professional .content {
    padding-left: 0.26667rem;
    padding-right: 0.26667rem; }
  .pageTrade .professional .cube {
    border: 1px solid var(--l1);
    border-radius: 2px; }
  .pageTrade .professional .label {
    padding-left: 0.13333rem; }
  .pageTrade .professional .des-label {
    margin-left: 0.4rem;
    margin-right: 0.4rem; }
  .pageTrade .professional .sub {
    color: var(--t2); }
  .pageTrade .professional .action-group .left {
    padding-top: 0.26667rem;
    border-top: 1px solid var(--bg1);
    background-color: var(--bg2); }
  .pageTrade .professional .action-group div {
    line-height: 150%; }
  .pageTrade .professional .action-group button {
    padding-top: 0.26667rem;
    padding-bottom: 0.26667rem;
    border: 0 none;
    width: 100%;
    display: block;
    color: var(--fff); }
  .pageTrade .professional .action-group .raise {
    background-color: var(--f1); }
  .pageTrade .professional .action-group .fall {
    background-color: var(--r1); }
  .pageTrade .stepper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pageTrade .stepper i {
      width: 25%; }
    .pageTrade .stepper input {
      width: 50%;
      text-align: center; }
  .pageTrade .setting-modal div {
    line-height: 0.8rem; }

.selectCon {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto; }
  .selectCon .selectFont {
    color: var(--mainFont);
    font-size: large;
    padding: 0 0.13333rem;
    font-family: 'DIN,DIN-Medium'; }
  .selectCon .selectBoxStyle {
    width: 4rem !important;
    border-color: transparent !important;
    font-size: 0.4rem;
    font-family: var(--pMFont);
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.13333rem 0.26667rem;
    border-radius: 3px;
    color: #E6E8EC;
    border-style: none;
    box-shadow: none; }
  .selectCon .dropdown {
    background-color: #23262F;
    max-height: 3.2rem;
    overflow: scroll; }
    .selectCon .dropdown .fiatCell {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
      color: var(--mainFont10);
      padding: 0.13333rem 0.26667rem; }
      .selectCon .dropdown .fiatCell.active {
        background-color: var(--active);
        color: black; }
      .selectCon .dropdown .fiatCell img {
        width: 0.45333rem;
        height: 0.45333rem; }
      .selectCon .dropdown .fiatCell p {
        font-size: 0.32rem;
        line-height: 0.32rem;
        font-weight: 500;
        margin-left: 0.13333rem; }
