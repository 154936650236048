@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-TVchart {
  display: flex;
  flex-direction: column;
  position: relative; }
  .components-TVchart .indicator_bar {
    height: 0.98667rem;
    display: flex;
    padding: 0.26667rem 0.66667rem;
    justify-content: space-between;
    border-top: 0.02667rem solid var(--line-bg5);
    border-bottom: 0.02667rem solid var(--line-bg5);
    background-color: var(--bg12);
    color: var(--t10);
    font-size: 0.37333rem;
    font-family: 'DIN,DIN-Medium';
    font-weight: 500; }
    .components-TVchart .indicator_bar .active {
      color: var(--mainFont4); }
    .components-TVchart .indicator_bar .left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 45%;
      height: 100%;
      padding-right: 0.4rem;
      border-right: 0.02667rem solid var(--line-bg6); }
      .components-TVchart .indicator_bar .left > div {
        height: 120%; }
        .components-TVchart .indicator_bar .left > div > img {
          height: 75%;
          color: var(--line-bg6); }
    .components-TVchart .indicator_bar .right {
      display: flex;
      width: 55%;
      margin-left: 0.4rem;
      justify-content: space-around;
      align-items: center;
      color: var(--t10); }
  .components-TVchart ul,
  .components-TVchart li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center; }
  .components-TVchart li.active {
    color: var(--active);
    position: relative;
    font-weight: bold; }
  .components-TVchart .menuBox {
    position: relative;
    border-bottom: 0.02667rem solid var(--bg6); }
    .components-TVchart .menuBox.fullmenu {
      margin-top: 0.66667rem; }
    .components-TVchart .menuBox .menu {
      width: 90%;
      padding: 0 0.10667rem;
      line-height: 0.90667rem;
      background-color: var(--bg12);
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 2;
      color: var(--mainFont10);
      font-size: 0.34667rem;
      font-family: 'DIN,DIN-Medium'; }
      .components-TVchart .menuBox .menu li {
        flex: 1;
        font-family: 'DIN,DIN-Medium';
        font-size: 0.32rem;
        font-weight: 500; }
      .components-TVchart .menuBox .menu .active {
        color: var(--active); }
      .components-TVchart .menuBox .menu .active:after {
        content: "";
        width: 0.4rem;
        height: 0.08rem;
        background: var(--active);
        position: absolute;
        bottom: 0;
        left: 34%;
        margin: auto; }
      .components-TVchart .menuBox .menu .rule {
        font-size: 0.34667rem; }
      .components-TVchart .menuBox .menu .switch,
      .components-TVchart .menuBox .menu .moreButtonBox,
      .components-TVchart .menuBox .menu .switch-TV {
        display: flex;
        justify-content: center;
        position: relative; }
        .components-TVchart .menuBox .menu .switch .triangleBox,
        .components-TVchart .menuBox .menu .moreButtonBox .triangleBox,
        .components-TVchart .menuBox .menu .switch-TV .triangleBox {
          display: flex;
          align-items: flex-end; }
          .components-TVchart .menuBox .menu .switch .triangleBox .triangle,
          .components-TVchart .menuBox .menu .moreButtonBox .triangleBox .triangle,
          .components-TVchart .menuBox .menu .switch-TV .triangleBox .triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 5px 5px;
            border-color: transparent transparent var(--fff-bg) transparent;
            margin-bottom: 0.13333rem; }
        .components-TVchart .menuBox .menu .switch:before,
        .components-TVchart .menuBox .menu .moreButtonBox:before,
        .components-TVchart .menuBox .menu .switch-TV:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 0.53333rem;
          right: -0.16rem;
          transform: rotate(135deg);
          border: 5px solid transparent;
          border-bottom-color: var(--f6);
          z-index: 1; }
        .components-TVchart .menuBox .menu .switch.show,
        .components-TVchart .menuBox .menu .moreButtonBox.show,
        .components-TVchart .menuBox .menu .switch-TV.show {
          font-weight: bold; }
          .components-TVchart .menuBox .menu .switch.show:before,
          .components-TVchart .menuBox .menu .moreButtonBox.show:before,
          .components-TVchart .menuBox .menu .switch-TV.show:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0.53333rem;
            transform: rotate(135deg);
            border: 5px solid transparent;
            border-bottom-color: var(--active);
            z-index: 1; }
      .components-TVchart .menuBox .menu .zoomInAndOut,
      .components-TVchart .menuBox .menu .switch-TV {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.90667rem; }
        .components-TVchart .menuBox .menu .zoomInAndOut .C-Svg,
        .components-TVchart .menuBox .menu .switch-TV .C-Svg {
          zoom: 0.5; }
    .components-TVchart .menuBox .moreList {
      display: flex;
      margin: 0 auto;
      position: absolute;
      height: 1.17333rem;
      line-height: 1.17333rem;
      width: 100%;
      background: var(--bg12);
      z-index: 2; }
      .components-TVchart .menuBox .moreList .more-cell {
        width: 1.25333rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: var(--mainFont10);
        font-family: 'DIN,DIN-Medium'; }
        .components-TVchart .menuBox .moreList .more-cell.hide {
          float: right; }
      .components-TVchart .menuBox .moreList .active {
        color: var(--active); }
        .components-TVchart .menuBox .moreList .active:after {
          content: "";
          width: 0.4rem;
          height: 0.08rem;
          background: var(--active);
          position: absolute;
          bottom: 0.13333rem;
          left: 35%;
          margin: auto; }
    .components-TVchart .menuBox .m_1 {
      z-index: 3; }
    .components-TVchart .menuBox .moreList-hidden {
      visibility: hidden; }
  .components-TVchart .loading-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10; }
  .components-TVchart.tvfull {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000; }
    .components-TVchart.tvfull .menu {
      width: 100%; }
    .components-TVchart.tvfull .tradingView {
      height: 100%; }
    .components-TVchart.tvfull .indicator_bar {
      margin-top: -1px; }

.quota {
  width: 100%;
  display: flex;
  padding-top: 0.13333rem;
  padding-bottom: 0.13333rem;
  background-color: var(--bg2);
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-top: 1px solid var(--bg1);
  align-items: center; }
  .quota > div {
    flex: 1;
    display: flex;
    align-items: center; }
  .quota span:nth-child(1) {
    color: var(--t1);
    font-size: 0.53333rem; }
  .quota span:nth-child(2) {
    color: var(--r1);
    border: 1px solid var(--r1);
    padding-left: 0.13333rem;
    padding-right: 0.13333rem;
    margin-left: 0.26667rem;
    border-radius: 0.05333rem; }
  .quota em {
    text-align: center;
    font-style: inherit;
    font-size: 0.32rem; }

.fullscreenBtn {
  line-height: 2.75; }
  .fullscreenBtn > img {
    height: 0.32rem; }

.fullScreenTV {
  flex: 1; }

.chart-action {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  color: var(--t1);
  display: flex;
  background-color: var(--bg1); }
  .chart-action .resolution,
  .chart-action .ma,
  .chart-action .sub-ma {
    position: relative; }
  .chart-action .name {
    position: relative; }
    .chart-action .name i {
      position: relative;
      margin-left: 0.26667rem;
      top: 0.16rem;
      width: 0;
      height: 0;
      display: inline-block;
      border: 3px solid transparent;
      border-top-color: var(--t1);
      transform: rotate(-45deg); }
    .chart-action .name i:after {
      content: ""; }
  .chart-action div {
    line-height: 0.85333rem; }

.light .menu {
  background: var(--fff-bg); }
  .light .menu .active {
    position: relative; }
  .light .menu .active:after {
    content: "";
    width: 0.53333rem;
    height: 0.05333rem;
    background: var(--active);
    position: absolute;
    bottom: 0;
    left: 30%;
    margin: auto; }
  .light .menu .rule {
    font-size: 0.37333rem;
    font-weight: 500;
    color: var(--f5); }
