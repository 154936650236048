@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 1.33333rem;
  position: relative; }
  .main .quizInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-top: 0.13333rem; }
    .main .quizInfo .quizItem {
      padding: 0.13333rem 0.4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500;
      color: var(--mainFontActive);
      font-family: 'DIN,DIN-Medium'; }
      .main .quizInfo .quizItem > div {
        width: 25%; }
      .main .quizInfo .quizItem :nth-child(1) {
        flex: 1.2;
        margin-right: 0.08rem; }
      .main .quizInfo .quizItem :nth-child(2) {
        flex: 1.4;
        text-align: right;
        margin-right: 0.08rem; }
      .main .quizInfo .quizItem :nth-child(3) {
        flex: 0.85;
        text-align: right;
        margin-right: 0.08rem; }
      .main .quizInfo .quizItem :nth-child(4) {
        flex: 0.55;
        text-align: right; }
      .main .quizInfo .quizItem .finish {
        position: relative;
        text-align: right; }
        .main .quizInfo .quizItem .finish img {
          height: 0.37333rem; }
      .main .quizInfo .quizItem .buying {
        color: #02C076; }
      .main .quizInfo .quizItem .pending {
        color: #108ee9; }
  .main .noLogin {
    position: absolute;
    top: 0.53333rem !important;
    left: 50%;
    transform: translateX(-50%);
    min-height: 200px; }
    .main .noLogin a {
      text-decoration: underline;
      color: var(--active);
      margin: 0 0.13333rem; }
  .main .upDownCon {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0rem 0.4rem;
    border-bottom: 1px solid var(--bg1); }
    .main .upDownCon.upDownCon2 {
      height: auto;
      padding: 0.26667rem 0.4rem; }
    .main .upDownCon .priceInputContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--mainFont);
      background: var(--line-bg5);
      padding: 0rem 0.26667rem; }
      .main .upDownCon .priceInputContainer span {
        color: grey;
        font-size: 0.64rem; }
      .main .upDownCon .priceInputContainer .labelInput {
        height: 100%;
        width: 100%;
        background: var(--line-bg5);
        border: none;
        color: var(--mainFont);
        text-align: center; }
        .main .upDownCon .priceInputContainer .labelInput input {
          border: none;
          font-family: 'DIN,DIN-Medium';
          color: var(--mainFont4);
          text-align: center; }
    .main .upDownCon .prizeCon {
      padding: 0rem; }
      .main .upDownCon .prizeCon .amountLabel {
        font-size: 0.32rem;
        color: var(--mainFont3); }
    .main .upDownCon p {
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500;
      color: var(--mainFontActive);
      font-family: var(--pMFont); }
    .main .upDownCon .straight {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .main .upDownCon .straight :not(:first-child) {
        margin: 0 0.26667rem; }
    .main .upDownCon .listRender {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-top: 0.26667rem; }
      .main .upDownCon .listRender.price {
        gap: 0.18667rem; }
      .main .upDownCon .listRender .normalTime, .main .upDownCon .listRender .selectTime {
        width: 2.8rem;
        height: 0.8rem;
        background: var(--bg6);
        border-radius: 0.10667rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.37333rem;
        line-height: 0.37333rem;
        font-weight: 500;
        font-family: 'DIN,DIN-Medium';
        color: var(--fff); }
      .main .upDownCon .listRender .selectTime {
        background-color: var(--active);
        color: var(--btn-active-color); }
      .main .upDownCon .listRender .normalCash, .main .upDownCon .listRender .selectCash {
        font-family: 'DIN,DIN-Medium';
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
        width: 1.33333rem;
        height: 0.8rem;
        border-radius: 0.10667rem;
        background: var(--bg6);
        font-size: 0.37333rem;
        line-height: 0.37333rem;
        font-weight: 500;
        color: var(--mainFontActive); }
      .main .upDownCon .listRender .selectCash {
        background-color: var(--active);
        color: var(--btn-active-color); }
      .main .upDownCon .listRender .normalMantissa, .main .upDownCon .listRender .selectMantissa {
        font-family: 'DIN,DIN-Medium';
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: center;
        width: 1.33333rem;
        height: 0.8rem;
        margin-right: 0.13333rem;
        border-radius: 0.10667rem;
        background: var(--bg6);
        font-size: 0.37333rem;
        line-height: 0.37333rem;
        font-weight: 500;
        color: var(--mainFontActive); }
      .main .upDownCon .listRender .selectMantissa {
        background-color: var(--active);
        color: var(--btn-active-color); }
  .main .prizeCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0rem 0.4rem;
    font-size: 0.32rem;
    height: 0.93333rem;
    color: var(--mainFont3); }
    .main .prizeCon.noHeight {
      height: 0;
      margin-top: 0.4rem; }
    .main .prizeCon :nth-child(2) {
      font-family: 'DIN,DIN-Medium';
      color: var(--f5); }
    .main .prizeCon img {
      margin-top: -0.05333rem; }
  .main .balanceCon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 0.13333rem 0;
    font-size: 0.37333rem;
    font-weight: 400; }
    .main .balanceCon p:last-child {
      font-size: 0.4rem;
      font-weight: 500;
      color: var(--f5); }
  .main .buyBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: fixed;
    background: var(--bg15);
    bottom: 0;
    z-index: 10;
    padding: 0 0.4rem 0.26667rem;
    height: 1.6rem;
    gap: 0.26667rem; }
    .main .buyBox .buyButton, .main .buyBox .down, .main .buyBox .up, .main .buyBox .disabled {
      width: 50%;
      height: 1.06667rem;
      border-radius: 0.10667rem;
      margin: 0;
      color: var(--fff);
      text-align: center;
      font-size: 0.42667rem;
      line-height: 0.42667rem;
      font-weight: 500;
      line-height: 1.06667rem;
      font-family: var(--pMFont); }
    .main .buyBox .down {
      background-color: var(--f1); }
    .main .buyBox .up {
      background-color: var(--r1); }
    .main .buyBox .disabled {
      pointer-events: none;
      touch-action: none; }

.popup {
  width: 80%;
  border-radius: 0.16rem;
  background-color: var(--fff-bg);
  padding: 0 0.72rem 0.66667rem 0.72rem; }
  .popup .title {
    font-size: 0.48rem;
    font-weight: 400;
    color: var(--active);
    margin: 0.53333rem 0;
    margin-bottom: 0.4rem;
    line-height: 0.48rem; }
  .popup .popContent {
    margin-top: 0.32rem;
    font-size: 0.37333rem;
    line-height: 0.37333rem; }
    .popup .popContent p {
      font-size: 0.32rem;
      font-weight: 400;
      color: var(--night-font1, var(--f5)); }
      .popup .popContent p i {
        font-family: 'DIN,DIN-Medium';
        font-weight: 500; }
      .popup .popContent p.settingsContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.21333rem; }
      .popup .popContent p.billingContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.21333rem; }
      .popup .popContent p.btm {
        padding-bottom: 0.26667rem;
        border-bottom: 0.02667rem solid var(--bg6); }
    .popup .popContent div {
      padding-left: 0;
      padding-right: 0; }
    .popup .popContent .billingRecord {
      height: 40vh;
      overflow: auto;
      scroll-behavior: smooth; }
  .popup .popFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.26667rem; }
    .popup .popFooter .confirmBtn {
      width: 100%;
      line-height: 1.01333rem;
      border-radius: 0.10667rem;
      margin-top: 0.26667rem;
      text-align: center;
      background: var(--active);
      font-size: 0.4rem;
      font-weight: 500;
      color: var(--btn-active-color); }
    .popup .popFooter .cancelBtn {
      width: 100%;
      line-height: 1.01333rem;
      border-radius: 0.10667rem;
      margin-top: 0.13333rem;
      text-align: center;
      background: var(--fff-bg);
      font-size: 0.4rem;
      font-weight: 500;
      color: var(--active); }

.switch {
  background-color: var(--active); }

.switchOff {
  background-color: var(--bg6); }

:global(.ant-progress) {
  background: var(--bg3);
  transform: rotate(90deg);
  transform: scaleX(-1);
  margin-top: -5px; }

:global(.ant-progress-inner) {
  background: var(--bg3); }
