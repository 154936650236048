@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.eventCenter {
  font-family: "IBMPlexSans-Regular"; }
  .eventCenter .rewardStamp {
    margin: 0.85333rem 0; }
    .eventCenter .rewardStamp .noData {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 300px; }
    .eventCenter .rewardStamp .stamp {
      border: 1px solid #333333;
      border-radius: 0.16rem;
      padding: 0.42667rem;
      margin-bottom: 0.53333rem;
      background-color: rgba(39, 39, 39, 0.3); }
      .eventCenter .rewardStamp .stamp .text {
        font-size: 0.42667rem;
        margin-bottom: 0.10667rem; }
      .eventCenter .rewardStamp .stamp .subText {
        color: #7C7E87;
        font-size: 0.34667rem; }
      .eventCenter .rewardStamp .stamp .description {
        display: flex;
        margin-top: 0.69333rem; }
        .eventCenter .rewardStamp .stamp .description .amount {
          font-size: 0.58667rem;
          color: #49CDEE;
          font-weight: 900; }
      .eventCenter .rewardStamp .stamp .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        padding: 0.13333rem 0;
        width: 2.50667rem;
        color: var(--mainFont);
        font-size: 0.37333rem;
        white-space: nowrap;
        background-color: transparent;
        border-radius: 0.21333rem;
        border: 1px solid var(--mainFont); }
        .eventCenter .rewardStamp .stamp .btn img {
          width: 0.21333rem;
          height: 0.21333rem;
          margin: 0; }
      .eventCenter .rewardStamp .stamp.claim .btn {
        color: #00F2ED;
        border: 1px solid #00F2ED; }
      .eventCenter .rewardStamp .stamp.unlocked .btn {
        border: 1px transparent;
        color: #888888; }
  .eventCenter.light .rewardStamp .stamp {
    background-color: #E7E9EC; }
