@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-tagView {
  padding: 0 0.4rem; }
  .module-tagView .tv-row {
    display: flex;
    justify-content: space-between;
    padding-top: 0.53333rem; }
    .module-tagView .tv-row .tv-row-system {
      display: flex; }
      .module-tagView .tv-row .tv-row-system .tv-row-system-tagBox {
        width: 0.32rem;
        margin-right: 0.13333rem; }
        .module-tagView .tv-row .tv-row-system .tv-row-system-tagBox.FIFA {
          width: 0.8rem; }
        .module-tagView .tv-row .tv-row-system .tv-row-system-tagBox img {
          width: 100%; }
    .module-tagView .tv-row .tv-row-favorite {
      width: 0.32rem; }
      .module-tagView .tv-row .tv-row-favorite img {
        width: 100%; }
  .module-tagView .tv-custom {
    margin-top: 0.18667rem; }
    .module-tagView .tv-custom .tv-custom-tagBox {
      display: inline-block;
      background: var(--pbg1);
      color: var(--pFont1);
      font-family: 'DIN,DIN-Medium';
      font-size: 0.26667rem;
      margin-right: 0.13333rem;
      margin-top: 0.13333rem;
      border-radius: 3px;
      padding: 0.05333rem 0.2rem; }
