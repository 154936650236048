@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-input-stepper {
  color: var(--t1);
  display: flex;
  line-height: 0.8rem;
  padding-left: 0.26667rem;
  border: 1px solid var(--l1);
  box-sizing: border-box; }
  .components-input-stepper input {
    border: 0 none;
    background-color: transparent;
    flex: 1;
    text-align: left;
    width: 0; }
  .components-input-stepper i {
    border-left: 1px solid var(--l1);
    min-width: 0.8rem;
    line-height: 0.8rem; }
  .components-input-stepper svg {
    position: relative;
    top: 0.05333rem;
    left: 0.02667rem; }
