@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-cAddAliPay {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .stage-cAddAliPay .caap-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem; }
    .stage-cAddAliPay .caap-main .caap-main-data {
      flex: 1; }
      .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-block {
        margin-bottom: 0.8rem; }
        .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-block .caap-main-data-block-title {
          font-size: 0.4rem;
          color: var(--mainFontActive);
          font-weight: 500;
          margin: 0 0.4rem; }
        .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-block .caap-main-data-block-inputBox {
          background: var(--bg6);
          border-radius: 0.10667rem;
          margin: 0.4rem 0.4rem 0;
          display: flex; }
          .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-block .caap-main-data-block-inputBox .caap-main-data-block-inputBox-input {
            height: 38px;
            font-size: 0.4rem;
            color: #777E90;
            font-weight: 500;
            display: flex;
            align-items: center;
            background: transparent;
            flex: 1;
            outline: none;
            box-shadow: none;
            border: 0; }
          .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-block .caap-main-data-block-inputBox input::placeholder {
            color: #353945; }
          .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-block .caap-main-data-block-inputBox > div {
            margin-left: 0.4rem; }
      .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-uploadBlock {
        margin-left: 0.4rem; }
        .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-uploadBlock .caap-main-data-uploadBlock-title {
          font-size: 0.37333rem;
          color: #777E90;
          font-weight: 400; }
        .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-uploadBlock .caap-main-data-uploadBlock-upload {
          width: 2.93333rem;
          height: 2.93333rem;
          background: var(--bg1);
          margin-top: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column; }
          .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-uploadBlock .caap-main-data-uploadBlock-upload .caap-main-data-uploadBlock-upload-image {
            width: 0.66667rem;
            height: 0.66667rem; }
            .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-uploadBlock .caap-main-data-uploadBlock-upload .caap-main-data-uploadBlock-upload-image svg {
              width: 100%; }
          .stage-cAddAliPay .caap-main .caap-main-data .caap-main-data-uploadBlock .caap-main-data-uploadBlock-upload .caap-main-data-uploadBlock-upload-text {
            font-size: 0.37333rem;
            color: #777E90;
            font-weight: 400;
            margin-top: 0.26667rem; }
    .stage-cAddAliPay .caap-main .caap-main-bottom {
      margin: 0 0.4rem; }
      .stage-cAddAliPay .caap-main .caap-main-bottom .caap-main-bottom-desc {
        line-height: 0.48rem;
        font-size: 0.32rem;
        color: #777E90;
        font-weight: 400;
        margin-bottom: 0.8rem; }
      .stage-cAddAliPay .caap-main .caap-main-bottom .caap-main-bottom-btn {
        font-size: 0.42667rem;
        color: #141416;
        font-weight: 500;
        background: var(--active);
        height: 1.06667rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.06667rem;
        border-radius: 4px; }
