@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.ListHeader {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .ListHeader .bankItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 0.42667rem;
    color: var(--f5);
    border-bottom: 1px solid var(--f8);
    height: 1.54667rem; }
    .ListHeader .bankItem .bankItemLeft span {
      color: var(--f5);
      font-size: 0.4rem;
      margin-right: 0.42667rem; }
    .ListHeader .bankItem .bankItemRight {
      display: flex;
      align-items: center; }
      .ListHeader .bankItem .bankItemRight .icon {
        width: 0.64rem; }
        .ListHeader .bankItem .bankItemRight .icon img {
          width: 100%; }
      .ListHeader .bankItem .bankItemRight .nameInfo {
        font-size: 0.4rem;
        margin: 0 0.26667rem; }
        .ListHeader .bankItem .bankItemRight .nameInfo .name {
          color: var(--f5);
          font-weight: bold; }
  .ListHeader .chainCon {
    display: flex;
    flex-direction: column;
    padding: 0.26667rem 0;
    background-color: var(--fff-bg); }
    .ListHeader .chainCon .title {
      font-size: 0.32rem;
      font-weight: 400;
      color: var(--f6);
      margin-bottom: 0.26667rem; }
    .ListHeader .chainCon .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: flex-start; }
      .ListHeader .chainCon .content .normal, .ListHeader .chainCon .content .active {
        width: 2.88rem;
        height: 0.74667rem;
        line-height: 0.74667rem;
        text-align: center;
        background: var(--night-bg3, #fbfbfb);
        border-radius: 0.10667rem;
        color: var(--f6);
        border: 0.02667rem solid transparent;
        margin-right: 0.26667rem; }
      .ListHeader .chainCon .content .active {
        border: 0.02667rem solid var(--active);
        color: var(--active); }

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .page .header {
    background-color: var(--fff-bg);
    color: var(--f13); }
  .page .container {
    margin-top: 1.17333rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: var(--bg1); }
    .page .container .listContainer {
      flex: 1;
      overflow: hidden;
      overflow-y: auto; }
      .page .container .listContainer .bank_item {
        margin: 0.02667rem 0;
        padding: 0.38667rem 0.4rem;
        background-color: var(--fff-bg);
        position: relative;
        border: none; }
        .page .container .listContainer .bank_item:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 1px solid var(--bg1);
          transform: scaleY(0.5);
          transform-origin: 0 0;
          -webkit-transform: scaleY(0.5);
          -webkit-transform-origin: 0 0; }
        .page .container .listContainer .bank_item .bank_item_title {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: space-between; }
          .page .container .listContainer .bank_item .bank_item_title > div:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: center; }
            .page .container .listContainer .bank_item .bank_item_title > div:first-child .iconImg {
              width: 0.96rem;
              height: 0.96rem;
              display: inline-block; }
              .page .container .listContainer .bank_item .bank_item_title > div:first-child .iconImg img {
                width: 0.96rem;
                height: 0.96rem; }
            .page .container .listContainer .bank_item .bank_item_title > div:first-child .title_name {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              margin-left: 0.26667rem; }
              .page .container .listContainer .bank_item .bank_item_title > div:first-child .title_name > p {
                font-size: 0.42667rem;
                font-weight: 400;
                color: var(--f5); }
                .page .container .listContainer .bank_item .bank_item_title > div:first-child .title_name > p:last-child {
                  font-size: 0.32rem;
                  font-weight: 400;
                  color: var(--f6); }
    .page .container .addBtnContainer {
      padding: 0.4rem 0;
      margin: 0 0.8rem; }
      .page .container .addBtnContainer .acitveBtn {
        background-color: transparent;
        border: 0.02667rem solid var(--active);
        color: var(--active); }

:global .am-action-sheet {
  background: var(--active) !important; }

:global .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
  background: #1e2c37 !important; }
