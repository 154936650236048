@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-predictView {
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--pbg2); }
  .module-predictView .pv-catBox {
    display: flex;
    background: var(--pbg2);
    padding-top: 0.4rem; }
    .module-predictView .pv-catBox .pv-catBox-cat {
      font-family: 'DIN,DIN-Medium';
      font-size: 0.37333rem;
      color: var(--pFont1);
      flex: 1;
      text-align: center; }
      .module-predictView .pv-catBox .pv-catBox-cat.active {
        color: var(--pFont4); }
        .module-predictView .pv-catBox .pv-catBox-cat.active::after {
          content: '';
          height: 0.09333rem;
          width: 0.42667rem;
          background: var(--active);
          display: flex;
          margin: 0.26667rem auto 0; }
  .module-predictView .pv-listBox {
    flex: 1;
    display: flex;
    overflow: hidden; }
