@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-pOrderView .pov-configBox {
  display: flex; }
  .module-pOrderView .pov-configBox .pov-configBox-margin {
    background: var(--bg18);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.10667rem;
    min-width: 2.13333rem;
    position: relative; }
    .module-pOrderView .pov-configBox .pov-configBox-margin .pov-configBox-margin-text {
      font-size: 0.34667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: var(--mainFont); }
    .module-pOrderView .pov-configBox .pov-configBox-margin .pov-configBox-margin-image {
      width: 0.26667rem;
      height: 0.18667rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto 0.10667rem auto auto; }
      .module-pOrderView .pov-configBox .pov-configBox-margin .pov-configBox-margin-image svg {
        width: 100%; }
  .module-pOrderView .pov-configBox .pov-configBox-lever {
    display: flex;
    align-items: center;
    background: var(--bg18);
    padding: 0 0.26667rem 0 0.4rem;
    height: 0.69333rem;
    border-radius: 0.10667rem;
    margin-left: 0.4rem; }
    .module-pOrderView .pov-configBox .pov-configBox-lever .pov-configBox-lever-text {
      font-size: 0.34667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: var(--mainFont); }
    .module-pOrderView .pov-configBox .pov-configBox-lever .pov-configBox-lever-image {
      width: 0.21333rem;
      height: 0.16rem;
      margin-left: 0.4rem; }
      .module-pOrderView .pov-configBox .pov-configBox-lever .pov-configBox-lever-image svg {
        width: 100%; }

.module-pOrderView .pov-directionBox {
  display: flex;
  margin-top: 0.26667rem; }
  .module-pOrderView .pov-directionBox .pov-directionBox-btn {
    flex: 1;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: var(--mainFont);
    background-repeat: no-repeat !important;
    background-size: contain !important; }
  .module-pOrderView .pov-directionBox .p-buy {
    background: url("./../../assets/svg/perpetual/buyUp_h_night.svg"); }
  .module-pOrderView .pov-directionBox .p-sell {
    background: url("./../../assets/svg/perpetual/buyDown_h_night.svg"); }
  .module-pOrderView .pov-directionBox .pov-directionBox-btn.p-buy.green {
    background: url("./../../assets/svg/perpetual/left-g.svg");
    color: #fff; }
  .module-pOrderView .pov-directionBox .pov-directionBox-btn.p-buy.red {
    background: url("./../../assets/svg/perpetual/left-r.svg");
    color: #fff; }
  .module-pOrderView .pov-directionBox .pov-directionBox-btn.p-sell.green {
    background: url("./../../assets/svg/perpetual/right-g.svg");
    color: #fff; }
  .module-pOrderView .pov-directionBox .pov-directionBox-btn.p-sell.red {
    background: url("./../../assets/svg/perpetual/right-r.svg");
    color: #fff; }

.module-pOrderView .pov-typeBox {
  margin-top: 0.26667rem; }

.module-pOrderView .pov-inputBox {
  margin-top: 0.26667rem; }
  .module-pOrderView .pov-inputBox .pov-inputBox-unitBox {
    display: flex;
    margin-bottom: 0.08rem; }
    .module-pOrderView .pov-inputBox .pov-inputBox-unitBox .pov-inputBox-unitBox-unit {
      flex: 1;
      height: 0.64rem;
      background: var(--bg18);
      font-size: 0.32rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      line-height: 0.64rem;
      color: #777E90;
      text-align: center; }
      .module-pOrderView .pov-inputBox .pov-inputBox-unitBox .pov-inputBox-unitBox-unit:first-child {
        margin-right: 0.05333rem;
        border-radius: 0.10667rem 0px 0px 0.10667rem; }
      .module-pOrderView .pov-inputBox .pov-inputBox-unitBox .pov-inputBox-unitBox-unit:last-child {
        margin-left: 0.05333rem;
        border-radius: 0px 0.10667rem 0.10667rem 0px; }
      .module-pOrderView .pov-inputBox .pov-inputBox-unitBox .pov-inputBox-unitBox-unit.active {
        color: var(--mainFont); }
  .module-pOrderView .pov-inputBox input {
    text-align: center; }

.module-pOrderView .pov-openButton {
  border-radius: 0.10667rem;
  font-size: 0.42667rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #FFFFFF;
  height: 1.01333rem;
  line-height: 1.01333rem;
  margin-top: 0.26667rem;
  text-align: center; }
  .module-pOrderView .pov-openButton.red {
    background: var(--r1); }
  .module-pOrderView .pov-openButton.green {
    background: var(--f1); }

.module-pOrderView .pov-sliderBox {
  padding: 0.8rem 0rem 0.53333rem 0.21333rem; }
  .module-pOrderView .pov-sliderBox .am-slider-handle {
    border-radius: 0.05333rem;
    border: 0.05333rem solid var(--bg16);
    background-color: var(--t10);
    transform: rotate(45deg);
    width: 0.42667rem;
    height: 0.42667rem;
    touch-action: none;
    top: 0.10667rem; }
  .module-pOrderView .pov-sliderBox .marginRate {
    position: absolute;
    top: -0.8rem;
    width: 0.85333rem;
    height: 0.4rem;
    opacity: 1;
    background: var(--mainFont10);
    border-radius: 0.05333rem;
    font-size: 0.32rem;
    font-family: 'DIN,DIN-Medium';
    font-weight: 500;
    text-align: center;
    color: var(--f15);
    line-height: 0.4rem; }
  .module-pOrderView .pov-sliderBox .am-slider-track {
    background-color: var(--t10); }
  .module-pOrderView .pov-sliderBox .am-slider-rail {
    background-color: var(--border3); }
  .module-pOrderView .pov-sliderBox .am-slider-dot {
    border-radius: 0.02667rem;
    transform: rotate(45deg);
    width: 0.26667rem;
    height: 0.26667rem;
    border: 0.02667rem solid var(--border3);
    background-color: var(--bg16);
    top: -0.10667rem; }
  .module-pOrderView .pov-sliderBox .am-slider-dot-active {
    border: 0.05333rem solid var(--bg16);
    background-color: var(--t10); }

.module-pOrderView .pov-infoBox {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin-top: 0.26667rem; }
  .module-pOrderView .pov-infoBox .pov-infoBox-title {
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #777E90; }
  .module-pOrderView .pov-infoBox .marginTips {
    border-bottom: 1px dashed #777E90; }
  .module-pOrderView .pov-infoBox .pov-infoBox-content {
    display: flex;
    align-items: center; }
    .module-pOrderView .pov-infoBox .pov-infoBox-content .pov-infoBox-content-text {
      font-size: 0.32rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      color: var(--mainFont); }
    .module-pOrderView .pov-infoBox .pov-infoBox-content .pov-infoBox-content-image {
      fill: var(--active);
      width: 0.32rem;
      height: 0.4rem;
      margin-left: 0.13333rem; }
      .module-pOrderView .pov-infoBox .pov-infoBox-content .pov-infoBox-content-image svg {
        width: 100%; }

.module-pOrderView .pov-modalBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.module-pOrderView.light .pov-directionBox .p-buy {
  background: url("./../../assets/svg/perpetual/buyUp_h_light.svg"); }

.module-pOrderView.light .pov-directionBox .p-sell {
  background: url("./../../assets/svg/perpetual/buyDown_h_light.svg"); }

.module-popoverSelectView.tradeType .psv-block {
  flex: 1;
  position: relative;
  justify-content: center; }
  .module-popoverSelectView.tradeType .psv-block .psv-block-title {
    text-align: center; }
  .module-popoverSelectView.tradeType .psv-block .psv-block-image {
    position: absolute;
    right: 0rem; }

.psv-tt-pop .am-popover-item-container .p-items {
  text-align: center; }

.psv-tt-pop .am-popover-item-container::before {
  background: var(--border3) !important; }
