@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.alertModelV3 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: table; }
  .alertModelV3 .alertCellV3 {
    display: table-cell;
    vertical-align: middle; }
  .alertModelV3 .alertBodyV3 {
    width: 80%;
    margin: 0 auto;
    min-height: 7.2rem;
    background: var(--fff-bg);
    border-radius: 0.13333rem;
    overflow: hidden; }
    .alertModelV3 .alertBodyV3 .alertTitleV3 {
      text-align: center;
      font-size: 0rem; }
    .alertModelV3 .alertBodyV3 .alertMainV3 {
      display: flex;
      padding: 0.53333rem;
      font-size: 0.42667rem;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      min-height: 3.36rem;
      background: var(--fff-bg);
      color: var(--night-font1, var(--f5));
      border-bottom: 1px solid var(--night-inputBg, #f5f5f5); }
      .alertModelV3 .alertBodyV3 .alertMainV3 input {
        border: 1px solid var(--f8);
        color: var(--f5); }
        .alertModelV3 .alertBodyV3 .alertMainV3 input::placeholder {
          color: var(--f9); }
      .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-titleV3 {
        text-align: left;
        font-size: 0.4rem;
        color: var(--active);
        padding-bottom: 0.26667rem; }
      .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-contentV3 {
        padding-top: 0.26667rem; }
        .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-contentV3 .infoBoxV3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.8rem;
          line-height: 0.8rem; }
          .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-contentV3 .infoBoxV3 .titleV3 {
            font-size: 0.34667rem; }
          .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-contentV3 .infoBoxV3 .contentV3 {
            font-size: 0.34667rem; }
      .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-finallyV3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
        line-height: 0.8rem;
        border-top: 1px solid rgba(39, 53, 79, 0.1);
        padding-top: 0.53333rem;
        margin-top: 0.26667rem; }
        .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-finallyV3 .titleV3 {
          font-size: 0.34667rem; }
        .alertModelV3 .alertBodyV3 .alertMainV3 .module-buyV3 .module-buy-finallyV3 .contentV3 {
          font-size: 0.34667rem; }
      .alertModelV3 .alertBodyV3 .alertMainV3 .alert-feeBoxV3 div {
        margin: 0 0.13333rem; }
      .alertModelV3 .alertBodyV3 .alertMainV3 .alert-feeBoxV3 .moneyV3 {
        color: var(--f5);
        font-weight: bold; }
    .alertModelV3 .alertBodyV3 .alertFootV3 {
      height: 1.01333rem;
      line-height: 1.17333rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.86667rem; }
      .alertModelV3 .alertBodyV3 .alertFootV3 .alertButtonV3 {
        flex: 1;
        text-align: center;
        font-size: 0.42667rem;
        height: 1.01333rem;
        align-self: center;
        width: 85%;
        border-radius: 6px; }
        .alertModelV3 .alertBodyV3 .alertFootV3 .alertButtonV3.active {
          height: 100%;
          color: var(--t6);
          background: var(--active); }
        .alertModelV3 .alertBodyV3 .alertFootV3 .alertButtonV3.cancel {
          height: 100%;
          color: var(--active);
          background: var(--fff-bg);
          margin-top: 0.13333rem; }
  .alertModelV3 .transferV3 {
    animation: animate 0.3s ease-out; }

@keyframes animate {
  0% {
    transform: translateY(100vh); }
  50% {
    transform: translateY(50vh); }
  100% {
    transform: translateY(0); } }
  .alertModelV3 .move-outV3 {
    animation: out 0.2s ease-in forwards; }

@keyframes out {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100vh); } }
