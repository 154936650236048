.headerImg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  animation: headRotate 900ms linear infinite;
}

.hideHeadImg {
  @extend .headerImg;
  animation-play-state: paused;
  display: none;
}

.stopAni {
  @extend .headerImg;
  animation-play-state: paused;
}

@keyframes headRotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
