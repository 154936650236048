@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-cMineMoreData {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .stage-cMineMoreData .cmmd-main {
    flex: 1;
    margin: 1.6rem 0.4rem 0; }
    .stage-cMineMoreData .cmmd-main .cmmd-main-block {
      padding-top: 0.53333rem; }
      .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row {
        color: #777e90;
        font-size: 0.37333rem;
        margin-bottom: 0.53333rem;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-title {
          font-weight: 400; }
          .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-title i {
            font-family: 'DIN,DIN-Medium';
            font-weight: 500; }
        .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content {
          font-weight: 500;
          display: flex;
          align-items: center; }
          .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content span {
            color: #e6e8ec;
            font-family: 'DIN,DIN-Medium';
            margin: 0 0.13333rem; }
          .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content .cmmd-main-block-row-content-line {
            width: 2px;
            height: 0.26667rem;
            background: #4f5867;
            margin: 0 0.26667rem 0 0.13333rem; }
          .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content .cmmd-main-block-row-content-switch {
            display: flex;
            align-items: center;
            background: #23262f;
            border-radius: 0.10667rem;
            padding: 0.18667rem 0.4rem; }
            .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content .cmmd-main-block-row-content-switch .cmmd-main-block-row-content-switch-title {
              font-size: 0.4rem;
              font-family: 'DIN,DIN-Medium';
              font-weight: 500;
              line-height: 1; }
            .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content .cmmd-main-block-row-content-switch .cmmd-main-block-row-content-switch-image {
              width: 0.21333rem;
              height: 0.16rem;
              margin-left: 0.26667rem; }
              .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content .cmmd-main-block-row-content-switch .cmmd-main-block-row-content-switch-image svg {
                width: 100%; }
        .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row .coin-font {
          font-family: 'DIN,DIN-Medium'; }
      .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-row.mt1 {
        margin-top: -0.26667rem; }
      .stage-cMineMoreData .cmmd-main .cmmd-main-block .cmmd-main-block-line {
        width: 100%;
        height: 2px;
        background: #23262f; }
  .stage-cMineMoreData .aaaaa {
    width: 90%;
    height: 60px;
    left: 5%;
    bottom: 70px;
    background: white;
    border-radius: 4px;
    opacity: 0;
    position: absolute;
    z-index: 10;
    transform: translateY(130%);
    transition-timing-function: ease-in;
    transition: 0.2s; }
  .stage-cMineMoreData .alert-is-shown {
    transition: 0.25s;
    transition-timing-function: ease-out;
    transform: translateY(0);
    opacity: 1; }
  .stage-cMineMoreData.light .module-cHeader {
    background-color: #f7f7f7; }
    .stage-cMineMoreData.light .module-cHeader .h-title {
      color: var(--light-primary); }
  .stage-cMineMoreData.light .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content span {
    color: var(--f7); }
  .stage-cMineMoreData.light .cmmd-main .cmmd-main-block .cmmd-main-block-row .cmmd-main-block-row-content .cmmd-main-block-row-content-switch {
    background: var(--light-secondary); }
  .stage-cMineMoreData.light .cmmd-main .cmmd-main-block .cmmd-main-block-line {
    height: 1px;
    background: var(--light-secondary); }
  .stage-cMineMoreData.light .module-actionSheet {
    background: rgba(0, 0, 0, 0.6); }
    .stage-cMineMoreData.light .module-actionSheet .as-black {
      background: rgba(0, 0, 0, 0); }
    .stage-cMineMoreData.light .module-actionSheet .as-content {
      background-color: var(--light-bg); }
      .stage-cMineMoreData.light .module-actionSheet .as-content .as-content-listBox .as-content-listBox-header .as-content-listBox-header-title {
        font-weight: bolder; }
      .stage-cMineMoreData.light .module-actionSheet .as-content .as-content-listBox .as-content-listBox-list .as-content-listBox-list-cell {
        border-top: 1px solid var(--light-secondary); }

.module-actionSheet {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  transition-timing-function: ease-in;
  transition: 0.5s;
  background: rgba(0, 0, 0, 0.3); }
  .module-actionSheet .as-black {
    flex: 1;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition-timing-function: ease-in;
    transition: 0.5s; }
  .module-actionSheet .as-content {
    width: 100%;
    background: #1c1e24;
    border-radius: 0.64rem 0.64rem 0 0;
    transform: translateY(130%);
    transition-timing-function: ease-in;
    transition: 0.5s; }
    .module-actionSheet .as-content .as-content-listBox .as-content-listBox-header .as-content-listBox-header-title {
      font-size: 0.42667rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      color: #777e90;
      padding: 0.53333rem 0;
      text-align: center; }
    .module-actionSheet .as-content .as-content-listBox .as-content-listBox-list .as-content-listBox-list-cell {
      font-size: 0.42667rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      color: var(--mainFont10);
      padding: 0.53333rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid var(--bg6); }
    .module-actionSheet .as-content .as-content-listBox .as-content-listBox-list .as-content-listBox-list-cell.active {
      color: var(--active); }
  .module-actionSheet.show {
    opacity: 1;
    z-index: 5;
    transition-timing-function: ease-out;
    transition: 0.5s; }
    .module-actionSheet.show .as-black {
      transition-timing-function: ease-out;
      transition: 0.5s;
      opacity: 1; }
    .module-actionSheet.show .as-content {
      transition-timing-function: ease-out;
      transition: 0.5s;
      transform: translateY(0); }
