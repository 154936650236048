@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-chart-24 {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative; }
  .components-chart-24 ul,
  .components-chart-24 li {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    color: var(--f6); }
  .components-chart-24 li.active {
    color: var(--active);
    position: relative;
    font-weight: bold; }
  .components-chart-24 .menuBox {
    position: relative;
    height: 0.93333rem;
    z-index: 1;
    width: 90%; }
    .components-chart-24 .menuBox .menu {
      padding: 0 0.10667rem;
      line-height: 0.90667rem;
      background-color: var(--bg12);
      display: flex;
      flex-wrap: wrap;
      font-family: var(--pRFont); }
      .components-chart-24 .menuBox .menu li {
        flex: 1;
        font-family: 'DIN,DIN-Medium';
        font-size: 0.32rem;
        font-weight: 500; }
      .components-chart-24 .menuBox .menu .active:after {
        content: '';
        width: 0.4rem;
        height: 0.08rem;
        background: var(--active);
        position: absolute;
        bottom: 0;
        left: 34%;
        margin: auto; }
      .components-chart-24 .menuBox .menu .fs-13 {
        font-size: 0.34667rem; }
      .components-chart-24 .menuBox .menu .rule {
        font-size: 0.34667rem; }
      .components-chart-24 .menuBox .menu .switch, .components-chart-24 .menuBox .menu .moreButtonBox, .components-chart-24 .menuBox .menu .switch-TV {
        display: flex;
        justify-content: center;
        position: relative; }
        .components-chart-24 .menuBox .menu .switch .triangleBox, .components-chart-24 .menuBox .menu .moreButtonBox .triangleBox, .components-chart-24 .menuBox .menu .switch-TV .triangleBox {
          display: flex;
          align-items: flex-end; }
          .components-chart-24 .menuBox .menu .switch .triangleBox .triangle, .components-chart-24 .menuBox .menu .moreButtonBox .triangleBox .triangle, .components-chart-24 .menuBox .menu .switch-TV .triangleBox .triangle {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 5px 5px;
            border-color: transparent transparent var(--fff) transparent;
            margin-bottom: 0.13333rem; }
        .components-chart-24 .menuBox .menu .switch:before, .components-chart-24 .menuBox .menu .moreButtonBox:before, .components-chart-24 .menuBox .menu .switch-TV:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 0.53333rem;
          right: -0.13333rem;
          transform: rotate(135deg);
          border: 5px solid transparent;
          border-bottom-color: var(--f6);
          z-index: 1; }
        .components-chart-24 .menuBox .menu .switch.show, .components-chart-24 .menuBox .menu .moreButtonBox.show, .components-chart-24 .menuBox .menu .switch-TV.show {
          color: var(--active);
          font-weight: bold; }
          .components-chart-24 .menuBox .menu .switch.show:before, .components-chart-24 .menuBox .menu .moreButtonBox.show:before, .components-chart-24 .menuBox .menu .switch-TV.show:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0.53333rem;
            right: -0.13333rem;
            transform: rotate(135deg);
            border: 0.13333rem solid transparent;
            border-bottom-color: var(--active);
            z-index: 1; }
      .components-chart-24 .menuBox .menu .zoomInAndOut, .components-chart-24 .menuBox .menu .switch-TV {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.90667rem; }
        .components-chart-24 .menuBox .menu .zoomInAndOut .C-Svg, .components-chart-24 .menuBox .menu .switch-TV .C-Svg {
          zoom: 0.5; }
    .components-chart-24 .menuBox .moreList {
      display: flex;
      margin: 0 auto;
      position: absolute;
      bottom: -1.17333rem;
      height: 1.17333rem;
      line-height: 1.17333rem;
      width: 100%;
      background: var(--bg1);
      z-index: 2; }
      .components-chart-24 .menuBox .moreList .more-cell {
        width: 1.25333rem;
        font-size: 0.32rem;
        color: var(--f6); }
        .components-chart-24 .menuBox .moreList .more-cell.hide {
          float: right; }
      .components-chart-24 .menuBox .moreList .active {
        color: var(--active); }
        .components-chart-24 .menuBox .moreList .active:after {
          content: '';
          width: 0.4rem;
          height: 0.08rem;
          background: var(--active);
          position: absolute;
          bottom: 0.13333rem;
          left: 35%;
          margin: auto; }
    .components-chart-24 .menuBox .m_1 {
      z-index: 3; }
    .components-chart-24 .menuBox .moreList-hidden {
      visibility: hidden; }
  .components-chart-24 .tradingView .stop_select {
    /*ios，如果不行可以加一个透明遮罩层*/
    -webkit-touch-callout: none;
    touch-callout: none;
    /*安卓*/
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; }
  .components-chart-24 .lines {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    pointer-events: none; }
    .components-chart-24 .lines .h {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      border-bottom: 0.02667rem dashed var(--active);
      overflow: hidden;
      background: none; }
    .components-chart-24 .lines .v {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      border-left: 0.02667rem dashed var(--active);
      height: 100%;
      overflow: hidden;
      background: none; }
    .components-chart-24 .lines .sl-p {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 1.46667rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.05333rem 0.10667rem;
      color: var(--fff);
      font-size: 0.29333rem;
      border-radius: 0.10667rem;
      background-color: var(--active); }
    .components-chart-24 .lines .kl-p {
      position: absolute;
      top: 0rem;
      right: 0rem;
      z-index: 1;
      padding: 0.13333rem;
      line-height: 1.2;
      color: var(--fff);
      font-size: 0.26667rem;
      border-radius: 0.05333rem;
      background-color: var(--night-bg2, var(--fc5)); }
      .components-chart-24 .lines .kl-p b {
        display: block; }
        .components-chart-24 .lines .kl-p b:nth-child(odd) {
          color: var(--t1); }
        .components-chart-24 .lines .kl-p b:nth-child(even) {
          margin-bottom: .05rem; }
      .components-chart-24 .lines .kl-p .ma5 {
        color: var(--ma5); }
      .components-chart-24 .lines .kl-p .ma10 {
        color: var(--ma10); }
      .components-chart-24 .lines .kl-p .ma30 {
        color: var(--ma30); }
    .components-chart-24 .lines .m {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      line-height: 0.53333rem;
      color: var(--fff);
      font-size: 0.32rem;
      text-align: center;
      border-radius: 0.10667rem;
      background-color: var(--active); }
  .components-chart-24 .heartbeat {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 0.10667rem;
    height: 0.10667rem; }
    .components-chart-24 .heartbeat::before {
      content: '';
      position: absolute;
      width: 0.21333rem;
      height: 0.21333rem;
      border-radius: 50%;
      background-color: rgba(243, 173, 0, 0.1);
      animation: anim-heartbeat 1s ease-out infinite alternate-reverse; }
    .components-chart-24 .heartbeat::after {
      content: '';
      position: absolute;
      width: 0.21333rem;
      height: 0.21333rem;
      border: 0.05333rem solid var(--active);
      border-radius: 50%;
      background-color: var(--fff-bg); }

.quota {
  width: 100%;
  display: flex;
  padding-top: 0.13333rem;
  padding-bottom: 0.13333rem;
  background-color: var(--bg2);
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-top: 1px solid var(--bg1);
  align-items: center; }
  .quota > div {
    flex: 1;
    display: flex;
    align-items: center; }
  .quota span:nth-child(1) {
    color: var(--t1);
    font-size: 0.53333rem; }
  .quota span:nth-child(2) {
    color: var(--r1);
    border: 1px solid var(--r1);
    padding-left: 0.13333rem;
    padding-right: 0.13333rem;
    margin-left: 0.26667rem;
    border-radius: 0.05333rem; }
  .quota em {
    text-align: center;
    font-style: inherit;
    font-size: 0.32rem; }

.chart-action {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  color: var(--t1);
  display: flex;
  background-color: var(--bg1); }
  .chart-action .resolution,
  .chart-action .ma,
  .chart-action .sub-ma {
    position: relative; }
  .chart-action .name {
    position: relative; }
    .chart-action .name i {
      position: relative;
      margin-left: 0.26667rem;
      top: 0.16rem;
      width: 0;
      height: 0;
      display: inline-block;
      border: 3px solid transparent;
      border-top-color: var(--t1);
      transform: rotate(-45deg); }
    .chart-action .name i:after {
      content: ""; }
  .chart-action div {
    line-height: 0.85333rem; }

.light .menu {
  background: var(--fff-bg); }
  .light .menu .active {
    position: relative; }
  .light .menu .active:after {
    content: '';
    width: 0.53333rem;
    height: 0.05333rem;
    background: var(--active);
    position: absolute;
    bottom: 0;
    left: 30%;
    margin: auto; }
  .light .menu .rule {
    font-size: 0.37333rem;
    font-weight: 500;
    color: var(--f5); }

@keyframes anim-heartbeat {
  0% {
    opacity: 1;
    transform: scale(2.22); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(1); } }
