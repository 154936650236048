@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-menuList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0;
  z-index: -1;
  transition-timing-function: ease-in;
  transition: 0.2s;
  background: rgba(0, 0, 0, 0.5); }
  .module-menuList .ml-black {
    flex: 1;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-timing-function: ease-in;
    transition: 0.5s;
    position: absolute;
    left: 0;
    right: 0; }
  .module-menuList .ml-content {
    width: 92%;
    height: 100%;
    transform: translateX(-100%);
    transition-timing-function: ease-in;
    transition: 0.5s;
    background: var(--bg16);
    display: flex;
    flex-direction: column; }
    .module-menuList .ml-content .ml-content-searchBox {
      display: flex;
      align-items: center;
      padding: 0 0.4rem;
      height: 1.46667rem; }
      .module-menuList .ml-content .ml-content-searchBox .ml-content-searchBox-image {
        fill: #353945; }
      .module-menuList .ml-content .ml-content-searchBox .ml-content-searchBox-input {
        flex: 1;
        margin-left: 0.26667rem; }
        .module-menuList .ml-content .ml-content-searchBox .ml-content-searchBox-input input {
          width: 100%;
          background: transparent;
          border: none;
          color: var(--mainFont);
          font-family: 'DIN,DIN-Medium'; }
    .module-menuList .ml-content .ml-content-catBox {
      display: flex;
      padding: 0 0.4rem; }
      .module-menuList .ml-content .ml-content-catBox .ml-content-catBox-cat {
        color: #848e9c;
        font-size: 0.42667rem;
        font-weight: 500;
        margin-right: 0.8rem;
        position: relative; }
      .module-menuList .ml-content .ml-content-catBox .ml-content-catBox-cat.active {
        color: var(--mainFont); }
      .module-menuList .ml-content .ml-content-catBox .ml-content-catBox-cat.active::after {
        content: '';
        width: 0.4rem;
        height: 0.08rem;
        position: absolute;
        bottom: -0.26667rem;
        left: 0;
        right: 0;
        background: var(--active);
        margin: auto; }
    .module-menuList .ml-content .ml-content-listBox {
      width: 100%;
      padding: 0 0.32rem;
      margin-top: 0.53333rem;
      overflow: auto; }
      .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell {
        display: flex;
        align-items: center;
        height: 1.54667rem; }
        .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell.header {
          height: 0.90667rem;
          color: #b6bcc7; }
        .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-nameBox {
          flex: 1.6; }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-nameBox .ml-listBox-cellBox-cell-nameBox-name {
            font-size: 0.42667rem;
            font-weight: 500;
            font-family: 'DIN,DIN-Medium';
            color: var(--mainFont);
            display: flex;
            align-items: flex-end;
            flex: 1; }
            .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-nameBox .ml-listBox-cellBox-cell-nameBox-name span {
              color: #848e9c;
              font-size: 0.32rem; }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-nameBox .ml-listBox-cellBox-cell-nameBox-volume {
            color: var(--f6); }
        .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-priceBox {
          flex: 1; }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-priceBox .ml-listBox-cellBox-cell-priceBox-price {
            font-size: 0.42667rem;
            font-weight: 500;
            color: var(--mainFont);
            font-family: 'DIN,DIN-Medium'; }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-priceBox .ml-listBox-cellBox-cell-priceBox-fiat {
            color: #848e9c;
            font-size: 0.32rem; }
        .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-percentBox {
          flex: 1; }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-percentBox .ml-listBox-cellBox-cell-percentBox-percent {
            margin-left: auto;
            font-weight: 500;
            font-size: 0.4rem;
            color: #fff;
            height: 0.8rem;
            line-height: 0.8rem;
            text-align: center;
            border-radius: 2px;
            width: 2.02667rem;
            font-family: 'DIN,DIN-Medium'; }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-percentBox .red {
            background: var(--r1); }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-percentBox .green {
            background: var(--f1); }
          .module-menuList .ml-content .ml-content-listBox .ml-listBox-cellBox .ml-listBox-cellBox-cell .ml-listBox-cellBox-cell-percentBox.ta-right {
            text-align: right; }
  .module-menuList.show {
    opacity: 1;
    z-index: 10;
    transition-timing-function: ease-out;
    transition: 0.2s; }
    .module-menuList.show .ml-black {
      transition-timing-function: ease-out;
      transition: 0.5s;
      opacity: 1;
      height: 100%; }
    .module-menuList.show .ml-content {
      transition-timing-function: ease-out;
      transition: 0.5s;
      transform: translateX(0); }
