@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-country-selector {
  background-color: var(--bg1);
  position: relative;
  border: 0.02667rem solid var(--l1);
  border-top: 0 none;
  padding: 0.26667rem;
  line-height: 2.5em;
  height: 20em;
  overflow-y: scroll; }
  .components-country-selector span {
    display: inline-block; }
  .components-country-selector .num {
    min-width: 1.33333rem; }
  .components-country-selector .title {
    border-bottom: 0.02667rem solid var(--l1);
    color: var(--t1);
    margin-top: 0.26667rem;
    margin-bottom: 0.26667rem; }
