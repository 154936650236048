@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-myPrediction {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .stage-myPrediction .mp-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .stage-myPrediction .mp-main .mp-main-top {
      display: flex;
      justify-content: center;
      position: relative;
      background: var(--pbg2); }
      .stage-myPrediction .mp-main .mp-main-top .mp-main-top-catBox {
        display: flex;
        border: 2px solid var(--pLine1);
        margin-top: 0.26667rem;
        padding: 0.08rem 0.10667rem; }
        .stage-myPrediction .mp-main .mp-main-top .mp-main-top-catBox .mp-main-top-catBox-cat {
          color: var(--pFont1);
          font-family: 'DIN,DIN-Medium';
          font-size: 0.37333rem;
          width: 2rem;
          height: 0.82667rem;
          line-height: 0.82667rem;
          text-align: center; }
          .stage-myPrediction .mp-main .mp-main-top .mp-main-top-catBox .mp-main-top-catBox-cat.active {
            color: var(--pFont4);
            background: var(--pbg7); }
      .stage-myPrediction .mp-main .mp-main-top .mp-main-top-statsBox {
        width: 0.32rem;
        position: absolute;
        right: 0.4rem;
        height: 1.06667rem;
        display: flex;
        align-items: center;
        margin-top: 0.26667rem; }
        .stage-myPrediction .mp-main .mp-main-top .mp-main-top-statsBox svg {
          width: 100%; }
