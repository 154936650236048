@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-cFundHome {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .stage-cFundHome .cfh-main {
    flex: 1;
    margin-top: 1.6rem;
    overflow: auto; }
    .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell {
      padding: 0 0.4rem; }
      .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.66667rem; }
        .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-left {
          display: flex;
          align-items: center; }
          .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-left .cfh-main-list-cellBox-cell-titleBox-left-image {
            width: 0.42667rem;
            height: 0.42667rem; }
            .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-left .cfh-main-list-cellBox-cell-titleBox-left-image svg {
              width: 100%; }
          .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-left .cfh-main-list-cellBox-cell-titleBox-left-title {
            font-size: 0.42667rem;
            color: #e6e8ec;
            font-weight: 500;
            margin-left: 0.13333rem; }
        .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-right {
          width: 0.4rem;
          height: 0.4rem; }
          .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-right svg {
            width: 100%; }
      .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-name {
        font-size: 0.4rem;
        color: #e6e8ec;
        font-weight: 400;
        line-height: 1;
        margin-top: 0.53333rem; }
      .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-bank {
        font-size: 0.4rem;
        color: #e6e8ec;
        font-weight: 400;
        line-height: 1;
        margin-top: 0.4rem; }
      .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-branch {
        font-size: 0.4rem;
        color: #e6e8ec;
        font-weight: 400;
        line-height: 1;
        margin-top: 0.4rem; }
      .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-account {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        font-size: 0.53333rem;
        font-weight: 500;
        color: #e6e8ec;
        font-family: 'DIN,DIN-Medium';
        line-height: 1;
        margin-top: 0.4rem; }
      .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell > div:last-child {
        margin-bottom: 0.66667rem; }
    .stage-cFundHome .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-line {
      background: #23262f;
      height: 1px; }
  .stage-cFundHome .cfh-add {
    line-height: 1.06667rem;
    margin: 0.53333rem 0.4rem;
    background: var(--active);
    color: #141416;
    text-align: center;
    font-size: 0.42667rem;
    font-weight: 500;
    border-radius: 0.08rem; }
  .stage-cFundHome.light .module-cHeader {
    background-color: #f7f7f7; }
    .stage-cFundHome.light .module-cHeader .h-title {
      color: var(--light-primary); }
  .stage-cFundHome.light .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-titleBox .cfh-main-list-cellBox-cell-titleBox-left .cfh-main-list-cellBox-cell-titleBox-left-title {
    color: var(--light-primary); }
  .stage-cFundHome.light .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-name,
  .stage-cFundHome.light .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-bank,
  .stage-cFundHome.light .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-branch,
  .stage-cFundHome.light .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-cell .cfh-main-list-cellBox-cell-account {
    color: var(--light-primary); }
  .stage-cFundHome.light .cfh-main .cfh-main-list .cfh-main-list-cellBox .cfh-main-list-cellBox-line {
    background: var(--light-secondary); }

.imgCon {
  position: relative; }
  .imgCon .mainImg {
    width: 100%;
    height: 100%; }
