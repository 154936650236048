@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-leverConfigView {
  background: var(--bg16);
  border-radius: 0.64rem 0.64rem 0px 0px;
  padding: 0 0.4rem;
  display: flex;
  flex-direction: column; }
  .module-leverConfigView .lcv-header {
    height: 1.49333rem;
    line-height: 1.49333rem;
    text-align: center;
    border-bottom: 1px solid var(--border3);
    position: relative; }
    .module-leverConfigView .lcv-header .lcv-header-title {
      font-size: 0.42667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: var(--mainFont); }
    .module-leverConfigView .lcv-header .lcv-header-close {
      position: absolute;
      right: 0.4rem;
      top: 0.53333rem;
      fill: #777E90; }
  .module-leverConfigView .lcv-main .lcv-main-inputBox {
    margin-top: 0.53333rem; }
    .module-leverConfigView .lcv-main .lcv-main-inputBox .module-orderInput .oi-inputBox {
      flex: 1; }
      .module-leverConfigView .lcv-main .lcv-main-inputBox .module-orderInput .oi-inputBox input {
        width: 100%;
        text-align: center; }
  .module-leverConfigView .lcv-main .lcv-main-sliderBox {
    margin-top: 1.06667rem; }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-handle {
      border-radius: 0.05333rem;
      border: 0.05333rem solid var(--bg16);
      background-color: var(--t10);
      transform: rotate(45deg);
      width: 0.42667rem;
      height: 0.42667rem;
      touch-action: none;
      top: 0.10667rem; }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .lcv-main-sliderBox-rate {
      position: absolute;
      top: -0.8rem;
      width: 0.85333rem;
      height: 0.4rem;
      opacity: 1;
      background: var(--mainFont10);
      border-radius: 0.05333rem;
      font-size: 0.32rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      text-align: center;
      color: var(--f15);
      line-height: 0.4rem; }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-track {
      background-color: var(--t10); }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-rail {
      background-color: var(--border3); }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-dot {
      border-radius: 0.02667rem;
      transform: rotate(45deg);
      width: 0.26667rem;
      height: 0.26667rem;
      border: 0.02667rem solid var(--border3);
      background-color: var(--bg16);
      top: -0.10667rem; }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-dot-active {
      border: 0.05333rem solid var(--bg16);
      background-color: var(--t10); }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-mark .am-slider-mark-text {
      font-size: 0.32rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      color: #E6E8EC; }
    .module-leverConfigView .lcv-main .lcv-main-sliderBox .am-slider-mark .am-slider-mark-text-active {
      color: var(--mainFont);
      opacity: 1; }
  .module-leverConfigView .lcv-main .lcv-main-max {
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: var(--mainFont);
    padding-top: 1.33333rem; }
  .module-leverConfigView .lcv-main .lcv-main-desc {
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: var(--mainFont);
    margin-top: 0.26667rem; }
  .module-leverConfigView .lcv-submit {
    font-size: 0.42667rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    height: 1.06667rem;
    color: #141416;
    background-color: var(--active);
    border-radius: 0.08rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.53333rem;
    margin-bottom: 0.53333rem; }
