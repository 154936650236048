.module-bottomModal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    transition-timing-function: ease-in;
    transition: 0.1s;
    background: rgba($color: #000000, $alpha: 0.3);
    .bm-black{
        flex: 1;
        // background: rgba($color: #000000, $alpha: 0.3);
        opacity: 0;
        transition-timing-function: ease-in;
        transition: 0.5s;
    }
    .bm-content{
        width: 100%;
        // background: $bg2;
        border-radius: rem(24px) rem(24px) 0 0;
        transform: translateY(130%);
        transition-timing-function: ease-in;
        transition: 0.5s;
    }
    &.show {
        opacity: 1;
        z-index: 10;
        transition-timing-function: ease-out;
        transition: 0.5s;
        .bm-black {
          transition-timing-function: ease-out;
          transition: 0.5s;
          opacity: 1;
        }
        .bm-content {
          transition-timing-function: ease-out;
          transition: 0.5s;
          transform: translateY(0);
        }
    }
    
}