@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  margin-top: 1.17333rem;
  padding: 0.32rem;
  font-size: 0.32rem;
  color: #e7e9ec; }
  .page .header {
    font-size: 0.64rem;
    margin: 0.21333rem 0;
    color: #01c3fd; }
  .page .subHeader {
    font-size: 0.48rem;
    margin: 0.21333rem 0;
    color: #f4ca70; }
  .page .date {
    font-size: 0.42667rem;
    margin: 0.21333rem 0;
    color: #7c7e87; }
  .page .description {
    margin: 0.96rem 0; }
    .page .description .activity {
      margin: 0.42667rem 0; }
    .page .description .activityCon {
      margin-top: 0.96rem; }
      .page .description .activityCon .title {
        color: #96D8F5; }
      .page .description .activityCon .hint {
        font-size: 0.26667rem; }
  .page table {
    width: 100%;
    margin-bottom: 0.42667rem; }
  .page table, .page th, .page td {
    border: 1px solid white;
    border-collapse: collapse; }
  .page .rulesCon .ruleHeader {
    font-size: 0.37333rem; }
  .page .rulesCon .rule {
    line-height: 0.53333rem;
    color: #7c7e87; }
  .page .newAct > div {
    margin: 0.42667rem 0; }
  .page .newAct .title {
    color: #96D8F5; }
  .page .newAct .link {
    color: #f4ca70;
    cursor: pointer;
    text-decoration: underline; }
  .page .newAct .ruleCon .header {
    color: #e7e9ec;
    font-size: 0.37333rem;
    padding: 1rem 0; }
  .page .newAct .ruleCon .rule {
    color: #7c7e87;
    line-height: 0.53333rem; }
