@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-pQuote {
  display: flex;
  flex-direction: column; }
  .stage-pQuote .trade-header {
    height: 1.17333rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    width: 100%;
    z-index: 3;
    background-color: var(--bg3); }
    .stage-pQuote .trade-header.safeView1 {
      top: 1.06667rem; }
    .stage-pQuote .trade-header .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 1.17333rem;
      width: 0.56rem; }
      .stage-pQuote .trade-header .left .back {
        display: flex;
        padding-right: 0.4rem; }
        .stage-pQuote .trade-header .left .back .C-Svg {
          zoom: 0.5; }
      .stage-pQuote .trade-header .left .left_f {
        display: flex; }
        .stage-pQuote .trade-header .left .left_f.l {
          margin-left: 0.4rem; }
        .stage-pQuote .trade-header .left .left_f .C-Svg {
          zoom: 0.5; }
        .stage-pQuote .trade-header .left .left_f img {
          width: 0.53333rem;
          height: 0.53333rem; }
      .stage-pQuote .trade-header .left .left_r {
        display: flex;
        font-family: Source Han Sans CN;
        align-items: center;
        margin-left: 0.26667rem; }
        .stage-pQuote .trade-header .left .left_r .switch-title {
          width: 0.45333rem;
          height: 0.45333rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--bg5);
          font-size: 0.32rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: var(--bg5);
          line-height: 0.45333rem;
          border-radius: 0.05333rem; }
          .stage-pQuote .trade-header .left .left_r .switch-title.st {
            background: var(--bg5);
            color: var(--fff); }
        .stage-pQuote .trade-header .left .left_r .crypto {
          font-size: 0.48rem;
          font-weight: bold;
          color: #333333; }
        .stage-pQuote .trade-header .left .left_r .pr {
          font-size: 0.32rem;
          font-weight: 400;
          display: flex; }
          .stage-pQuote .trade-header .left .left_r .pr.red {
            color: #e76d42; }
          .stage-pQuote .trade-header .left .left_r .pr.green {
            color: #00cf8c; }
          .stage-pQuote .trade-header .left .left_r .pr .bg {
            display: inline-block;
            padding: 0 0.08rem; }
            .stage-pQuote .trade-header .left .left_r .pr .bg.red {
              background: rgba(231, 109, 66, 0.1); }
            .stage-pQuote .trade-header .left .left_r .pr .bg.green {
              background: rgba(0, 207, 140, 0.1); }
    .stage-pQuote .trade-header .center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 1.17333rem;
      font-size: 0.48rem;
      font-family: var(--pMFont);
      font-weight: bold;
      color: var(--headerFont); }
      .stage-pQuote .trade-header .center .button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.17333rem;
        background: var(--fff-bg);
        font-weight: 500;
        color: var(--f6);
        font-size: 0.42667rem;
        font-family: var(--pMFont);
        margin-right: 0.48rem; }
        .stage-pQuote .trade-header .center .button.active {
          color: var(--line-bg5); }
          .stage-pQuote .trade-header .center .button.active:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0.4rem;
            height: 0.08rem;
            margin: auto;
            background: var(--active); }
      .stage-pQuote .trade-header .center .bsk-tabs {
        padding: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 0.98667rem;
        border-radius: 0.10667rem;
        border: 0.02667rem solid var(--line-bg4); }
        .stage-pQuote .trade-header .center .bsk-tabs span {
          display: flex;
          justify-content: center;
          align-items: center; }
        .stage-pQuote .trade-header .center .bsk-tabs .bsk-tabs-item {
          font-size: 0.42667rem;
          font-family: var(--pMFont);
          font-weight: 500;
          color: var(--t10);
          width: 25%;
          height: 100%; }
        .stage-pQuote .trade-header .center .bsk-tabs .bsk-tabs-item-active {
          width: 2.24rem;
          height: 0.82667rem;
          font-size: 0.42667rem;
          font-family: var(--pMFont);
          font-weight: 500;
          color: var(--mainFont4);
          background: var(--night-bg2, var(--bg9));
          border-radius: 0.10667rem; }
    .stage-pQuote .trade-header .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 1.17333rem; }
      .stage-pQuote .trade-header .right .C-Svg {
        flex: 1; }
      .stage-pQuote .trade-header .right span {
        font-family: var(--pRFont);
        font-size: 0.37333rem;
        color: var(--f6);
        letter-spacing: 0; }
      .stage-pQuote .trade-header .right img {
        width: 0.53333rem;
        height: 0.53333rem; }
  .stage-pQuote .p-main {
    flex: 1;
    display: flex;
    flex-direction: column; }
    .stage-pQuote .p-main .p-main-quoteBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.53333rem;
      padding: 0 0.4rem; }
      .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row {
        display: flex;
        align-items: center; }
        .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info {
          display: flex;
          align-items: center;
          margin-left: 0.26667rem; }
          .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-name {
            font-size: 0.53333rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 600;
            color: var(--mainFont);
            line-height: 1; }
            .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-name span {
              margin-left: 0.26667rem; }
          .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-percent {
            font-size: 0.4rem;
            font-family: 'DIN,DIN-Medium';
            font-weight: 500;
            margin-left: 0.53333rem; }
          .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-percent.green {
            color: var(--f1); }
          .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-percent.red {
            color: var(--r1); }
        .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image {
          width: 0.42667rem;
          height: 0.42667rem;
          fill: var(--mainFont); }
          .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image svg, .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image img {
            width: 100%; }
      .stage-pQuote .p-main .p-main-quoteBox .p-main-quoteBox-row:last-child .p-main-quoteBox-row-image {
        margin-left: 0.32rem; }
    .stage-pQuote .p-main .p-main-dataBox {
      display: flex;
      justify-content: space-between;
      margin-top: 0.58667rem;
      padding: 0 0.4rem 0.4rem; }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .p-main-dataBox-left-price {
        font-size: 0.64rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 700;
        display: flex;
        align-items: center; }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .p-main-dataBox-left-imageBox {
        width: 0.37333rem;
        height: 0.42667rem;
        margin-left: 0.13333rem; }
        .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .p-main-dataBox-left-imageBox svg {
          width: 100%; }
        .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .p-main-dataBox-left-imageBox.raise {
          transform: rotate(180deg); }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .p-main-dataBox-left-direction .p-main-dataBox-left-direction-rate {
        font-size: 0.37333rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500; }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .p-main-dataBox-left-direction .p-main-dataBox-left-direction-percent {
        font-size: 0.37333rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        margin-left: 0.26667rem; }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .raise {
        color: var(--f1);
        fill: var(--f1); }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-left .fall {
        color: var(--r1);
        fill: var(--r1); }
      .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-right .p-main-dataBox-right-row {
        display: flex;
        justify-content: space-between; }
        .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-right .p-main-dataBox-right-row .p-main-dataBox-right-row-block .p-main-dataBox-right-row-block-title {
          font-size: 0.32rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #777E90;
          line-height: 1; }
        .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-right .p-main-dataBox-right-row .p-main-dataBox-right-row-block .p-main-dataBox-right-row-block-content {
          font-size: 0.32rem;
          font-family: 'DIN,DIN-Medium';
          font-weight: 500;
          color: var(--mainFont);
          line-height: 1;
          margin-top: 0.10667rem; }
        .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-right .p-main-dataBox-right-row .p-main-dataBox-right-row-block:nth-child(2) {
          margin-left: 0.8rem;
          text-align: right; }
        .stage-pQuote .p-main .p-main-dataBox .p-main-dataBox-right .p-main-dataBox-right-row:nth-child(2) {
          margin-top: 0.26667rem; }
    .stage-pQuote .p-main .p-main-chartBox .tradingView {
      position: relative;
      height: 8.53333rem; }
      .stage-pQuote .p-main .p-main-chartBox .tradingView #tradingView {
        height: 100%; }
    .stage-pQuote .p-main .p-main-chartBox .menuBox .menu {
      width: auto; }
    .stage-pQuote .p-main .p-main-infoBox {
      padding: 0 0.4rem; }
      .stage-pQuote .p-main .p-main-infoBox .p-main-infoBox-catBox {
        display: flex;
        align-items: center;
        height: 1.17333rem; }
        .stage-pQuote .p-main .p-main-infoBox .p-main-infoBox-catBox .p-main-infoBox-catBox-cat {
          display: flex;
          align-items: center;
          margin-right: 0.8rem;
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: var(--mainFont10); }
          .stage-pQuote .p-main .p-main-infoBox .p-main-infoBox-catBox .p-main-infoBox-catBox-cat.active {
            color: var(--mainFont4);
            position: relative; }
          .stage-pQuote .p-main .p-main-infoBox .p-main-infoBox-catBox .p-main-infoBox-catBox-cat.active::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -0.26667rem;
            width: 0.4rem;
            height: 3px;
            margin: auto;
            background: var(--active); }

.module-pqInfoView .pqiv-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #777E90;
  line-height: 1;
  height: 0.66667rem;
  margin-bottom: 0.10667rem; }
  .module-pqInfoView .pqiv-row .pqiv-row-title {
    font-size: 0.32rem; }
  .module-pqInfoView .pqiv-row .pqiv-row-content {
    font-size: 0.32rem;
    font-family: 'DIN,DIN-Medium';
    color: var(--mainFont); }

.module-pqTurnOverView .pqtov-list {
  color: #777e90; }
  .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox {
    height: 0.66667rem;
    margin-bottom: 0.10667rem; }
    .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell .pqtov-list-cellBox-cell-date {
        font-family: 'DIN,DIN-Medium';
        font-weight: 400;
        font-size: 0.32rem;
        flex: 1; }
      .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell .pqtov-list-cellBox-cell-price {
        font-family: 'DIN,DIN-Medium';
        font-weight: 400;
        font-size: 0.32rem;
        flex: 1;
        text-align: center; }
        .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell .pqtov-list-cellBox-cell-price.green {
          color: var(--f1); }
        .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell .pqtov-list-cellBox-cell-price.red {
          color: var(--r1); }
      .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell .pqtov-list-cellBox-cell-amount {
        font-family: 'DIN,DIN-Medium';
        font-weight: 400;
        font-size: 0.32rem;
        flex: 1;
        text-align: right; }
      .module-pqTurnOverView .pqtov-list .pqtov-list-cellBox .pqtov-list-cellBox-cell .title {
        font-size: 0.37333rem; }

.module-pqDepthView {
  display: flex; }
  .module-pqDepthView .pqdv-list {
    flex: 1; }
    .module-pqDepthView .pqdv-list .pqdv-list-cellBox {
      margin-bottom: 0.08rem; }
      .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0.66667rem; }
        .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-price {
          font-family: 'DIN,DIN-Medium';
          font-size: 0.32rem;
          line-height: 1;
          z-index: 2; }
          .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-price.bid {
            color: var(--f1);
            padding-right: 0.13333rem; }
          .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-price.ask {
            color: var(--r1);
            padding-left: 0.13333rem; }
        .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-amount {
          font-family: 'DIN,DIN-Medium';
          font-size: 0.32rem;
          text-align: right;
          line-height: 1;
          z-index: 2; }
        .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-bar {
          position: absolute;
          height: 100%;
          z-index: 1; }
          .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-bar.bid {
            background: #02c0761a;
            right: 0; }
          .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-bar.ask {
            background: rgba(248, 73, 96, 0.2);
            left: 0; }
          .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-bar.bidreverse {
            background: rgba(248, 73, 96, 0.2);
            right: 0; }
          .module-pqDepthView .pqdv-list .pqdv-list-cellBox .pqdv-list-cellBox-cell .pqdv-list-cellBox-cell-bar.askreverse {
            background: #02c0761a;
            left: 0; }
