@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-filterPicker {
  background: var(--pbg3);
  margin: auto;
  width: 90%;
  min-height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.26667rem; }
  .module-filterPicker .fp-title {
    height: 1.49333rem;
    line-height: 1.49333rem;
    display: flex;
    padding-left: 0.66667rem;
    border-bottom: 1px solid var(--pLine2);
    display: flex;
    align-items: center; }
    .module-filterPicker .fp-title .fp-title-image {
      width: 0.32rem;
      display: flex;
      margin-right: 0.4rem; }
      .module-filterPicker .fp-title .fp-title-image img {
        width: 100%; }
    .module-filterPicker .fp-title .fp-title-text {
      font-family: 'DIN,DIN-Medium';
      font-size: 0.48rem;
      color: var(--active);
      text-align: left; }
  .module-filterPicker .fp-main .fp-main-chooseBox {
    padding: 0 0.66667rem; }
    .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.26667rem;
      margin-top: 0.26667rem; }
      .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox .fp-main-chooseBox-titleBox-left .fp-main-chooseBox-titleBox-left-title {
        font-size: 0.37333rem;
        color: var(--pFont1);
        font-family: 'DIN,DIN-Medium';
        text-align: left; }
      .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox .fp-main-chooseBox-titleBox-left .fp-main-chooseBox-titleBox-left-desc {
        font-size: 0.26667rem;
        color: var(--pFont1);
        font-family: 'DIN,DIN-Medium'; }
      .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox .fp-main-chooseBox-titleBox-right {
        display: flex; }
        .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox .fp-main-chooseBox-titleBox-right .fp-main-chooseBox-titleBox-right-title {
          font-size: 0.37333rem;
          color: var(--active);
          font-family: 'DIN,DIN-Medium'; }
        .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox .fp-main-chooseBox-titleBox-right .fp-main-chooseBox-titleBox-right-image {
          fill: var(--active);
          width: 0.12rem;
          margin-left: 0.26667rem; }
          .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-titleBox .fp-main-chooseBox-titleBox-right .fp-main-chooseBox-titleBox-right-image svg {
            width: 100%; }
    .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList {
      text-align: left;
      display: flex;
      flex-wrap: wrap; }
      .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox {
        margin-right: 0.13333rem;
        vertical-align: middle;
        margin-bottom: 0.13333rem; }
        .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell {
          display: flex;
          align-items: center;
          border: 1px solid var(--pLine4);
          border-radius: 0.04rem;
          padding: 0.13333rem 0.2rem; }
          .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell .fp-main-chooseBox-tagList-cellBox-cell-image {
            width: 0.32rem;
            margin-right: 0.13333rem;
            display: flex; }
            .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell .fp-main-chooseBox-tagList-cellBox-cell-image.FIFA {
              width: 0.8rem; }
            .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell .fp-main-chooseBox-tagList-cellBox-cell-image img {
              width: 100%; }
          .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell .fp-main-chooseBox-tagList-cellBox-cell-text {
            font-size: 0.26667rem;
            color: var(--pFont1);
            font-family: 'DIN,DIN-Medium'; }
          .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell .fp-main-chooseBox-tagList-cellBox-cell-close {
            width: 0.32rem;
            height: 0.32rem;
            margin-left: 0.2rem;
            display: flex; }
            .module-filterPicker .fp-main .fp-main-chooseBox .fp-main-chooseBox-tagList .fp-main-chooseBox-tagList-cellBox .fp-main-chooseBox-tagList-cellBox-cell .fp-main-chooseBox-tagList-cellBox-cell-close img {
              width: 100%; }
  .module-filterPicker .fp-btnBox {
    padding: 0.4rem 0.66667rem 0;
    margin-top: 0.26667rem;
    margin-bottom: 0.26667rem;
    border-top: 1px solid var(--pLine2); }
    .module-filterPicker .fp-btnBox .fp-btnBox-submit {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      height: 0.90667rem;
      line-height: 0.90667rem;
      border-radius: 0.04rem;
      background: var(--active);
      color: var(--pFont6);
      text-align: center; }
    .module-filterPicker .fp-btnBox .fp-btnBox-reset {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      height: 0.90667rem;
      line-height: 0.90667rem;
      border-radius: 0.04rem;
      background: transparent;
      color: var(--active);
      text-align: center; }
