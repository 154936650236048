@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-predictGuide {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .stage-predictGuide .module-header .center {
    margin: 0 0.8rem; }
  .stage-predictGuide .pg-main {
    margin-top: 1.44rem;
    padding-bottom: 0.26667rem; }
    .stage-predictGuide .pg-main .pg-main-desc {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      color: var(--f5);
      margin: 0.53333rem 0.4rem 0; }
    .stage-predictGuide .pg-main .pg-main-image {
      display: inline-block;
      height: auto;
      max-width: 100%;
      margin: 0.53333rem 0.4rem 0; }
      .stage-predictGuide .pg-main .pg-main-image img {
        width: 100%; }
    .stage-predictGuide .pg-main .pg-main-list {
      margin: 0 0.4rem; }
      .stage-predictGuide .pg-main .pg-main-list .pg-main-list-cell {
        font-size: 0.37333rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--f5); }
        .stage-predictGuide .pg-main .pg-main-list .pg-main-list-cell.title {
          margin-top: 0.53333rem; }
