@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-contentBoard {
  background: var(--pbg3);
  flex: 1;
  border-top-left-radius: 0.53333rem;
  border-top-right-radius: 0.53333rem;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .module-contentBoard .cb-configBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.4rem;
    margin-top: 0.53333rem; }
    .module-contentBoard .cb-configBox .cb-configBox-filterBox {
      display: flex;
      align-items: center; }
      .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-wrapper {
        display: flex;
        align-items: center; }
      .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-image {
        width: 0.32rem; }
        .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-image img {
          width: 100%; }
      .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-text {
        color: var(--pFont1);
        font-family: 'DIN,DIN-Medium';
        font-size: 0.26667rem;
        margin-left: 0.13333rem;
        display: flex;
        align-items: center; }
      .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected {
        margin-left: 0.26667rem;
        height: 0.58667rem;
        display: flex;
        align-items: center;
        padding-left: 0.13333rem; }
        .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected.border {
          border: 1px solid var(--pLine4);
          border-radius: 0.04rem; }
        .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected .cb-configBox-filterBox-selected-cellBox {
          margin-right: 0.13333rem; }
          .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected .cb-configBox-filterBox-selected-cellBox .cb-configBox-filterBox-selected-cellBox-image {
            width: 0.32rem;
            display: flex; }
            .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected .cb-configBox-filterBox-selected-cellBox .cb-configBox-filterBox-selected-cellBox-image.FIFA {
              width: 0.8rem; }
            .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected .cb-configBox-filterBox-selected-cellBox .cb-configBox-filterBox-selected-cellBox-image img {
              width: 100%; }
          .module-contentBoard .cb-configBox .cb-configBox-filterBox .cb-configBox-filterBox-selected .cb-configBox-filterBox-selected-cellBox .cb-configBox-filterBox-selected-cellBox-text {
            font-size: 0.26667rem;
            font-family: 'DIN,DIN-Medium';
            color: var(--pFont1); }
    .module-contentBoard .cb-configBox .cb-configBox-sortBox {
      display: flex; }
      .module-contentBoard .cb-configBox .cb-configBox-sortBox .cb-configBox-sortBox-image {
        width: 0.32rem;
        margin-left: 0.13333rem; }
        .module-contentBoard .cb-configBox .cb-configBox-sortBox .cb-configBox-sortBox-image img {
          width: 100%; }
      .module-contentBoard .cb-configBox .cb-configBox-sortBox .cb-configBox-sortBox-text {
        color: var(--pFont1);
        font-family: 'DIN,DIN-Medium';
        font-size: 0.26667rem;
        display: flex;
        align-items: center; }
  .module-contentBoard .cb-listBox {
    margin-top: 0.4rem;
    overflow: hidden;
    display: flex; }
