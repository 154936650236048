@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.spinCon {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

@keyframes mySpin {
  to {
    transform: rotate(360deg); } }

.spin {
  display: inline-block;
  animation: mySpin 1s infinite linear;
  font-size: 0.53333rem; }
