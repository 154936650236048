@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-pHeader {
  height: 1.17333rem;
  background: var(--pbg2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .module-pHeader .ph-title {
    color: var(--pFont4);
    font-family: 'DIN,DIN-Medium';
    font-size: 0.42667rem;
    text-align: center; }
    .module-pHeader .ph-title .sub {
      font-size: 0.26667rem;
      color: var(--pFont1); }
  .module-pHeader .ph-left {
    position: absolute;
    left: 0.4rem; }
