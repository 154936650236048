@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-cHeader {
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: var(--bg1);
  z-index: 1; }
  .module-cHeader .h-image {
    width: 0.18667rem;
    height: 0.32rem;
    transform: rotate(180deg);
    position: absolute;
    left: 0.4rem; }
    .module-cHeader .h-image svg {
      width: 100%;
      height: 100%; }
  .module-cHeader .h-title {
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: 500;
    font-family: 'DIN,DIN-Medium';
    color: var(--mainFontActive); }
  .module-cHeader .h-right {
    position: absolute;
    right: 0.4rem;
    width: 0.48rem;
    height: 0.48rem; }
    .module-cHeader .h-right svg {
      width: 100%; }
