@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.c2cContainer {
  display: flex;
  margin-bottom: 2rem; }
  .c2cContainer .header {
    background-color: var(--bg3); }
    .c2cContainer .header .imageBox2 {
      width: 0.48rem;
      height: 0.48rem; }
  .c2cContainer .c2cInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 1.17333rem;
    padding: 0 0.4rem; }
    .c2cContainer .c2cInfo .c2cInfo3 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem; }
      .c2cContainer .c2cInfo .c2cInfo3 .imageBox2 {
        width: 2.66667rem;
        height: 2.66667rem; }
    .c2cContainer .c2cInfo .c2cInfo81 {
      font-family: var(--pRFont);
      font-size: 0.37333rem;
      font-weight: 400;
      color: #FFD140;
      margin-left: 0.4rem; }
    .c2cContainer .c2cInfo .c2cInfo4 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: center;
      margin-top: 0.5rem;
      font-family: 'DIN,DIN-Medium';
      font-size: 1.14667rem;
      font-weight: 400;
      color: var(--mainFont); }
      .c2cContainer .c2cInfo .c2cInfo4 .c2cInfo41 {
        font-family: var(--pRFont);
        font-size: 0.4rem;
        font-weight: 400;
        color: var(--mainFont); }
    .c2cContainer .c2cInfo .specialNotice {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: var(--bg2);
      padding: 0.29333rem 0.93333rem 0.29333rem;
      margin-top: 0.7rem;
      border-radius: 0.10667rem; }
      .c2cContainer .c2cInfo .specialNotice .specialNotice1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        font-family: var(--pRFont);
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--active); }
        .c2cContainer .c2cInfo .specialNotice .specialNotice1 img {
          margin-left: -0.53333rem;
          margin-right: 0.25333rem; }
      .c2cContainer .c2cInfo .specialNotice .specialNotice2 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
        font-family: var(--pRFont);
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--night-tipsDisplay, #777e90); }
    .c2cContainer .c2cInfo .c2cInfo7 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: center;
      margin-top: 0.53333rem;
      font-family: 'DIN,DIN-Medium';
      font-size: 1.14667rem;
      font-weight: 400;
      color: var(--mainFont); }
      .c2cContainer .c2cInfo .c2cInfo7:not(:first-child) {
        margin-top: 0.4rem; }
      .c2cContainer .c2cInfo .c2cInfo7 .c2cInfo71 {
        font-family: var(--pRFont);
        font-size: 0.4rem;
        font-weight: 400;
        color: var(--mainFont); }
    .c2cContainer .c2cInfo .inputCon {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      font-family: 'DIN,DIN-Regular';
      margin-top: 0.26667rem;
      align-items: center;
      width: 100%;
      border-radius: 0.10667rem; }
      .c2cContainer .c2cInfo .inputCon input {
        display: block;
        width: 100%;
        padding: 0.26667rem;
        border: none;
        outline: none;
        font-size: 0.37333rem;
        background: transparent;
        color: var(--mainFont);
        height: 1.01333rem;
        border-top-left-radius: 0.10667rem;
        border-bottom-left-radius: 0.10667rem;
        background-color: var(--night-font10, #eeeff2);
        color: var(--mainFont);
        font-size: 0.37333rem; }
        .c2cContainer .c2cInfo .inputCon input::placeholder {
          font-size: 0.29333rem;
          font-weight: 400;
          color: var(--night-placeHolder, var(--f9)); }
        .c2cContainer .c2cInfo .inputCon input::placeholder {
          color: var(--mainFont10);
          font-size: 0.37333rem;
          line-height: 0.37333rem;
          font-weight: 500; }
      .c2cContainer .c2cInfo .inputCon .currencyStyleV2 {
        background-color: var(--night-font10, #eeeff2);
        line-height: 0.98667rem;
        border-top-right-radius: 0.10667rem;
        border-bottom-right-radius: 0.10667rem;
        padding-right: 0.13333rem;
        padding-top: 0.02667rem;
        font-family: var(--pRFont);
        font-size: 0.37333rem;
        font-weight: 400;
        color: var(--active);
        width: 15%; }
    .c2cContainer .c2cInfo .inputCon2 {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      font-family: 'DIN,DIN-Regular';
      align-items: center;
      width: 100%;
      border-radius: 0.10667rem;
      margin-top: 0.26667rem; }
      .c2cContainer .c2cInfo .inputCon2 input {
        display: block;
        width: 100%;
        padding: 0.26667rem;
        border: none;
        outline: none;
        font-size: 0.37333rem;
        background: transparent;
        color: var(--mainFont);
        height: 1.01333rem;
        border-top-left-radius: 0.10667rem;
        border-bottom-left-radius: 0.10667rem;
        background-color: var(--night-font10, #eeeff2);
        color: var(--mainFont);
        font-size: 0.34667rem; }
        .c2cContainer .c2cInfo .inputCon2 input::placeholder {
          font-size: 0.29333rem;
          font-weight: 400;
          color: var(--night-placeHolder, var(--f9)); }
        .c2cContainer .c2cInfo .inputCon2 input::placeholder {
          color: var(--mainFont10);
          font-size: 0.37333rem;
          line-height: 0.37333rem;
          font-weight: 500; }
      .c2cContainer .c2cInfo .inputCon2 .currencyStyleV2 {
        background-color: var(--night-font10, #eeeff2);
        line-height: 0.98667rem;
        border-top-right-radius: 0.10667rem;
        border-bottom-right-radius: 0.10667rem;
        padding-right: 0.02667rem;
        padding-top: 0.02667rem;
        color: var(--night-font1, var(--f5));
        font-family: var(--pRFont);
        font-size: 0.37333rem;
        font-weight: 400;
        color: #ffd140;
        width: 10%; }
        .c2cContainer .c2cInfo .inputCon2 .currencyStyleV2 .imageBox2 {
          width: 0.4rem;
          height: 0.4rem; }
    .c2cContainer .c2cInfo .c2cInfo5, .c2cContainer .c2cInfo .c2cInfoImgUp {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background-color: var(--bg6);
      justify-content: center;
      width: 33%;
      padding: 0.8rem 0.05333rem 0.8rem;
      margin-top: 0.26667rem; }
      .c2cContainer .c2cInfo .c2cInfo5 .c2cInfo51, .c2cContainer .c2cInfo .c2cInfoImgUp .c2cInfo51 {
        display: flex; }
        .c2cContainer .c2cInfo .c2cInfo5 .c2cInfo51 .imageBox2, .c2cContainer .c2cInfo .c2cInfoImgUp .c2cInfo51 .imageBox2 {
          width: 0.66667rem;
          height: 0.66667rem; }
        .c2cContainer .c2cInfo .c2cInfo5 .c2cInfo51 .imageBox3, .c2cContainer .c2cInfo .c2cInfoImgUp .c2cInfo51 .imageBox3 {
          width: 2.13333rem;
          height: 3.33333rem; }
        .c2cContainer .c2cInfo .c2cInfo5 .c2cInfo51 .imageBox4, .c2cContainer .c2cInfo .c2cInfoImgUp .c2cInfo51 .imageBox4 {
          width: 0.37333rem;
          height: 0.37333rem; }
      .c2cContainer .c2cInfo .c2cInfo5 .c2cInfo52, .c2cContainer .c2cInfo .c2cInfoImgUp .c2cInfo52 {
        margin-top: 0.18667rem;
        font-family: var(--pRFont);
        font-size: 0.37333rem;
        font-weight: 400;
        color: var(--night-tipsDisplay, #777e90); }
    .c2cContainer .c2cInfo .c2cInfoImgUp {
      padding: 0;
      background-color: transparent; }
      .c2cContainer .c2cInfo .c2cInfoImgUp .imageBox3 {
        width: 2.13333rem;
        height: 3.33333rem; }
      .c2cContainer .c2cInfo .c2cInfoImgUp .imageBox4 {
        width: 0.37333rem;
        height: 0.37333rem;
        margin-left: 0.18667rem; }
      .c2cContainer .c2cInfo .c2cInfoImgUp .c2cInfo53 {
        visibility: hidden; }
    .c2cContainer .c2cInfo .submitBtnCon {
      position: static;
      width: 100%;
      bottom: 0;
      margin-top: 1.33333rem; }
      .c2cContainer .c2cInfo .submitBtnCon .submitBtnStyle1 {
        font-family: var(--pRFont);
        font-size: 0.32rem;
        font-weight: 400;
        color: var(--night-tipsDisplay, #777e90); }
    .c2cContainer .c2cInfo .hide {
      display: none;
      visibility: hidden; }
    .c2cContainer .c2cInfo .maskV2 {
      font-family: 'DIN,DIN-Medium';
      position: fixed;
      z-index: 99;
      background: rgba(0, 0, 0, 0.5);
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0; }
      .c2cContainer .c2cInfo .maskV2 .detail {
        width: 100%;
        background-color: var(--fff-bg);
        position: fixed;
        bottom: 0;
        left: 0;
        overflow: auto;
        border-top-left-radius: 0.72rem;
        border-top-right-radius: 0.72rem;
        animation: detailAni 200ms ease-in forwards;
        padding-top: 0.13333rem; }
        .c2cContainer .c2cInfo .maskV2 .detail .list {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          justify-content: center;
          border-bottom: 1px solid var(--bg6);
          margin-top: 0.13333rem; }
          .c2cContainer .c2cInfo .maskV2 .detail .list .line {
            width: 100%;
            height: 1px; }
        .c2cContainer .c2cInfo .maskV2 .detail .item, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem1, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem2 {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          justify-content: center;
          padding: 0 0.13333rem;
          color: var(--mainFont);
          align-items: center; }
          .c2cContainer .c2cInfo .maskV2 .detail .item .left, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem .left, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem1 .left, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem2 .left {
            display: flex;
            align-items: center;
            color: var(--mainFont);
            font-size: 0.37333rem;
            font-weight: 500; }
            .c2cContainer .c2cInfo .maskV2 .detail .item .left img, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem .left img, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem1 .left img, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem2 .left img {
              width: 0.8rem;
              margin-right: 0.4rem; }
          .c2cContainer .c2cInfo .maskV2 .detail .item .hide, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem .hide, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem1 .hide, .c2cContainer .c2cInfo .maskV2 .detail .cancelItem2 .hide {
            display: none; }
        .c2cContainer .c2cInfo .maskV2 .detail .line {
          width: 100%;
          height: 0.26667rem;
          background-color: #fff; }
        .c2cContainer .c2cInfo .maskV2 .detail .cancelItem {
          text-align: center;
          justify-content: center;
          font-size: 0.42667rem;
          font-weight: 400;
          height: 1.2rem;
          margin-top: 0.05333rem;
          color: var(--night-tipsDisplay, #777e90); }
        .c2cContainer .c2cInfo .maskV2 .detail .cancelItem1 {
          text-align: center;
          justify-content: center;
          font-size: 0.42667rem;
          font-weight: 400;
          height: 0.8rem;
          color: #FFD140; }
        .c2cContainer .c2cInfo .maskV2 .detail .cancelItem2 {
          text-align: center;
          justify-content: center;
          font-size: 0.32rem;
          font-weight: 400;
          height: 0.8rem;
          margin-top: 0.05333rem;
          margin-bottom: 0.26667rem;
          color: var(--night-tipsDisplay, #777e90); }

.imgCon {
  position: relative; }
  .imgCon .mainImg {
    width: 100%;
    height: 100%; }

.popContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  width: 85%;
  background: var(--bg2);
  border-radius: 0.26667rem;
  padding: 0.66667rem; }
  .popContent .title {
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: 25;
    font-family: var(--pMFont);
    color: var(--active);
    margin-bottom: 0.8rem; }
  .popContent .btnArea {
    display: flex;
    width: 100%;
    flex-direction: column; }
    .popContent .btnArea .btn {
      background-color: var(--active);
      color: var(--btn-active-color);
      border-radius: 0.08rem;
      padding: 0.26667rem;
      text-align: center;
      font-size: 0.42667rem;
      line-height: 0.42667rem;
      font-weight: 0;
      font-family: var(--pMFont);
      margin-bottom: 0.32rem; }
      .popContent .btnArea .btn:last-child {
        background-color: transparent;
        color: var(--active);
        margin-bottom: 0; }
