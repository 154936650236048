@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  font-family: PingFangSC, PingFangSC-Regular;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  color: #EEEEEE;
  background-color: black;
  height: 100%; }
  .page .golden {
    position: relative;
    min-height: 100vh; }
    .page .golden .bottom {
      position: absolute;
      bottom: 0; }
    .page .golden .banner {
      position: relative; }
      .page .golden .banner .topBanner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        padding: 0.26667rem 0.53333rem;
        width: 100%;
        position: relative; }
        .page .golden .banner .topBanner .right {
          position: absolute;
          right: 20px; }
          .page .golden .banner .topBanner .right img {
            margin-left: auto;
            max-width: 125px; }
        .page .golden .banner .topBanner .left {
          width: 65%; }
          .page .golden .banner .topBanner .left .logo {
            width: 0.8rem;
            margin-bottom: 8px; }
          .page .golden .banner .topBanner .left h1 {
            margin: 0;
            padding: 0;
            font-weight: normal;
            color: inherit;
            color: #FFAFB4;
            text-shadow: 1px 1px 1px #000000BF;
            font-size: 0.42667rem;
            margin-bottom: 8px;
            line-height: 20px;
            font-weight: bold; }
          .page .golden .banner .topBanner .left .subtitle {
            font-size: 0.32rem;
            color: #FFE8CA;
            margin-bottom: 8px;
            line-height: 16px; }
          .page .golden .banner .topBanner .left .date {
            color: #c1c1c1;
            font-size: 0.26667rem; }
      .page .golden .banner .egg {
        margin-top: -0.53333rem; }
      .page .golden .banner .preamble {
        margin: 0.53333rem;
        font-size: 0.29333rem;
        line-height: 0.48rem; }
      .page .golden .banner .block {
        position: relative;
        z-index: 2;
        text-align: center;
        color: #EEEEEE; }
        .page .golden .banner .block .red {
          font-size: 0.32rem; }
        .page .golden .banner .block .tickets {
          font-size: 0.37333rem; }
        .page .golden .banner .block .blue {
          color: #EEEEEE; }
        .page .golden .banner .block .buttonCon {
          width: 100%;
          position: relative;
          margin-bottom: 0.53333rem; }
          .page .golden .banner .block .buttonCon .button {
            width: 3.2rem;
            margin: 0.16rem auto;
            padding: 0.10667rem;
            background: #ffc647;
            border-radius: 2rem;
            color: black;
            z-index: 1; }
          .page .golden .banner .block .buttonCon .helps {
            position: absolute;
            top: 0;
            left: 5vw;
            width: 90vw;
            z-index: 0; }
      .page .golden .banner .wheelBlock {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .page .golden .banner .wheelBlock img {
          z-index: 1; }
        .page .golden .banner .wheelBlock .prizeTable {
          margin: 0.4rem; }
        .page .golden .banner .wheelBlock .wheelText {
          z-index: 2;
          white-space: nowrap;
          text-align: center; }
          .page .golden .banner .wheelBlock .wheelText .wheelTitle {
            font-size: 0.32rem; }
        .page .golden .banner .wheelBlock .wheel2Text {
          z-index: 2;
          white-space: nowrap;
          text-align: center; }
          .page .golden .banner .wheelBlock .wheel2Text .wheelTitle {
            font-size: 0.32rem; }
        .page .golden .banner .wheelBlock .wheelO {
          position: relative; }
        .page .golden .banner .wheelBlock .wheelI, .page .golden .banner .wheelBlock .wheel2I {
          z-index: 3;
          width: 100%;
          margin: 0.4rem 0; }
          .page .golden .banner .wheelBlock .wheelI img, .page .golden .banner .wheelBlock .wheel2I img {
            width: 6.4rem; }
        .page .golden .banner .wheelBlock .wheelBtn, .page .golden .banner .wheelBlock .wheel2Btn {
          position: absolute;
          z-index: 4;
          width: 2.13333rem;
          top: 49%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .page .golden .banner .wheelBlock .wheel2O {
          position: relative; }
        .page .golden .banner .wheelBlock .spin {
          animation: spinning 1s linear infinite; }
    .page .golden .activityTables {
      position: relative;
      z-index: 4;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.4rem; }
      .page .golden .activityTables .taskText {
        width: 100%;
        margin-bottom: 0.26667rem;
        text-align: left; }
      .page .golden .activityTables img {
        margin-bottom: 0.48rem; }
      .page .golden .activityTables table {
        margin: 0.53333rem auto;
        width: 90%;
        border-collapse: collapse;
        font-size: 0.26667rem; }
      .page .golden .activityTables table, .page .golden .activityTables th, .page .golden .activityTables td {
        border: 1px solid rgba(255, 180, 67, 0.5); }
      .page .golden .activityTables th, .page .golden .activityTables td {
        padding: 0.21333rem; }
      .page .golden .activityTables th {
        font-size: 0.32rem;
        text-align: center; }
      .page .golden .activityTables td:first-child {
        width: 60%; }
      .page .golden .activityTables td:nth-child(2) {
        text-align: right; }
    .page .golden .ruleCon {
      padding-bottom: 5vh;
      margin: auto;
      width: 90%; }
      .page .golden .ruleCon .ruleHeader {
        color: #FFB443;
        margin-bottom: 0.26667rem; }
      .page .golden .ruleCon .rule {
        font-size: 0.32rem; }
      .page .golden .ruleCon table td {
        font-size: 0.29333rem;
        line-height: 0.48rem;
        vertical-align: top;
        padding-bottom: 0.26667rem; }
    .page .golden .ex {
      position: relative;
      margin-top: -30vh;
      z-index: -1; }
    .page .golden .eggContainer {
      margin-top: -0.13333rem;
      position: relative; }
      .page .golden .eggContainer .activity {
        position: relative;
        background: black;
        width: 100%;
        min-height: 100vh;
        padding: 0.34667rem 0 0.21333rem 0;
        color: #FFB443; }
        .page .golden .eggContainer .activity .prizeTitle {
          margin: 0.8rem 0 0.4rem 0;
          width: 90%;
          margin-left: 5%; }
      .page .golden .eggContainer > div {
        padding: 0.13333rem 0; }
      .page .golden .eggContainer .tables {
        max-height: 100vh;
        overflow-y: scroll;
        text-align: left;
        font-size: 0.32rem; }
        .page .golden .eggContainer .tables table {
          width: 90%;
          margin: 0 auto 0.8rem auto;
          max-height: 8rem;
          white-space: nowrap; }
          .page .golden .eggContainer .tables table th {
            border: 1px solid #FFB443;
            margin: auto;
            font-size: 0.37333rem;
            color: #EEEEEE;
            padding: 0.26667rem 0.26667rem;
            width: 50%; }
          .page .golden .eggContainer .tables table td {
            width: 50%;
            border: 1px solid #FFB443;
            padding: 0.13333rem 0.26667rem;
            color: #EEEEEE; }
  .page .back {
    position: absolute;
    top: 0.26667rem;
    left: 0.26667rem;
    color: #EEEEEE;
    z-index: 2; }
  .page .mt {
    margin-top: 0.66667rem; }
  .page .mt:last-of-type {
    margin-bottom: 1.33333rem; }
  .page .mb {
    margin-bottom: 0.4rem; }
  .page .header {
    background-color: var(--fff-bg); }
  .page .wrapper {
    padding-top: 1.17333rem; }
    .page .wrapper img {
      width: 100%;
      display: block;
      object-fit: fill;
      position: relative;
      z-index: 1; }
  .page .footer {
    width: 100%;
    height: 1.6rem;
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0; }
    .page .footer .submitBtn {
      width: 100%;
      height: 1.2rem;
      line-height: 1.2rem;
      border-radius: 1.33333rem;
      color: var(--fff);
      text-align: center; }

.prizePopup {
  height: 100%;
  width: 100%;
  text-align: center;
  color: #EEEEEE;
  font-size: 0.42667rem; }
  .prizePopup .prizeText {
    display: flex;
    justify-content: center;
    align-items: center; }
    .prizePopup .prizeText span:last-child {
      color: #DB150B;
      font-size: 0.48rem; }
  .prizePopup img {
    width: 85%;
    margin-top: 20%;
    object-fit: fill; }
  .prizePopup .cross {
    margin: 0;
    width: 0.53333rem; }

@keyframes spinning {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
