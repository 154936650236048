@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.trade-header {
  height: 1.17333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.4rem;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--bg12); }
  .trade-header .center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 1.17333rem; }
    .trade-header .center .button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.17333rem;
      background: var(--fff-bg);
      font-weight: 500;
      color: var(--f6);
      font-size: 0.42667rem;
      font-family: var(--pMFont);
      margin-right: 0.48rem; }
      .trade-header .center .button.active {
        color: var(--mainFont); }
        .trade-header .center .button.active:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          width: 0.4rem;
          height: 0.08rem;
          margin: auto;
          background: var(--active); }
    .trade-header .center .bsk-tabs {
      padding: 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 0.98667rem;
      border-radius: 0.10667rem;
      border: none; }
      .trade-header .center .bsk-tabs span {
        display: flex;
        justify-content: center;
        align-items: center; }
      .trade-header .center .bsk-tabs .bsk-tabs-item {
        font-size: 0.34667rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        color: var(--t10);
        width: 25%;
        height: 100%; }
      .trade-header .center .bsk-tabs .bsk-tabs-item-active {
        width: 25%;
        height: 0.82667rem;
        font-size: 0.34667rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        color: var(--mainFont4);
        background: var(--night-bg2, var(--bg9));
        border-radius: 0.10667rem; }
        .trade-header .center .bsk-tabs .bsk-tabs-item-active:after {
          display: none; }
