@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.changeArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.4rem;
  font-size: 0.37333rem;
  line-height: 0.37333rem;
  font-weight: 500; }
  .changeArea p {
    margin: 0;
    color: var(--mainFont); }
    .changeArea p:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: var(--active);
      font-size: 0.32rem;
      line-height: 0.32rem;
      width: 3.2rem;
      font-size: 0.32rem;
      line-height: 0.32rem;
      font-weight: 400; }
      .changeArea p:last-child img {
        margin-right: 0.13333rem; }
  .changeArea .ChangeOptionStyles {
    color: var(--night-changeOptions, #1E2129);
    margin-right: 0.13333rem; }

.verifyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 0.10667rem;
  border: 0.02667rem solid var(--night-inputBg, var(--bg6));
  background-color: var(--night-inputBg, #EEEFF2);
  height: 1.01333rem;
  font-size: 0.37333rem;
  line-height: 0.37333rem;
  font-weight: 500; }
  .verifyContainer input {
    border: none;
    height: 1.01333rem;
    border-radius: 0.10667rem;
    flex: 1;
    color: var(--mainFont);
    font-weight: bold;
    background-color: var(--night-inputBg, #EEEFF2);
    white-space: nowrap;
    /*不允许换行*/
    overflow: hidden;
    /*超出隐藏*/
    text-overflow: ellipsis;
    /*文本超出三点代替*/
    text-indent: 0.26667rem; }
    .verifyContainer input::placeholder {
      color: var(--night-placeHolder, var(--f16));
      font-size: 0.29333rem;
      line-height: 0.29333rem;
      font-weight: 500; }
  .verifyContainer .verifyCode {
    margin-left: 0.13333rem;
    color: var(--active);
    position: relative;
    width: 2.4rem;
    text-align: center;
    font-weight: bolder; }
    .verifyContainer .verifyCode::after {
      position: absolute;
      top: 10%;
      left: -0.13333rem;
      height: 80%;
      width: 0.02667rem;
      background-color: #EBECF0; }

.inputContainer {
  display: flex;
  width: 100%;
  height: 1.01333rem;
  align-items: center;
  position: relative;
  background-color: var(--fff-bg);
  border-radius: 0.10667rem;
  padding: 0 0.26667rem; }
  .inputContainer input {
    border: none;
    width: 100%;
    text-align: left;
    background: transparent;
    font-size: 0.4rem;
    line-height: 0.4rem;
    font-weight: 700;
    font-family: 'DIN,DIN-Medium';
    color: var(--mainFontActive); }
    .inputContainer input::placeholder {
      color: var(--mainFont10);
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500; }
  .inputContainer .verifyCode {
    margin-left: 0.13333rem;
    color: var(--active);
    position: relative;
    width: 2.4rem;
    text-align: center;
    font-weight: bolder;
    display: flex;
    justify-content: center; }
    .inputContainer .verifyCode .disabled {
      pointer-events: none;
      touch-action: none; }
    .inputContainer .verifyCode::after {
      position: absolute;
      top: 10%;
      left: -0.13333rem;
      height: 80%;
      width: 0.02667rem;
      background-color: #EBECF0; }
