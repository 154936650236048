@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-topics {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .stage-topics .t-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; }
    .stage-topics .t-main .t-main-searchBox {
      background: var(--pbg2);
      display: flex;
      padding-top: 0.26667rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem; }
      .stage-topics .t-main .t-main-searchBox .t-main-searchBox-inputBox {
        display: flex;
        align-items: center;
        background: var(--pbg3);
        width: 100%;
        border-radius: 0.4rem; }
        .stage-topics .t-main .t-main-searchBox .t-main-searchBox-inputBox .t-main-searchBox-inputBox-image {
          width: 0.32rem;
          margin-left: 0.4rem; }
          .stage-topics .t-main .t-main-searchBox .t-main-searchBox-inputBox .t-main-searchBox-inputBox-image img {
            width: 100%; }
        .stage-topics .t-main .t-main-searchBox .t-main-searchBox-inputBox input {
          font-family: 'DIN,DIN-Medium';
          height: 0.8rem;
          font-size: 0.37333rem;
          background: transparent;
          box-shadow: none;
          border: 0;
          margin-left: 0.26667rem;
          flex: 1; }
          .stage-topics .t-main .t-main-searchBox .t-main-searchBox-inputBox input::placeholder {
            color: var(--pFont3); }
      .stage-topics .t-main .t-main-searchBox .t-main-searchBox-create {
        font-family: 'DIN,DIN-Medium';
        font-size: 0.37333rem;
        color: var(--pFont2);
        background: var(--active);
        width: 2.50667rem;
        text-align: center;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-left: 0.26667rem;
        border-radius: 0.04rem; }
        .stage-topics .t-main .t-main-searchBox .t-main-searchBox-create.disable {
          pointer-events: none;
          background: #c3c3c3;
          color: #FFFFFF; }
        .stage-topics .t-main .t-main-searchBox .t-main-searchBox-create.guide {
          background: var(--pbg7);
          color: var(--pFont5); }
