@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.my-drawer {
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.my-drawer .am-drawer-sidebar {
  background-color: var(--bg2);
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.my-drawer .am-drawer-sidebar .am-list {
  width: 70%;
  padding: 0; }

.mask {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 300;
  display: flex;
  flex-direction: column;
  color: var(--f5); }
  .mask.come {
    left: 0; }
  .mask .list2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 8.53333rem;
    height: 100%;
    background-color: var(--bg2); }
    .mask .list2 .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: center;
      height: 1.81333rem;
      font-size: 0.37333rem;
      padding: 0 0.53333rem;
      position: relative;
      border: none; }
      .mask .list2 .title:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid var(--bg1);
        transform: scaleY(0.5);
        transform-origin: 0 0;
        -webkit-transform: scaleY(0.5);
        -webkit-transform-origin: 0 0; }
      .mask .list2 .title .searchBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        width: 7.2rem;
        height: 0.85333rem;
        line-height: 0.85333rem;
        border-radius: 0.42667rem;
        padding-left: 0.4rem;
        background-color: var(--night-inputBg, var(--t8)); }
        .mask .list2 .title .searchBox .searchInput {
          border: none; }
          .mask .list2 .title .searchBox .searchInput label {
            color: var(--mainFont10); }
    .mask .list2 .tabStyle {
      display: flex;
      align-items: center;
      height: 1.17333rem;
      width: 3.2rem;
      padding-left: 0.4rem;
      align-self: flex-start;
      font-size: 0.42667rem;
      font-weight: 500;
      color: var(--f6); }
    .mask .list2 .tab {
      width: 2.4rem;
      background: var(--bg1);
      position: relative; }
    .mask .list2 .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      height: 100%; }
      .mask .list2 .group .groupTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 0 0.4rem;
        height: 0.90667rem; }
        .mask .list2 .group .groupTitle p {
          font-size: 0.32rem;
          line-height: 0.32rem;
          font-weight: 400;
          color: var(--t7); }
      .mask .list2 .group .groupCon {
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding-bottom: 1.8rem; }
        .mask .list2 .group .groupCon .groupCell {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          font-size: 0.4rem;
          font-weight: bold;
          padding: 0 0.4rem;
          height: 1.54667rem;
          color: var(--mainFont);
          position: relative;
          border: none; }
          .mask .list2 .group .groupCon .groupCell:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-bottom: 1px solid var(--f8);
            transform: scaleY(0.5);
            transform-origin: 0 0;
            -webkit-transform: scaleY(0.5);
            -webkit-transform-origin: 0 0; }
          .mask .list2 .group .groupCon .groupCell .imageBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            width: 3.33333rem; }
            .mask .list2 .group .groupCon .groupCell .imageBox .name {
              display: flex;
              align-items: center;
              font-size: 0.37333rem;
              line-height: 0.37333rem;
              font-weight: bold;
              padding-bottom: 0.13333rem;
              color: var(--mainFontActive); }
              .mask .list2 .group .groupCon .groupCell .imageBox .name .nameStyle {
                font-size: 0.42667rem;
                line-height: 0.42667rem;
                font-weight: 500;
                font-family: 'DIN,DIN-Medium';
                color: var(--mainFontActive); }
                .mask .list2 .group .groupCon .groupCell .imageBox .name .nameStyle .nameDes {
                  font-size: 0.32rem;
                  line-height: 0.32rem;
                  font-weight: 500;
                  color: var(--f6); }
                .mask .list2 .group .groupCon .groupCell .imageBox .name .nameStyle .dateStyle {
                  font-size: 0.32rem;
                  line-height: 0.32rem;
                  font-weight: 400;
                  width: 0.85333rem;
                  height: 0.42667rem;
                  line-height: 0.42667rem;
                  text-align: center;
                  border-radius: 0.05333rem;
                  border: 0.02667rem solid var(--active);
                  color: var(--active);
                  margin-left: 0.13333rem; }
            .mask .list2 .group .groupCon .groupCell .imageBox .volume {
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              justify-content: flex-start;
              font-size: 0.32rem;
              line-height: 0.32rem;
              font-weight: 500;
              font-family: 'DIN,DIN-Medium';
              color: var(--f6); }
              .mask .list2 .group .groupCon .groupCell .imageBox .volume img {
                margin-right: 0.08rem;
                padding-bottom: 0.05333rem; }
          .mask .list2 .group .groupCon .groupCell .itemPriceBox {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            width: 4rem;
            font-family: 'DIN,DIN-Medium'; }
            .mask .list2 .group .groupCon .groupCell .itemPriceBox .currencyPrice {
              font-size: 0.42667rem;
              line-height: 0.42667rem;
              font-weight: 500;
              padding-bottom: 0.13333rem;
              color: var(--mainFontActive); }
            .mask .list2 .group .groupCon .groupCell .itemPriceBox .desCurrencyPrice {
              font-size: 0.32rem;
              line-height: 0.32rem;
              font-weight: 500;
              color: var(--f6); }
          .mask .list2 .group .groupCon .groupCell .itemRateBox {
            width: 2.13333rem;
            height: 0.82667rem;
            color: var(--fff);
            font-family: 'DIN,DIN-Medium'; }
            .mask .list2 .group .groupCon .groupCell .itemRateBox p {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border-radius: 0.05333rem;
              font-size: 0.4rem;
              line-height: 0.4rem;
              font-weight: 500; }
            .mask .list2 .group .groupCon .groupCell .itemRateBox .bgGreen {
              background: var(--f1); }
            .mask .list2 .group .groupCon .groupCell .itemRateBox .bgRed {
              background: var(--r1); }
            .mask .list2 .group .groupCon .groupCell .itemRateBox .close {
              background: var(--fc5); }
