@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-assetTransfer {
  display: flex;
  flex-direction: column; }
  .stage-assetTransfer .at-title {
    display: flex;
    align-items: center; }
    .stage-assetTransfer .at-title .at-title-cat {
      color: var(--mainFont10);
      background: var(--bg1);
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      line-height: 0.66667rem;
      padding: 0 0.26667rem; }
    .stage-assetTransfer .at-title .at-title-cat.active {
      color: #141416;
      background: var(--active); }
  .stage-assetTransfer .at-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 0.4rem;
    margin-top: 30px; }
    .stage-assetTransfer .at-main .at-main-wrapper {
      flex: 1; }
      .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox {
        background: var(--bg2);
        display: flex;
        border-radius: 0.10667rem; }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-account {
          flex: 1; }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-account .at-main-wrapper-switchBox-account-row {
            display: flex;
            align-items: center;
            height: 1.44rem; }
            .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-account .at-main-wrapper-switchBox-account-row .at-main-wrapper-switchBox-account-row-source {
              font-size: 0.4rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              color: var(--mainFont5);
              margin-left: 1.06667rem; }
            .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-account .at-main-wrapper-switchBox-account-row .at-main-wrapper-switchBox-account-row-title {
              font-size: 0.37333rem;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 500;
              color: var(--mainFont);
              margin-left: 1.2rem;
              flex: 1; }
            .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-account .at-main-wrapper-switchBox-account-row .at-main-wrapper-switchBox-account-row-image {
              width: 0.13333rem;
              height: 0.21333rem;
              margin-right: 1.2rem; }
              .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-account .at-main-wrapper-switchBox-account-row .at-main-wrapper-switchBox-account-row-image svg {
                width: 100%; }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-exchange {
          width: 1.6rem;
          fill: var(--active);
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotate(-90deg); }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-switchBox .at-main-wrapper-switchBox-exchange svg {
            width: 0.42667rem;
            height: 0.53333rem; }
      .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-ddvBox {
        margin-top: 0.8rem; }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-ddvBox .module-dropdownView .dv-option {
          justify-content: flex-start; }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-ddvBox .module-dropdownView .dv-option .dv-option-selected {
            font-family: 'DIN,DIN-Medium'; }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-ddvBox .module-dropdownView .dv-list .dv-list-cell {
          justify-content: flex-start; }
      .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox {
        border-radius: 0.10667rem;
        margin-top: 0.8rem; }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-title {
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          line-height: 1;
          color: var(--mainFont5); }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-inputBox {
          background: var(--bg2);
          height: 1.01333rem;
          padding: 0 0.4rem;
          display: flex;
          align-items: center;
          margin-top: 0.26667rem;
          border-radius: 0.10667rem; }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-inputBox input {
            flex: 1;
            font-size: 0.4rem;
            background: transparent;
            box-shadow: none;
            border: 0;
            font-family: 'DIN,DIN-Medium';
            color: var(--mainFont); }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-inputBox .at-main-wrapper-amountBox-inputBox-currency {
            font-size: 0.4rem;
            font-family: 'DIN,DIN-Medium';
            font-weight: 500;
            color: var(--mainFont);
            margin-left: 0.4rem; }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-inputBox .at-main-wrapper-amountBox-inputBox-max {
            font-size: 0.4rem;
            font-family: 'DIN,DIN-Medium';
            font-weight: 500;
            color: var(--active);
            margin-left: 0.4rem; }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-inputBox .module-orderInput {
            background: var(--bg2); }
        .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-available {
          font-size: 0.37333rem;
          font-weight: 400;
          font-family: PingFangSC, PingFangSC-Regular;
          color: var(--mainFont5);
          margin-top: 0.26667rem; }
          .stage-assetTransfer .at-main .at-main-wrapper .at-main-wrapper-amountBox .at-main-wrapper-amountBox-available span {
            font-family: 'DIN,DIN-Medium';
            color: var(--mainFont);
            font-weight: 500;
            margin-left: 0.26667rem;
            font-size: 0.34667rem; }
    .stage-assetTransfer .at-main .at-main-submit {
      margin: 1.06667rem 0.4rem 0;
      background: var(--active);
      height: 1.06667rem;
      line-height: 1.06667rem;
      text-align: center;
      color: #141416;
      font-size: 0.42667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      border-radius: 0.08rem; }
