@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-orderInput {
  display: flex;
  align-items: center;
  height: 1.01333rem;
  background: var(--bg18);
  border-radius: 0.10667rem;
  padding: 0 0.18667rem;
  flex: 1; }
  .module-orderInput .oi-image {
    width: 0.64rem;
    height: 0.64rem; }
    .module-orderInput .oi-image svg {
      width: 100%;
      height: 16px; }
  .module-orderInput .oi-inputBox {
    flex: 1;
    height: 100%; }
    .module-orderInput .oi-inputBox input {
      background: transparent;
      box-shadow: none;
      border: 0;
      font-size: 0.37333rem;
      font-family: DIN,DIN-Medium;
      font-weight: 500;
      color: var(--mainFont);
      width: 100%;
      height: 100%;
      font-family: 'DIN,DIN-Medium'; }
      .module-orderInput .oi-inputBox input::placeholder {
        font-family: 'DIN,DIN-Medium';
        color: var(--mainFont10); }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }
