@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-systemTagPicker {
  background: var(--pbg3);
  margin: auto;
  width: 90%;
  min-height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.26667rem; }
  .module-systemTagPicker .stp-title {
    height: 1.49333rem;
    line-height: 1.49333rem;
    font-family: 'DIN,DIN-Medium';
    font-size: 0.48rem;
    color: var(--active);
    text-align: left;
    padding-left: 0.66667rem;
    border-bottom: 1px solid var(--pLine2); }
  .module-systemTagPicker .stp-btnBox {
    border-top: 1px solid var(--pLine2);
    height: 1.86667rem;
    display: flex;
    align-items: center; }
    .module-systemTagPicker .stp-btnBox .stp-btnBox-btn {
      height: 0.90667rem;
      border-radius: 0.04rem;
      background: var(--active);
      margin: 0 0.66667rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'DIN,DIN-Medium';
      font-size: 0.42667rem;
      color: var(--pFont6);
      width: 100%; }

.puzzle-tagList {
  margin-top: 0.53333rem; }
  .puzzle-tagList .tl-cellBox {
    margin-bottom: 0.53333rem;
    padding: 0 0.66667rem; }
    .puzzle-tagList .tl-cellBox .tl-cellBox-cell {
      display: flex;
      align-items: center; }
      .puzzle-tagList .tl-cellBox .tl-cellBox-cell .tl-cellBox-cell-tick {
        width: 0.32rem;
        height: 0.32rem;
        background: var(--pbg1);
        display: flex; }
        .puzzle-tagList .tl-cellBox .tl-cellBox-cell .tl-cellBox-cell-tick img {
          width: 0.24rem; }
      .puzzle-tagList .tl-cellBox .tl-cellBox-cell .tl-cellBox-cell-icon {
        margin-left: 0.2rem;
        margin-right: 0.13333rem;
        width: 0.32rem;
        display: flex; }
        .puzzle-tagList .tl-cellBox .tl-cellBox-cell .tl-cellBox-cell-icon.FIFA {
          width: 0.8rem; }
        .puzzle-tagList .tl-cellBox .tl-cellBox-cell .tl-cellBox-cell-icon img {
          width: 100%; }
      .puzzle-tagList .tl-cellBox .tl-cellBox-cell .tl-cellBox-cell-text {
        font-family: 'DIN,DIN-Medium';
        font-size: 0.32rem;
        color: var(--pFont1); }
