@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.emulate-input {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid var(--lc1);
  border-radius: 2px;
  height: 30px;
  margin: 10px 0; }
  .emulate-input.float {
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 0; }
    .emulate-input.float.error {
      box-shadow: none; }
    .emulate-input.float > label {
      color: var(--mainFont10);
      font-size: 0.42667rem;
      font-weight: 500;
      transition: all 0.5s;
      font-family: 'DIN,DIN-Medium'; }
      .emulate-input.float > label.fixed {
        font-size: 16.5px;
        transform: translate(-5px, -30px); }
    .emulate-input.float > input {
      font-size: 16px; }
    .emulate-input.float > .verify-btn {
      height: 36px;
      top: auto;
      right: 10px; }
  .emulate-input.error {
    border-color: #ff6960 !important;
    box-shadow: 0 0 5px #ff6960; }
  .emulate-input > label {
    position: absolute !important;
    left: 6px;
    align-self: center;
    font-size: 0.34667rem;
    font-weight: 500;
    color: var(--mainFont10);
    pointer-events: none;
    font-family: 'DIN,DIN-Medium'; }
    .emulate-input > label.pos {
      display: flex;
      justify-content: center;
      width: 100%;
      left: 0; }
  .emulate-input > input {
    flex: 1;
    text-indent: 6px;
    font-size: 13px;
    color: var(--mainFont);
    background: transparent;
    outline: none;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .emulate-input > .smart-ass {
    position: absolute;
    width: calc(100% + 2px);
    background-color: var(--fff-bg);
    top: calc(100% - 2px);
    z-index: 1;
    border: 1px solid;
    border-top-width: 0;
    left: -1px; }
    .emulate-input > .smart-ass li {
      height: 34px;
      line-height: 34px;
      font-size: 12px;
      text-indent: 6px;
      cursor: pointer;
      letter-spacing: 1px; }
      .emulate-input > .smart-ass li:hover {
        background-color: #fafafa; }
  .emulate-input > .verify-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: var(--active);
    width: 100px;
    height: 100%;
    cursor: pointer;
    font-size: 13px;
    color: #000; }
    .emulate-input > .verify-btn.disabled {
      background: #b9b9b9;
      color: var(--fff);
      cursor: default; }
  .emulate-input > .eye {
    display: flex;
    margin-right: 10px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    cursor: default; }
  .emulate-input > .controller {
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-self: center;
    align-items: center;
    height: 100%;
    width: 100%;
    left: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: 1; }
    .emulate-input > .controller .add {
      pointer-events: auto;
      width: 23px;
      height: 23px; }
      .emulate-input > .controller .add svg #Fill-1 {
        fill: var(--fc2); }
      .emulate-input > .controller .add .btn {
        width: 23px;
        height: 23px; }
    .emulate-input > .controller .minus {
      pointer-events: auto;
      width: 23px;
      height: 23px; }
      .emulate-input > .controller .minus svg #Fill-1 {
        fill: var(--fc2); }
      .emulate-input > .controller .minus .btn {
        width: 23px;
        height: 23px; }
    .emulate-input > .controller .btn {
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      cursor: default;
      fill: var(--active); }
  .emulate-input > .emulate-input-notice {
    position: absolute;
    bottom: -20px;
    font-size: 10px;
    color: #ff6960; }
