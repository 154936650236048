@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-dialog {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: table; }
  .components-dialog .container {
    display: table-cell;
    vertical-align: middle; }
  .components-dialog .main {
    width: 8.66667rem;
    min-height: 13.97333rem;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--bg16);
    overflow: hidden;
    border-radius: 0.21333rem;
    padding-bottom: 0.26667rem;
    animation: scale 0.2s; }
  .components-dialog .scale-out {
    animation: scaleout 0.2s; }
  .components-dialog .title {
    padding: 0.66667rem 0.66667rem 0.53333rem !important;
    font-size: 0.48rem !important;
    font-weight: 500;
    background-color: var(--bg16);
    color: var(--active) !important;
    font-family: var(--pMFont);
    text-align: left; }
  .components-dialog .content {
    text-align: center; }
  .components-dialog .action .right {
    width: 7.33333rem;
    height: 1.06667rem;
    margin: 0 auto;
    background: #ffd140;
    border-radius: 0.08rem;
    font-size: 0.42667rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #141416;
    line-height: 1.06667rem; }
    .components-dialog .action .right.error {
      opacity: 0.5;
      touch-action: none;
      pointer-events: none; }
  .components-dialog .action .left {
    text-align: center;
    font-size: 0.42667rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffd140;
    margin-top: 0.56rem; }
  .components-dialog .dialogFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0.4rem; }

@keyframes scale {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes scaleout {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }
