@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.pDetail {
  font-family: 'DIN,DIN-Regular';
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--pbg2); }
  .pDetail .pDetail-pull-body {
    overflow-y: auto;
    background: var(--pbg2);
    flex: 1; }
  .pDetail .creator {
    display: flex;
    margin-top: 0.64rem;
    padding: 0 0.4rem; }
    .pDetail .creator .img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 0.26667rem; }
      .pDetail .creator .img img {
        width: 0.58667rem;
        height: 0.58667rem; }
    .pDetail .creator .desc .name {
      font-size: 0.37333rem;
      color: var(--pFont4); }
    .pDetail .creator .desc .id {
      font-size: 0.26667rem;
      color: var(--pFont1); }
  .pDetail .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--pbg3);
    padding: 0.53333rem 0.4rem;
    border-top-right-radius: 48px;
    border-top-left-radius: 48px; }
    .pDetail .content .sysBar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.32rem; }
      .pDetail .content .sysBar > ul {
        display: flex; }
        .pDetail .content .sysBar > ul li {
          margin-right: 0.13333rem; }
          .pDetail .content .sysBar > ul li img.fifa {
            width: 0.8rem; }
          .pDetail .content .sysBar > ul li img.football {
            width: 0.32rem; }
          .pDetail .content .sysBar > ul li img.tournament {
            width: 0.29333rem; }
          .pDetail .content .sysBar > ul li img.bitcoin {
            width: 0.32rem; }
          .pDetail .content .sysBar > ul li img.tennis {
            width: 0.32rem; }
          .pDetail .content .sysBar > ul li img.basketball {
            width: 0.32rem; }
      .pDetail .content .sysBar .spec {
        display: flex;
        align-items: center; }
        .pDetail .content .sysBar .spec img.favor {
          width: 0.32rem;
          margin-right: 0.26667rem; }
        .pDetail .content .sysBar .spec img.share {
          width: 0.32rem; }
        .pDetail .content .sysBar .spec > div {
          display: flex;
          align-items: center; }
    .pDetail .content .tagBar {
      display: flex;
      margin-bottom: 0.29333rem; }
      .pDetail .content .tagBar li {
        margin-right: 0.13333rem;
        padding: 0 0.2rem;
        font-size: 0.29333rem;
        color: var(--pFont1);
        background: var(--pbg1);
        border-radius: 3px; }
    .pDetail .content .title {
      font-size: 0.37333rem;
      color: var(--pFont4);
      margin-bottom: 0.24rem; }
    .pDetail .content .source {
      font-size: 0.26667rem;
      color: var(--pFont1);
      margin-bottom: 0.4rem;
      display: flex; }
      .pDetail .content .source a {
        color: #FFD040;
        margin-left: 0.13333rem;
        text-decoration: underline;
        letter-spacing: .5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100px;
        display: inline-block;
        vertical-align: middle;
        flex: 1; }
    .pDetail .content .space-between {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 0.4rem; }
      .pDetail .content .space-between .subtitle {
        font-size: 0.26667rem;
        color: var(--pFont1);
        margin-bottom: 0.06667rem; }
      .pDetail .content .space-between .mainContent {
        display: flex;
        align-items: center;
        font-size: 0.37333rem;
        color: var(--pFont5); }
        .pDetail .content .space-between .mainContent .upper {
          font-size: 0.42667rem; }
        .pDetail .content .space-between .mainContent img {
          margin-right: 0.13333rem;
          width: 0.32rem; }
        .pDetail .content .space-between .mainContent span {
          font-size: 0.26667rem;
          color: var(--pFont1); }
      .pDetail .content .space-between.percentBox {
        width: 4.93333rem; }
        .pDetail .content .space-between.percentBox .percent {
          color: var(--active); }
      .pDetail .content .space-between .judge-report {
        display: flex;
        align-items: center; }
        .pDetail .content .space-between .judge-report img {
          width: 0.32rem;
          margin-right: 0.13333rem; }
        .pDetail .content .space-between .judge-report span {
          font-size: 0.37333rem; }
          .pDetail .content .space-between .judge-report span.red {
            color: #F74860; }
      .pDetail .content .space-between .range {
        height: 0.10667rem;
        width: 4.93333rem;
        background: var(--pbg6);
        border-radius: 3px; }
        .pDetail .content .space-between .range .process {
          width: 100%;
          height: 0.10667rem;
          background: #fed040;
          border-radius: 3px;
          margin-top: 0.2rem; }
          .pDetail .content .space-between .range .process.full {
            background: #56BD7C; }
      .pDetail .content .space-between .left-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: center;
        flex: 1; }
        .pDetail .content .space-between .left-between > div:first-child {
          margin-bottom: 0.4rem; }
      .pDetail .content .space-between.percentBox {
        margin-bottom: 0; }
    .pDetail .content .countdown {
      display: flex; }
      .pDetail .content .countdown .cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 0.53333rem;
        font-size: 0.32rem;
        color: var(--pFont5);
        background: var(--pbg5);
        border-radius: 3px;
        margin: 0 0.08rem; }
        .pDetail .content .countdown .cell:last-child {
          margin-right: 0; }
    .pDetail .content .options {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.26667rem; }
      .pDetail .content .options .left {
        font-size: 0.37333rem;
        color: var(--pFont4); }
      .pDetail .content .options .right {
        font-size: 0.26667rem;
        color: var(--pFont1); }
    .pDetail .content .place {
      display: flex;
      flex-direction: column;
      flex: 1; }
      .pDetail .content .place li {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.38667rem;
        border-radius: 3px;
        background: var(--pbg4);
        padding: 0 0.53333rem;
        margin-bottom: 0.26667rem;
        pointer-events: none; }
        .pDetail .content .place li.show, .pDetail .content .place li.pick {
          border-top: 1px solid;
          border-bottom: 1px solid;
          border-right: 1px solid; }
        .pDetail .content .place li.show {
          margin-bottom: 1.44rem; }
          .pDetail .content .place li.show .placePanel {
            display: flex; }
        .pDetail .content .place li .pointer {
          position: absolute;
          left: 0;
          height: 1.38667rem;
          width: 100%;
          opacity: 0;
          pointer-events: auto; }
        .pDetail .content .place li .range {
          position: absolute;
          left: 0;
          height: 1.38667rem;
          width: 100%; }
        .pDetail .content .place li .indicator {
          position: absolute;
          left: 0;
          width: 0.21333rem;
          height: 1.38667rem; }
        .pDetail .content .place li .leftGrid {
          display: flex; }
          .pDetail .content .place li .leftGrid img {
            width: 0.46667rem;
            margin-right: 0.26667rem; }
        .pDetail .content .place li .grid {
          display: flex;
          flex-direction: column; }
          .pDetail .content .place li .grid.right {
            align-items: flex-end; }
          .pDetail .content .place li .grid span:first-child {
            font-size: 0.26667rem;
            color: var(--pFont1); }
          .pDetail .content .place li .grid span:last-child {
            display: flex;
            align-items: center;
            font-size: 0.37333rem;
            color: var(--pFont4); }
            .pDetail .content .place li .grid span:last-child span {
              font-size: 0.37333rem;
              color: var(--pFont4); }
            .pDetail .content .place li .grid span:last-child img {
              width: 0.37333rem;
              margin-left: 0.13333rem; }
        .pDetail .content .place li .placePanel {
          display: none;
          position: absolute;
          justify-content: space-around;
          left: 0;
          width: 100%;
          top: 1.65333rem;
          pointer-events: auto; }
          .pDetail .content .place li .placePanel > div, .pDetail .content .place li .placePanel > a {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 0.90667rem;
            background: #32353E;
            border-radius: 3px;
            color: var(--pFont1);
            font-size: 0.37333rem;
            margin-right: 0.2rem; }
            .pDetail .content .place li .placePanel > div:last-child, .pDetail .content .place li .placePanel > a:last-child {
              margin-right: 0; }
            .pDetail .content .place li .placePanel > div.button, .pDetail .content .place li .placePanel > a.button {
              background: #FED040;
              color: var(--pFont6); }
    .pDetail .content .button {
      height: 0.90667rem;
      line-height: 0.90667rem;
      text-align: center;
      background: #FED040;
      color: var(--pFont6);
      font-size: 0.37333rem;
      border-radius: 4px; }
    .pDetail .content .judger {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.38667rem;
      background: var(--pbg4);
      border-radius: 3px;
      padding: 0 0.42667rem;
      margin-bottom: 0.26667rem; }
      .pDetail .content .judger.applied {
        border: 1px solid #FED040; }
      .pDetail .content .judger img {
        width: 0.58667rem;
        margin-right: 0.13333rem; }
        .pDetail .content .judger img.applied {
          position: absolute;
          width: 1.33333rem;
          left: 50%;
          margin-left: -0.66667rem; }
      .pDetail .content .judger span {
        font-size: 0.26667rem;
        color: var(--pFont1); }
      .pDetail .content .judger div {
        display: flex;
        justify-content: center;
        align-items: center; }
        .pDetail .content .judger div span {
          font-size: 0.37333rem;
          color: var(--pFont4); }
  .pDetail .tabBar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.12rem;
    border-color: var(--pLine2);
    border-top: 1px solid;
    border-bottom: 1px solid;
    background-color: var(--pbg3); }
    .pDetail .tabBar > div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 1.12rem;
      line-height: 1.12rem;
      font-size: 0.37333rem;
      color: var(--pFont1); }
      .pDetail .tabBar > div.select {
        color: var(--pFont4); }
        .pDetail .tabBar > div.select::after {
          position: absolute;
          bottom: 0;
          content: '';
          width: 0.50667rem;
          height: 0.09333rem;
          background: #FFD040; }
      .pDetail .tabBar > div:first-child {
        margin-left: 0.4rem; }
      .pDetail .tabBar > div:last-child {
        margin-right: 0.4rem; }
  .pDetail .comments {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--pbg3);
    padding: 0.4rem; }
    .pDetail .comments > ul > li {
      display: flex;
      margin-bottom: 0.4rem; }
      .pDetail .comments > ul > li .img {
        display: flex;
        flex-direction: column; }
        .pDetail .comments > ul > li .img.left {
          padding-right: 0.4rem; }
        .pDetail .comments > ul > li .img.right {
          padding-left: 0.4rem; }
        .pDetail .comments > ul > li .img img {
          width: 0.58667rem; }
      .pDetail .comments > ul > li .comment {
        display: flex;
        flex-direction: column;
        flex: 1; }
        .pDetail .comments > ul > li .comment .row1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 0.58667rem; }
        .pDetail .comments > ul > li .comment .row2 {
          display: flex;
          flex-direction: column;
          background: var(--pbg1);
          position: relative;
          padding: 0.26667rem; }
          .pDetail .comments > ul > li .comment .row2::after {
            position: absolute;
            content: '';
            top: 0; }
          .pDetail .comments > ul > li .comment .row2.left::after {
            border-bottom: 10px solid transparent;
            border-right: 8px solid var(--pbg1);
            left: -8px; }
          .pDetail .comments > ul > li .comment .row2.right::after {
            border-bottom: 10px solid transparent;
            border-left: 8px solid var(--pbg1);
            right: -8px; }
          .pDetail .comments > ul > li .comment .row2 .row2-2 {
            display: flex;
            justify-content: space-between;
            font-size: 0.26667rem;
            color: var(--pFont1); }
          .pDetail .comments > ul > li .comment .row2 .detail {
            color: var(--pFont4);
            font-size: 0.32rem; }
        .pDetail .comments > ul > li .comment .row3 {
          display: flex;
          align-items: center;
          margin-top: 0.13333rem; }
          .pDetail .comments > ul > li .comment .row3 img {
            width: 0.32rem;
            margin-right: 0.13333rem; }
          .pDetail .comments > ul > li .comment .row3 span {
            font-size: 0.26667rem;
            color: var(--pFont1); }
  .pDetail .placeHistory {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--pbg3); }
    .pDetail .placeHistory table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse; }
      .pDetail .placeHistory table thead tr th {
        text-align: center;
        border-bottom: 1px solid var(--pLine2);
        height: 0.98667rem;
        color: #4E5767;
        font-size: 0.32rem; }
        .pDetail .placeHistory table thead tr th:first-child {
          text-align: left;
          padding-left: 0.4rem; }
        .pDetail .placeHistory table thead tr th:last-child {
          text-align: right;
          padding-right: 0.4rem; }
      .pDetail .placeHistory table tbody tr td > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 1.25333rem; }
        .pDetail .placeHistory table tbody tr td > div span {
          font-size: 0.32rem;
          color: var(--pFont4);
          text-align: center; }
          .pDetail .placeHistory table tbody tr td > div span.sub {
            font-size: 0.26667rem;
            color: var(--pFont1); }
      .pDetail .placeHistory table tbody tr td:first-child > div {
        align-items: flex-start;
        padding-left: 0.4rem; }
      .pDetail .placeHistory table tbody tr td:last-child > div {
        align-items: flex-end;
        padding-right: 0.4rem; }
  .pDetail .order {
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); }
    .pDetail .order.show {
      display: flex; }
      .pDetail .order.show .order-content {
        bottom: 0; }
    .pDetail .order .order-mask {
      flex: 1; }
    .pDetail .order .order-content {
      position: absolute;
      bottom: -12.46667rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 12.46667rem;
      background: var(--pbg3);
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      padding-top: 0.32rem;
      transition: all 1s linear; }
      .pDetail .order .order-content .order-header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        color: var(--pFont4);
        font-size: 0.42667rem;
        margin-bottom: 0.32rem;
        z-index: 1; }
        .pDetail .order .order-content .order-header img {
          position: absolute;
          right: 0.53333rem;
          width: 0.32rem; }
      .pDetail .order .order-content .order-title {
        padding: 0 0.4rem;
        font-size: 0.37333rem;
        color: var(--pFont4);
        margin-bottom: 0.53333rem; }
      .pDetail .order .order-content .order-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.4rem; }
        .pDetail .order .order-content .order-detail > div {
          display: flex;
          flex-direction: column;
          justify-content: center; }
        .pDetail .order .order-content .order-detail .left {
          align-items: flex-start; }
          .pDetail .order .order-content .order-detail .left > span {
            color: var(--pFont1);
            font-size: 0.32rem; }
            .pDetail .order .order-content .order-detail .left > span:first-child {
              color: var(--pFont4);
              font-size: 0.37333rem; }
        .pDetail .order .order-content .order-detail .right {
          align-items: flex-end; }
          .pDetail .order .order-content .order-detail .right > span {
            display: flex;
            align-items: center;
            color: var(--pFont1);
            font-size: 0.32rem; }
            .pDetail .order .order-content .order-detail .right > span:first-child {
              color: var(--pFont4);
              font-size: 0.37333rem; }
          .pDetail .order .order-content .order-detail .right img {
            width: 0.32rem;
            margin-left: 0.26667rem; }
      .pDetail .order .order-content .divider {
        width: 100%;
        height: 1px;
        background: #22252E;
        margin: 0.26667rem 0; }
      .pDetail .order .order-content .order-place {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.4rem; }
        .pDetail .order .order-content .order-place .order-place-1 {
          font-size: 0.37333rem;
          color: var(--pFont4); }
        .pDetail .order .order-content .order-place .order-place-2 {
          font-size: 0.32rem;
          color: var(--pFont1); }
        .pDetail .order .order-content .order-place .order-place-3 {
          font-size: 0.37333rem;
          color: #F1D040; }
        .pDetail .order .order-content .order-place .order-place-4 {
          display: flex;
          align-items: center; }
          .pDetail .order .order-content .order-place .order-place-4 .order-place-4-1 {
            font-size: 0.26667rem;
            color: var(--pFont1); }
          .pDetail .order .order-content .order-place .order-place-4 .order-place-4-2 {
            font-size: 0.37333rem;
            color: #14A478;
            margin: 0 0.26667rem; }
          .pDetail .order .order-content .order-place .order-place-4 img {
            width: 0.32rem; }
      .pDetail .order .order-content .module-amountBox {
        width: 100%;
        margin-top: 0.26667rem; }
        .pDetail .order .order-content .module-amountBox .ab-amount {
          margin-top: 0; }
      .pDetail .order .order-content .order-btn-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.26667rem 0.4rem; }
        .pDetail .order .order-content .order-btn-group .order-btn-ele {
          flex: 1;
          text-align: center;
          height: 0.90667rem;
          line-height: 0.90667rem;
          background: var(--pbg1);
          color: var(--pFont4);
          margin: 0 0.08rem; }
          .pDetail .order .order-content .order-btn-group .order-btn-ele:first-child {
            margin-left: 0; }
          .pDetail .order .order-content .order-btn-group .order-btn-ele:last-child {
            margin-right: 0; }
      .pDetail .order .order-content .order-comments-area {
        width: 100%;
        padding: 0 0.4rem; }
        .pDetail .order .order-content .order-comments-area textarea {
          width: 100%;
          height: 1.17333rem;
          line-height: 0.45333rem;
          background: var(--pbg1);
          border: 0;
          color: var(--pFont4);
          padding: 0.13333rem; }
      .pDetail .order .order-content .order-btn-content {
        width: 100%;
        padding: 0 0.4rem; }
        .pDetail .order .order-content .order-btn-content .order-btn {
          width: 100%;
          height: 0.90667rem;
          line-height: 0.90667rem;
          background: #FED040;
          text-align: center;
          color: #000000; }
          .pDetail .order .order-content .order-btn-content .order-btn.disabled {
            background: #c3c3c3;
            color: #FFFFFF; }
  .pDetail .loadingBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px; }
    .pDetail .loadingBar span {
      font-size: 12px; }

.pModal {
  font-family: 'DIN,DIN-Regular';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0.89333rem; }
  .pModal .am-modal-content,
  .pModal .p-modal-content {
    height: auto;
    background: var(--pbg3);
    width: 100%;
    padding-top: 0; }
    .pModal .am-modal-content .pModal-title,
    .pModal .p-modal-content .pModal-title {
      display: flex;
      align-items: center;
      padding-left: 0.82667rem;
      height: 1.49333rem;
      font-size: 0.42667rem;
      color: #FFD040;
      border-bottom: 1px solid var(--pLine2); }
    .pModal .am-modal-content .pModal-body > p,
    .pModal .p-modal-content .pModal-body > p {
      padding: 0 0.82667rem;
      text-align: left;
      color: var(--pFont4);
      font-size: 0.37333rem;
      margin: 0.26667rem auto; }
    .pModal .am-modal-content .pModal-body ul,
    .pModal .p-modal-content .pModal-body ul {
      padding: 0 0.82667rem;
      list-style-type: disc;
      border-bottom: 1px solid var(--pLine2); }
      .pModal .am-modal-content .pModal-body ul li,
      .pModal .p-modal-content .pModal-body ul li {
        text-align: left;
        font-size: 0.37333rem;
        color: var(--pFont4);
        margin-bottom: 0.26667rem; }
    .pModal .am-modal-content .pModal-body .verify,
    .pModal .p-modal-content .pModal-body .verify {
      font-size: 0.30667rem; }
      .pModal .am-modal-content .pModal-body .verify::placeholder,
      .pModal .p-modal-content .pModal-body .verify::placeholder {
        font-family: 'DIN,DIN-Regular';
        font-size: 0.34667rem;
        color: var(--t2); }
    .pModal .am-modal-content .pModal-body .pModal-password,
    .pModal .p-modal-content .pModal-body .pModal-password {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0.82667rem; }
      .pModal .am-modal-content .pModal-body .pModal-password .pModal-input-title,
      .pModal .p-modal-content .pModal-body .pModal-password .pModal-input-title {
        font-size: 0.37333rem;
        color: var(--pFont1);
        margin-top: 0.53333rem;
        margin-bottom: 0.2rem; }
      .pModal .am-modal-content .pModal-body .pModal-password .pModal-input,
      .pModal .p-modal-content .pModal-body .pModal-password .pModal-input {
        width: 100%;
        background: #22252E;
        border: 0;
        height: 0.90667rem;
        border-radius: 3px;
        font-size: 0.36rem;
        color: var(--pFont4);
        padding-left: 0.4rem; }
    .pModal .am-modal-content .pModal-body .pModal-apply,
    .pModal .p-modal-content .pModal-body .pModal-apply {
      height: 0.90667rem;
      line-height: 0.90667rem;
      margin: 0.4rem 0.82667rem;
      border-radius: 3px;
      background: #FED040;
      color: #131316;
      font-size: 0.37333rem;
      text-align: center; }
      .pModal .am-modal-content .pModal-body .pModal-apply.disabled,
      .pModal .p-modal-content .pModal-body .pModal-apply.disabled {
        background: var(--bg6);
        color: var(--pFont3); }
    .pModal .am-modal-content .pModal-body .pModal-cancel,
    .pModal .p-modal-content .pModal-body .pModal-cancel {
      height: 0.90667rem;
      line-height: 0.90667rem;
      text-align: center;
      margin-bottom: 0.26667rem;
      font-size: 0.37333rem;
      color: #FED040; }
  .pModal .am-modal-body {
    padding: 0 !important; }
