@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-predictCell {
  margin-bottom: 0.8rem;
  width: 100%; }
  .module-predictCell .pc-infoBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.4rem; }
    .module-predictCell .pc-infoBox .pc-infoBox-nameBox {
      display: flex; }
      .module-predictCell .pc-infoBox .pc-infoBox-nameBox .pc-infoBox-nameBox-image {
        width: 0.58667rem;
        height: 0.58667rem; }
        .module-predictCell .pc-infoBox .pc-infoBox-nameBox .pc-infoBox-nameBox-image img {
          width: 100%; }
      .module-predictCell .pc-infoBox .pc-infoBox-nameBox .pc-infoBox-nameBox-text {
        font-size: 0.37333rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1);
        margin-left: 0.13333rem; }
    .module-predictCell .pc-infoBox .countdown {
      display: flex; }
      .module-predictCell .pc-infoBox .countdown .cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 0.53333rem;
        font-size: 0.32rem;
        color: var(--pFont5);
        background: var(--pbg5);
        border-radius: 3px;
        margin: 0 0.08rem;
        font-family: 'DIN,DIN-Medium'; }
        .module-predictCell .pc-infoBox .countdown .cell:last-child {
          margin-right: 0; }
    .module-predictCell .pc-infoBox .pc-infoBox-statusBox {
      display: flex;
      align-items: center; }
      .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-status {
        font-family: 'DIN,DIN-Medium';
        font-size: 0.26667rem; }
        .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-status.status-1 {
          color: #FEB540; }
        .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-status.status-2 {
          color: #F74860; }
        .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-status.status-3 {
          color: #777D90; }
        .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-status.status-7 {
          color: #FED040; }
        .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-status.status-8 {
          color: #56BD7C; }
      .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-image {
        width: 0.32rem;
        margin-left: 0.13333rem; }
        .module-predictCell .pc-infoBox .pc-infoBox-statusBox .pc-infoBox-statusBox-image img {
          width: 100%; }
  .module-predictCell .pc-dialogBox {
    display: block;
    background: var(--pbg4);
    margin: 0.53333rem 0.4rem 0;
    position: relative;
    border-radius: 0.08rem; }
    .module-predictCell .pc-dialogBox .pc-dialogBox-title {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      color: var(--pFont4);
      padding: 0 0.4rem;
      margin-top: 0.32rem;
      display: flex; }
    .module-predictCell .pc-dialogBox .pc-dialogBox-countBox {
      display: flex;
      align-items: center;
      padding: 0 0.4rem; }
      .module-predictCell .pc-dialogBox .pc-dialogBox-countBox .pc-dialogBox-countBox-title {
        font-size: 0.26667rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1); }
      .module-predictCell .pc-dialogBox .pc-dialogBox-countBox .pc-dialogBox-countBox-symbol {
        font-size: 0.26667rem;
        color: var(--pFont1);
        width: 1px;
        height: 0.25333rem;
        background-color: var(--pFont1);
        margin: 0 0.26667rem; }
      .module-predictCell .pc-dialogBox .pc-dialogBox-countBox .pc-dialogBox-countBox-row {
        display: flex;
        align-items: center; }
        .module-predictCell .pc-dialogBox .pc-dialogBox-countBox .pc-dialogBox-countBox-row .pc-dialogBox-countBox-row-image {
          width: 0.32rem;
          display: flex; }
          .module-predictCell .pc-dialogBox .pc-dialogBox-countBox .pc-dialogBox-countBox-row .pc-dialogBox-countBox-row-image img {
            width: 100%; }
        .module-predictCell .pc-dialogBox .pc-dialogBox-countBox .pc-dialogBox-countBox-row .pc-dialogBox-countBox-row-count {
          font-size: 0.26667rem;
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont1);
          margin-left: 0.13333rem; }
    .module-predictCell .pc-dialogBox .pc-dialogBox-prizeBox {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 0.4rem; }
      .module-predictCell .pc-dialogBox .pc-dialogBox-prizeBox .pc-dialogBox-prizeBox-prize {
        color: var(--pFont4);
        font-size: 0.42667rem;
        font-family: 'DIN,DIN-Medium'; }
        .module-predictCell .pc-dialogBox .pc-dialogBox-prizeBox .pc-dialogBox-prizeBox-prize span {
          color: var(--pFont1);
          font-size: 0.26667rem; }
      .module-predictCell .pc-dialogBox .pc-dialogBox-prizeBox .pc-dialogBox-prizeBox-percent {
        font-size: 0.26667rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--active); }
    .module-predictCell .pc-dialogBox::before {
      content: "";
      width: 0;
      height: 0;
      border-width: 0rem 0.66667rem 0.36rem 0rem;
      border-color: transparent transparent var(--pbg4) var(--pbg4);
      border-style: solid;
      position: absolute;
      top: -0.36rem;
      left: 0.46667rem; }
  .module-predictCell .pc-resultBox {
    padding: 0 0.4rem;
    margin-top: 0.26667rem; }
    .module-predictCell .pc-resultBox .pc-resultBox-list {
      width: 100%; }
      .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-head {
        height: 0.93333rem;
        border-top: 1px solid var(--pLine2);
        border-bottom: 1px solid var(--pLine2); }
        .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-head .pc-resultBox-list-head-content {
          font-size: 0.32rem;
          color: var(--pFont7);
          font-family: 'DIN,DIN-Medium'; }
          .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-head .pc-resultBox-list-head-content:first-child {
            text-align: left; }
          .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-head .pc-resultBox-list-head-content:last-child {
            text-align: right; }
      .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-row {
        border-spacing: 10px; }
        .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-row .pc-resultBox-list-row-content {
          font-size: 0.32rem;
          color: var(--pFont4);
          font-family: 'DIN,DIN-Medium';
          line-height: 0.4rem;
          padding-top: 0.45333rem; }
          .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-row .pc-resultBox-list-row-content span {
            color: var(--pFont1);
            font-size: 0.26667rem;
            display: block;
            line-height: 0.33333rem; }
          .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-row .pc-resultBox-list-row-content:first-child {
            text-align: left; }
          .module-predictCell .pc-resultBox .pc-resultBox-list .pc-resultBox-list-row .pc-resultBox-list-row-content:last-child {
            text-align: right; }
  .module-predictCell .pc-dialogBox-progressBox {
    padding: 0.24rem 0.4rem 0.53333rem;
    display: block; }
    .module-predictCell .pc-dialogBox-progressBox .pc-dialogBox-progressBox-basic {
      width: 100%;
      background: var(--pbg6);
      height: 0.09333rem;
      width: 100%;
      border-radius: 0.04rem;
      position: relative; }
    .module-predictCell .pc-dialogBox-progressBox .pc-dialogBox-progressBox-value {
      position: absolute;
      height: 0.09333rem;
      background: var(--active); }
      .module-predictCell .pc-dialogBox-progressBox .pc-dialogBox-progressBox-value.max {
        background: #56BD7C; }
  .module-predictCell .pc-dialogBox-decline {
    border: 1px solid #F74860;
    color: #F74860;
    font-size: 0.29333rem;
    font-family: 'DIN,DIN-Medium';
    margin-top: -0.26667rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    border-radius: 0.04rem;
    padding: 0 0.26667rem;
    display: inline-block; }
