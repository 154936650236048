@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-marginModal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative; }
  .module-marginModal .mm-black {
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .module-marginModal .mm-content {
    width: 90%;
    background: var(--bg16);
    z-index: 2;
    padding: 0 0.66667rem; }
    .module-marginModal .mm-content .mm-content-title {
      font-size: 0.48rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: var(--active);
      margin-top: 0.66667rem; }
    .module-marginModal .mm-content .mm-content-desc {
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: var(--mainFont);
      margin-top: 0.53333rem; }
    .module-marginModal .mm-content .mm-content-type {
      display: flex;
      justify-content: space-between;
      margin-top: 0.66667rem; }
      .module-marginModal .mm-content .mm-content-type .mm-content-type-btn {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: var(--mainFont);
        background: var(--bg18);
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        width: 3.2rem;
        position: relative;
        border-radius: 0.10667rem; }
        .module-marginModal .mm-content .mm-content-type .mm-content-type-btn .mm-content-type-btn-image {
          display: none;
          position: absolute;
          right: 0;
          bottom: 0; }
        .module-marginModal .mm-content .mm-content-type .mm-content-type-btn.active {
          border: 1px solid var(--active); }
          .module-marginModal .mm-content .mm-content-type .mm-content-type-btn.active .mm-content-type-btn-image {
            display: block;
            width: 0.53333rem;
            height: 0.53333rem; }
            .module-marginModal .mm-content .mm-content-type .mm-content-type-btn.active .mm-content-type-btn-image svg {
              width: 100%; }
    .module-marginModal .mm-content .mm-content-expandBtn {
      display: flex;
      align-items: center;
      margin-top: 0.53333rem;
      line-height: 1;
      margin-bottom: 0.53333rem; }
      .module-marginModal .mm-content .mm-content-expandBtn .mm-content-expandBtn-text {
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #777E90; }
      .module-marginModal .mm-content .mm-content-expandBtn .mm-content-expandBtn-image {
        width: 0.16rem;
        height: 0.26667rem;
        margin-right: 0.26667rem; }
        .module-marginModal .mm-content .mm-content-expandBtn .mm-content-expandBtn-image svg {
          width: 100%; }
    .module-marginModal .mm-content .mm-content-expand {
      margin-bottom: 0.53333rem; }
      .module-marginModal .mm-content .mm-content-expand .mm-content-expand-text {
        margin-top: 0.32rem;
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 400;
        color: #777E90; }
      .module-marginModal .mm-content .mm-content-expand span {
        font-weight: 600; }
