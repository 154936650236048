@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-pFooter {
  height: 1.73333rem;
  width: 100%;
  display: flex;
  background: var(--pbg1); }
  .module-pFooter .pf-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .module-pFooter .pf-block .pf-block-image {
      width: 0.53333rem;
      height: 0.53333rem; }
      .module-pFooter .pf-block .pf-block-image img {
        width: 100%; }
    .module-pFooter .pf-block .pf-block-text {
      margin-top: 0.13333rem;
      color: var(--pFont1);
      font-size: 0.32rem;
      font-family: 'DIN,DIN-Medium'; }
    .module-pFooter .pf-block.active .pf-block-text {
      color: var(--active); }
