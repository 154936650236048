@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-confirmCreated {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: var(--pbg2); }
  .stage-confirmCreated .cc-main {
    overflow: auto; }
    .stage-confirmCreated .cc-main .cc-composeBox {
      padding: 0 0.4rem; }
  .stage-confirmCreated .cc-dataBox {
    padding: 0 0.4rem; }
    .stage-confirmCreated .cc-dataBox .cc-dataBox-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.4rem; }
      .stage-confirmCreated .cc-dataBox .cc-dataBox-row .cc-dataBox-row-title {
        font-size: 0.37333rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1); }
      .stage-confirmCreated .cc-dataBox .cc-dataBox-row .cc-dataBox-row-content {
        font-size: 0.37333rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont4);
        max-width: 60%;
        word-wrap: break-word;
        text-align: right; }
  .stage-confirmCreated .cc-tagBox {
    padding: 0 0.4rem; }
    .stage-confirmCreated .cc-tagBox .cc-tagBox-list {
      margin-bottom: 0.26667rem; }
      .stage-confirmCreated .cc-tagBox .cc-tagBox-list .cc-tagBox-list-cellBox {
        display: inline-block;
        margin-right: 0.13333rem; }
        .stage-confirmCreated .cc-tagBox .cc-tagBox-list .cc-tagBox-list-cellBox .cc-tagBox-list-cellBox-cell .cc-tagBox-list-cellBox-cell-image {
          width: 0.32rem; }
          .stage-confirmCreated .cc-tagBox .cc-tagBox-list .cc-tagBox-list-cellBox .cc-tagBox-list-cellBox-cell .cc-tagBox-list-cellBox-cell-image.FIFA {
            width: 0.8rem; }
          .stage-confirmCreated .cc-tagBox .cc-tagBox-list .cc-tagBox-list-cellBox .cc-tagBox-list-cellBox-cell .cc-tagBox-list-cellBox-cell-image img {
            width: 100%; }
        .stage-confirmCreated .cc-tagBox .cc-tagBox-list .cc-tagBox-list-cellBox .cc-tagBox-list-cellBox-cell .cc-tagBox-list-cellBox-cell-text {
          font-size: 0.29333rem;
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont1);
          padding: 0.05333rem 0.24rem;
          background: var(--pbg1);
          border-radius: 0.04rem;
          margin-bottom: 0.13333rem; }
  .stage-confirmCreated .cc-optionBox {
    padding: 0 0.4rem;
    margin-top: 0.32rem; }
    .stage-confirmCreated .cc-optionBox .cc-optionBox-top {
      display: flex;
      justify-content: space-between; }
      .stage-confirmCreated .cc-optionBox .cc-optionBox-top .cc-optionBox-top-title {
        font-size: 0.37333rem;
        color: var(--pFont1);
        font-family: 'DIN,DIN-Medium'; }
      .stage-confirmCreated .cc-optionBox .cc-optionBox-top .cc-optionBox-top-infoBox {
        display: flex; }
        .stage-confirmCreated .cc-optionBox .cc-optionBox-top .cc-optionBox-top-infoBox .cc-optionBox-top-infoBox-image {
          width: 0.32rem;
          margin-right: 0.13333rem; }
          .stage-confirmCreated .cc-optionBox .cc-optionBox-top .cc-optionBox-top-infoBox .cc-optionBox-top-infoBox-image img {
            width: 100%; }
        .stage-confirmCreated .cc-optionBox .cc-optionBox-top .cc-optionBox-top-infoBox .cc-optionBox-top-infoBox-text {
          font-size: 0.37333rem;
          color: var(--pFont1);
          font-family: 'DIN,DIN-Medium'; }
  .stage-confirmCreated .cc-totalBox {
    display: flex;
    justify-content: space-between;
    margin: 0 0.4rem;
    border-top: 1px solid var(--pLine2);
    margin-top: 0.4rem; }
    .stage-confirmCreated .cc-totalBox .cc-totalBox-left {
      display: flex;
      align-items: flex-end; }
      .stage-confirmCreated .cc-totalBox .cc-totalBox-left .cc-totalBox-left-deposit {
        color: var(--active);
        font-family: 'DIN,DIN-Medium';
        font-size: 0.37333rem; }
    .stage-confirmCreated .cc-totalBox .cc-totalBox-right {
      margin-top: 0.26667rem; }
      .stage-confirmCreated .cc-totalBox .cc-totalBox-right .cc-totalBox-right-block {
        display: flex;
        align-items: flex-end;
        justify-content: space-between; }
        .stage-confirmCreated .cc-totalBox .cc-totalBox-right .cc-totalBox-right-block .cc-totalBox-right-block-title {
          color: var(--pFont1);
          font-size: 0.26667rem;
          font-family: 'DIN,DIN-Medium';
          margin-right: 0.26667rem; }
        .stage-confirmCreated .cc-totalBox .cc-totalBox-right .cc-totalBox-right-block .cc-totalBox-right-block-content {
          display: flex;
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont4); }
          .stage-confirmCreated .cc-totalBox .cc-totalBox-right .cc-totalBox-right-block .cc-totalBox-right-block-content.balance {
            color: var(--r1); }
          .stage-confirmCreated .cc-totalBox .cc-totalBox-right .cc-totalBox-right-block .cc-totalBox-right-block-content span {
            width: 0.32rem;
            display: block;
            margin-left: 0.26667rem; }
            .stage-confirmCreated .cc-totalBox .cc-totalBox-right .cc-totalBox-right-block .cc-totalBox-right-block-content span img {
              width: 100%; }
  .stage-confirmCreated .cc-buttonRow {
    display: flex;
    margin-top: 0.66667rem;
    margin-bottom: 0.53333rem;
    padding: 0 0.4rem; }
    .stage-confirmCreated .cc-buttonRow .cc-submit {
      border-radius: 3px;
      height: 0.90667rem;
      line-height: 0.90667rem;
      text-align: center;
      background: var(--active);
      font-family: 'DIN,DIN-Medium';
      font-size: 0.37333rem;
      color: var(--pFont2);
      flex: 1;
      margin-left: 0.13333rem; }
    .stage-confirmCreated .cc-buttonRow .cc-save {
      background: var(--pbg1);
      color: var(--pFont1);
      font-family: 'DIN,DIN-Medium';
      font-size: 0.37333rem;
      flex: 1;
      margin-right: 0.13333rem;
      height: 0.90667rem;
      line-height: 0.90667rem;
      text-align: center; }

.puzzle-createSuccess {
  background: var(--pbg3);
  width: 90%;
  margin: auto;
  padding: 0 0.66667rem;
  border-radius: 0.04rem; }
  .puzzle-createSuccess .cs-title {
    font-size: 0.42667rem;
    color: var(--active);
    font-family: 'DIN,DIN-Medium';
    padding: 0.4rem 0;
    border-bottom: 1px solid var(--pLine2); }
  .puzzle-createSuccess .cs-content {
    font-size: 0.37333rem;
    color: var(--pFont4);
    font-family: 'DIN,DIN-Medium';
    margin-top: 0.48rem; }
  .puzzle-createSuccess .cs-flex {
    display: flex;
    justify-content: space-between; }
    .puzzle-createSuccess .cs-flex .cs-btn, .puzzle-createSuccess .cs-flex .cs-btn-grey {
      border-radius: 0.10667rem;
      width: 100%;
      height: 0.90667rem;
      line-height: 0.90667rem;
      background: var(--active);
      font-size: 0.37333rem;
      color: var(--pFont6);
      font-family: 'DIN,DIN-Medium';
      margin: 0.66667rem 0.13333rem 0.53333rem;
      text-align: center; }
    .puzzle-createSuccess .cs-flex .cs-btn-grey {
      background: var(--pbg5);
      color: var(--pFont1); }
