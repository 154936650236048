@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #231e45; }
  .page .golden {
    position: relative;
    padding-bottom: 2.66667rem; }
    .page .golden .banner {
      position: relative; }
      .page .golden .banner .egg {
        position: absolute;
        width: 100%;
        bottom: 0.85333rem; }
    .page .golden .activity {
      padding: 0.21333rem 0;
      color: white; }
      .page .golden .activity > div {
        padding: 0.26667rem; }
    .page .golden .border {
      border: 1px solid #0f28ac;
      border-radius: 0.26667rem;
      background: linear-gradient(to bottom, #0e0c38, #1d1972);
      margin: -4.4rem 0.32rem 0.26667rem 0.32rem;
      padding: 0.48rem;
      font-size: 0.32rem;
      color: white;
      position: relative;
      z-index: 1; }
    .page .golden .calender {
      margin: 0.53333rem 0.26667rem; }
    .page .golden .subheader, .page .golden .rulesHeader {
      margin-top: 1.06667rem;
      margin-left: 0.4rem;
      color: white; }
    .page .golden .rulesHeader {
      color: #96d7f4; }
    .page .golden .rulesTable {
      width: 90%;
      margin: 0.26667rem 0.4rem;
      color: white; }
      .page .golden .rulesTable td {
        vertical-align: top; }
    .page .golden .prizeSelection {
      margin: 0.26667rem 0.32rem 0.53333rem 0.32rem; }
    .page .golden .prizeBox {
      margin: 0.26667rem 1.6rem 0.53333rem 1.6rem; }
    .page .golden .eggContainer {
      position: relative; }
      .page .golden .eggContainer .activity {
        position: relative;
        width: 100%;
        min-height: 50vh;
        color: white; }
        .page .golden .eggContainer .activity .prizeTitle {
          margin: 0.8rem 0 0.4rem 0; }
      .page .golden .eggContainer .red {
        color: red; }
      .page .golden .eggContainer .button {
        color: red;
        cursor: pointer;
        display: inline-block;
        touch-action: manipulation; }
      .page .golden .eggContainer > div {
        padding: 0.13333rem 0; }
      .page .golden .eggContainer .tables {
        max-height: 8rem;
        overflow-y: scroll;
        text-align: left; }
        .page .golden .eggContainer .tables table {
          width: 80%;
          margin: 0 auto;
          max-height: 7.2rem; }
          .page .golden .eggContainer .tables table th {
            margin: auto;
            font-size: 0.37333rem;
            color: var(--active);
            padding: 0.05333rem 0.53333rem;
            padding-bottom: 0.32rem; }
          .page .golden .eggContainer .tables table td {
            padding: 0.05333rem 0.53333rem; }
  .page .back {
    position: absolute;
    top: 0.26667rem;
    left: 0.26667rem;
    color: white;
    z-index: 2; }
  .page .mt {
    margin-top: 0.66667rem; }
  .page .mt:last-of-type {
    margin-bottom: 1.33333rem; }
  .page .mb {
    margin-bottom: 0.4rem; }
  .page .header {
    background-color: var(--fff-bg); }
  .page .wrapper {
    padding-top: 1.17333rem; }
    .page .wrapper img {
      width: 100%;
      display: block;
      object-fit: fill;
      position: relative;
      z-index: 1; }
  .page .footer {
    width: 100%;
    height: 1.6rem;
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0; }
    .page .footer .submitBtn {
      width: 100%;
      height: 1.2rem;
      line-height: 1.2rem;
      border-radius: 1.33333rem;
      color: var(--fff);
      text-align: center; }

.prizePopup {
  height: 100%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 0.64rem; }
  .prizePopup .prizeText {
    display: flex;
    justify-content: center;
    align-items: center; }
    .prizePopup .prizeText span:last-child {
      color: #DB150B;
      font-size: 0.74667rem; }
  .prizePopup img {
    width: 100%;
    margin-top: 20%;
    object-fit: fill; }
  .prizePopup .cross {
    margin: 0;
    width: 0.8rem; }
