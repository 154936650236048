html,
body,
#root {
  height: 100%;
  //height: calc(100vh - var(--browser-address-bar, 0px));
  font-size: rem(13px);
  color: $t2;
  //overflow: -moz-hidden-unscrollable;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
}

.adapt-root {
  height: calc(100vh - var(--browser-address-bar, 0px)) !important;
}

.appRoot {
  position: relative;

  .load {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

.safeView {
  margin-top: rem(40px);
  position: relative;
  height: 100%;
}


.app-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* bottom: -1px; */
  /* transform: translateY(-1px); */
  opacity: 0.999;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

body,
a {
  color: $t2;

  a:visited {
    color: $t2;
  }
}

#root {
  > div {
    height: 100vh;
    overflow-y: scroll;
    //display: flex;
    //flex-direction: column;
  }
}

body {
  background-color: $bg1;
}

.am-navbar {
  background-color: $bg1;
  color: $t1;
  width: 100%;
}


.active {
  color: $active;
}

.form {
  margin-left: rem(25);
  margin-right: rem(25);

  svg {
    font-size: rem(16);
  }

  h3 {
    color: $t1;
    font-size: rem(24);
    font-weight: normal;
    margin-top: rem(40);
    margin-bottom: rem(40);
  }

  .ipt {
    margin-top: rem(20);
  }

  .close {
    svg {
      fill: $active;
    }
  }

  .btn {
    padding: 0;
    margin: 0;
    line-height: rem(50);
    background-color: $active;
    border-radius: rem(2);
    width: 100%;
    color: $bg1;
    font-size: rem(17);
    border: 0 none;
  }

  .tip {
    text-align: right;
    line-height: rem(50);

    a {
      color: $t2;
    }
  }

  .mobile-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-right: rem(5);
    }

    span:nth-child(3) {
      border-right: 1px solid $t2;
      padding-right: rem(8);
      line-height: rem(14);
      height: rem(14);

      svg {
        font-size: rem(10);
      }
    }
  }
}

.toggle {
  position: absolute;
  width: 100%;
  bottom: rem(30);
  text-align: center;

  span {
    color: $active;
  }
}

.am-navbar-title {
  color: $f5;
  font-weight: bold;
  font-size: rem(18px);
}

.am-stepper-input-wrap {
  height: rem(42);
}

.am-stepper {
  height: rem(42);
}

.am-stepper-handler, .am-stepper-handler-up-inner, .am-stepper-handler-down-inner {
  height: rem(42);
  width: rem(42);
  line-height: rem(42);
}

.am-navbar-right {
  font-size: rem(15);
}

.am-tabs-default-bar {
  background-color: transparent !important;
}

.am-tabs-default-bar-tab {
  color: $t1;
}

.am-tabs-default-bar-tab-active {
  color: $active !important;
}

.am-tabs-default-bar-underline {
  display: none !important;
}

.am-tabs-default-bar-tab::after {
  width: 20% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: transparent !important;
}

.am-tabs-default-bar-tab-active::after {
  background-color: $active !important;
  height: 2px !important;
}

.ant-switch-checked {
  background-color: $active;
}

.am-picker-col-mask {
  background-image: linear-gradient(to bottom, rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.95),
          rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.6)),
  linear-gradient(to top, rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.95),
                  rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.6))!important;
}

.am-picker-popup-item {
  color: $mainFont1 !important;
}

.am-picker-col {
  background-color: $bg2;
}

.am-picker-col-item {
  color: $mainFont1!important;
  @include fontDM;
}

.am-list-item {
  padding-left: 0 !important;
}

.am-list-item .am-input-label.am-input-label-5 {
  margin-right: rem(10);
}

.am-accordion-content-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.am-modal-mask {
  z-index: 100;
}

.am-modal-wrap {
  z-index: 100;
}

.action {
  position: relative;

  .loading {
    position: absolute;
    left: 35%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.toggle {
  position: absolute;
  width: 100%;
  bottom: 0.8rem;
  text-align: center;
}


.ml5 {
  margin-left: rem(5);
}

.ml10 {
  margin-left: rem(10);
}

.ml15 {
  margin-left: rem(15);
}

.mt5 {
  margin-top: rem(5);
}

.mt10 {
  margin-top: rem(10);
}

.mt15 {
  margin-top: rem(15);
}

.mr5 {
  margin-right: rem(5);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

i, em {
  font-style: normal;
}

::-webkit-scrollbar {
  display: none;
}

:global {
  .am-action-sheet-button-list .am-action-sheet-destructive-button {
    background-color: $fffBg;
  }

  .am-action-sheet-button-list .am-action-sheet-button-list-item {
    background-color: $fffBg;
    height: rem(64);
    line-height: rem(64);
    font-size: rem(16);
    overflow: hidden;
  }

  .am-action-sheet-button-list .am-action-sheet-cancel-button {
    color: $t2;
  }

  .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
    background: $t5 !important;
  }

  .am-list-body::before, .am-list-body::after {
    height: 0 !important;
  }
}

:global {
  .fiatCell {
    padding-left: rem(10);
  }
}

:global {
  .am-action-sheet {
    background: var(--active) !important;
  }

  .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
    background: #1e2c37 !important;
  }
}

:global {
  .am-action-sheet {
    background: var(--active) !important;
  }

  .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
    background: #1e2c37 !important;
  }
}

:global {
  .my-radio .am-radio {
    padding: 2.5px;
    border: 1px solid $t5;
    border-radius: 50%;
    margin-right: rem(5);
    color: $f9;
    line-height: rem(14);
  }
}

:global {
  .am-list-body::before,
  .am-list-body::after {
    background-color: $lineBg1 !important;
  }

  .react-dropdown-select-content {
    color: $mainFont1;
  }

  .react-dropdown-select-dropdown {
    width: 100% !important;
    top: rem(35) !important;
    border: none !important;
  }

  .fiatCell {
    height: rem(36);
    display: flex;
    align-items: center;
    background-color: $bg2;
    color: #858e9d;
  }

  .fiatCellActive {
    color: $active;
  }
}

:global(.am-list-view-scrollview-content) {
  position: relative !important;
}

:global(.am-picker-popup-header) {
  background-color: $bg2;

  &::after {
    background-color: $lineBg1 !important;
  }
}

:global(.am-picker-col-indicator) {
  &::before,
  &::after {
    background-color: $lineBg1 !important;
  }
}

:global(.am-accordion .am-accordion-item .am-accordion-header) {
  @include font-style(15);
  background-color: $fffBg;
  height: rem(58);
  line-height: rem(58);
  color: $f5;

  &::after {
    background-color: $spotInputBg !important;
  }

  //border-bottom: 1px solid $f8 !important;
}

:global(.am-accordion .am-accordion-item .am-accordion-header i) {
  top: 20px
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box) {
  font-size: rem(15px);
  font-weight: 400;
  background-color: var(--night-bg2, #f5f5f5);
  line-height: 24px;
  color: $f6;

  &::before {
    background-color: $spotInputBg !important;
  }
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraph) {
  text-align: left;
  width: 100%;
  font-size: rem(15px);
  line-height: 24px;
  color: $f6;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraphTitle) {
  text-align: left;
  width: 100%;
  font-size: rem(15px);
  line-height: 24px;
  color: $f6;
  margin-top: rem(15);
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraph2) {
  text-align: left;
  width: 100%;
  font-size: rem(13px);
  line-height: 24px;
  color: $f6;
  margin-top: rem(15);
  text-align: left;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .tableLine) {
  width: 100%;
  height: rem(15);
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraph3) {
  text-align: left;
  width: 100%;
  font-size: rem(15px);
  line-height: 24px;
  color: $f6;
  margin: rem(15) 0;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box table) {
  width: 100%;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box table tr td) {
  font-size: rem(12);
  border: 1px solid $t5;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box table tr .center) {
  text-align: center;
}

:global(.am-list-body) {
  background-color: $bg1;
}

:global(.am-accordion .am-accordion-item .am-accordion-header){
  color: $mainFont1;
  font-weight: 500 !important;
}

:global(.am-list-body) {
  &::before {
    background-color: $lineBg1 !important;
  }
}

:global(.am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.select-down) {
  margin-left: rem(-5);
}

:global(.slider-frame) {
  border-radius: rem(5px);
}

:global(.am-stepper) {
  height: rem(44);
}

:global(.am-stepper-input-wrap) {
  height: rem(44) !important;
  border: 0 !important;
}

:global(.am-stepper-handler) {
  border: 0;
}

:global(.am-stepper-handler-up) {
  width: rem(42) !important;
  height: rem(42) !important;
  color: transparent !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url('../assets/svg/newIcon/handler-up.svg');
  background-size: cover;
}

:global(.am-stepper-handler-down) {
  width: rem(42) !important;
  height: rem(42) !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: transparent !important;
  background: url('../assets/svg/newIcon/handler-down.svg');
  background-size: cover;
}

:global(.am-accordion .am-accordion-item .am-accordion-header) {
  @include font-style(15, bold);
  background-color: $fffBg;
  height: rem(58);
  line-height: rem(58);
  color: $mainFont1;

  &::after {
    background-color: var(--night-line-bg, #ddd) !important;
  }
}

:global(.am-accordion .am-accordion-item .am-accordion-header i) {
  top: 20px
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box) {
  font-size: rem(14px);
  font-weight: 400;
  color: $f6;
  background-color: var(--night-bg2, #f5f5f5);
  text-align: left;

  p {
    text-align: left;
  }

  &:after {
    background-color: var(--night-line-bg, #f5f5f5) !important;
  }
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box p) {
  text-align: left;
  width: 100%;
  line-height: 24px;
  color: $f6;
  text-indent: 2em;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .title) {
  text-align: left;
  width: 100%;
  line-height: rem(20);
  margin: rem(10) 0;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .title2) {
  text-align: left;
  width: 100%;
  line-height: rem(20);
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .title3) {
  text-align: left;
  width: 100%;
  line-height: rem(20);
  text-indent: 2em;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .li) {
  text-align: left;
  width: 100%;
  line-height: rem(25);
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box span) {
  width: 350px;
  margin: rem(20) auto;
}

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box span img) {
  width: 100%;
}

:global(.am-accordion) {
  &::before {
    background-color: $lineBg1 !important;
  }
}

:global(.am-stepper) {
  height: rem(44);
}

:global(.am-stepper-input-wrap) {
  height: rem(44) !important;
  border: 0 !important;
}

:global(.am-stepper-handler) {
  border: 0;
}

:global(.am-stepper-handler-up) {
  width: rem(42) !important;
  height: rem(42) !important;
  color: transparent !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url('../assets/svg/newIcon/handler-up.svg');
  background-size: cover;
}

:global(.am-stepper-handler-down) {
  width: rem(42) !important;
  height: rem(42) !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: transparent !important;
  background: url('../assets/svg/newIcon/handler-down.svg');
  background-size: cover;
}

:global(.am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

:global(.content) {
  padding: 0;
}

:global(.am-list-body) {
  background-color: $bg1;
}

:global(.slider-slide span) {
  font-weight: bold;
}

:global(.am-popover .am-popover-item) {
  background-color: $bg2;
  color: $mainFont1;
}

:global(.am-popover .am-popover-item.am-popover-item-active) {
  background-color: $bg2!important;
  color: $mainFont1;
}

:global(.right) {
  :global(a) {
    :global(.C-Svg) {
      :global(svg) {
        :global(#gq) {
          fill: var(--night-font4, #202020);
        }
      }
    }
  }
}

:global(.center) {
  font-size: rem(14);
}

:global(.select-down) {
  @include font-style(14, 400);
  margin-left: rem(-5);
}

:global(.am-popover-inner-wrapper) {
  background-color: $bg2!important;
}

:global(.am-popover-arrow) {
  background-color: $bg2!important;
}

.module-header {
  .center {
    //width: rem(160px);
    .bskTabs {
      //width: rem(160px);
      font-size: rem(14px);
      display: flex;
      flex-direction: row;
      border-radius: rem(2px);
      font-weight: normal;

      .tabItem {
        padding: rem(4px) rem(11px);
        text-align: center;
        background-color: var(--night-bg3, #E5E5E5);
        color: $f6;

        &.tabItemActive {
          background: $active;
          color: $t6;
        }
      }
    }
  }
}
@keyframes alertAni {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
#alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  z-index: 99;
  div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 70%;
    font-size: rem(14);
    font-weight: 400;
    background-color: yellow;
    border-radius: rem(4);
    text-align: center;
    animation: alertAni 500ms;
  }
}
