@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-perpetual {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: var(--bg12);
  position: relative;
  overflow: auto;
  overflow-x: hidden; }
  .stage-perpetual .trade-header {
    height: 1.17333rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: var(--bg12); }
    .stage-perpetual .trade-header.safeView1 {
      top: 1.06667rem; }
    .stage-perpetual .trade-header .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 1.17333rem;
      width: 0.8rem; }
      .stage-perpetual .trade-header .left .back {
        display: flex;
        padding-right: 0.4rem; }
        .stage-perpetual .trade-header .left .back .C-Svg {
          zoom: 0.5; }
      .stage-perpetual .trade-header .left .left_f {
        display: flex; }
        .stage-perpetual .trade-header .left .left_f.l {
          margin-left: 0.4rem; }
        .stage-perpetual .trade-header .left .left_f .C-Svg {
          zoom: 0.5; }
        .stage-perpetual .trade-header .left .left_f img {
          width: 0.53333rem;
          height: 0.53333rem; }
      .stage-perpetual .trade-header .left .left_r {
        display: flex;
        font-family: Source Han Sans CN;
        align-items: center;
        margin-left: 0.26667rem; }
        .stage-perpetual .trade-header .left .left_r .switch-title {
          width: 0.45333rem;
          height: 0.45333rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--bg5);
          font-size: 0.32rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: var(--bg5);
          line-height: 0.45333rem;
          border-radius: 0.05333rem; }
          .stage-perpetual .trade-header .left .left_r .switch-title.st {
            background: var(--bg5);
            color: var(--fff); }
        .stage-perpetual .trade-header .left .left_r .crypto {
          font-size: 0.48rem;
          font-weight: bold;
          color: #333333; }
        .stage-perpetual .trade-header .left .left_r .pr {
          font-size: 0.32rem;
          font-weight: 400;
          display: flex; }
          .stage-perpetual .trade-header .left .left_r .pr.red {
            color: #e76d42; }
          .stage-perpetual .trade-header .left .left_r .pr.green {
            color: #00cf8c; }
          .stage-perpetual .trade-header .left .left_r .pr .bg {
            display: inline-block;
            padding: 0 0.08rem; }
            .stage-perpetual .trade-header .left .left_r .pr .bg.red {
              background: rgba(231, 109, 66, 0.1); }
            .stage-perpetual .trade-header .left .left_r .pr .bg.green {
              background: rgba(0, 207, 140, 0.1); }
    .stage-perpetual .trade-header .center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 1.17333rem; }
      .stage-perpetual .trade-header .center .button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.17333rem;
        background: var(--fff-bg);
        font-weight: 500;
        color: var(--f6);
        font-size: 0.42667rem;
        font-family: var(--pMFont);
        margin-right: 0.48rem; }
        .stage-perpetual .trade-header .center .button.active {
          color: var(--mainFont); }
          .stage-perpetual .trade-header .center .button.active:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 0.4rem;
            height: 0.08rem;
            margin: auto;
            background: var(--active); }
      .stage-perpetual .trade-header .center .bsk-tabs {
        padding: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 0.98667rem;
        border-radius: 0.10667rem;
        border: none; }
        .stage-perpetual .trade-header .center .bsk-tabs span {
          display: flex;
          justify-content: center;
          align-items: center; }
        .stage-perpetual .trade-header .center .bsk-tabs .bsk-tabs-item {
          font-size: 0.34667rem;
          font-family: 'DIN,DIN-Medium';
          font-weight: 500;
          color: var(--t10);
          width: 25%;
          height: 100%; }
        .stage-perpetual .trade-header .center .bsk-tabs .bsk-tabs-item-active {
          width: 25%;
          height: 0.82667rem;
          font-size: 0.34667rem;
          font-family: 'DIN,DIN-Medium';
          font-weight: 500;
          color: var(--mainFont4);
          background: var(--night-bg2, var(--bg9));
          border-radius: 0.10667rem; }
    .stage-perpetual .trade-header .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 1.17333rem; }
      .stage-perpetual .trade-header .right .C-Svg {
        flex: 1; }
      .stage-perpetual .trade-header .right span {
        font-family: PingFangSC-Regular;
        font-size: 0.37333rem;
        color: var(--f6);
        letter-spacing: 0; }
      .stage-perpetual .trade-header .right img {
        width: 0.53333rem;
        height: 0.53333rem; }
  .stage-perpetual .p-main {
    padding: 0 0.4rem;
    flex: 1;
    display: flex;
    flex-direction: column; }
    .stage-perpetual .p-main .p-main-quoteBox {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row {
        display: flex;
        align-items: center; }
        .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info {
          display: flex;
          align-items: center;
          margin-left: 0.26667rem; }
          .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-name {
            font-size: 0.53333rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 600;
            color: var(--mainFont); }
          .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-percent {
            font-size: 0.4rem;
            font-family: DIN, DIN-Medium;
            font-weight: 500;
            margin-left: 0.53333rem; }
          .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-percent.green {
            color: var(--f1); }
          .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image-info .p-main-quoteBox-row-image-info-percent.red {
            color: var(--r1); }
        .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image {
          width: 0.42667rem;
          height: 0.42667rem;
          fill: var(--mainFont); }
          .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image.back {
            margin-right: 0.32rem;
            width: 0.50667rem;
            height: 0.4rem;
            display: flex; }
          .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image svg, .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row .p-main-quoteBox-row-image img {
            width: 100%; }
      .stage-perpetual .p-main .p-main-quoteBox .p-main-quoteBox-row:last-child .p-main-quoteBox-row-image {
        margin-left: 0.32rem; }
    .stage-perpetual .p-main .p-main-desc {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      margin-left: 0.69333rem;
      color: var(--mainFont); }
    .stage-perpetual .p-main .p-main-tradeBox {
      display: flex; }
      .stage-perpetual .p-main .p-main-tradeBox .p-main-tradeBox-depthBox {
        flex: 4;
        margin-right: 0.21333rem; }
      .stage-perpetual .p-main .p-main-tradeBox .p-main-tradeBox-orderBox {
        flex: 6; }
    .stage-perpetual .p-main .p-main-positionBox {
      overflow: hidden;
      flex: 1;
      display: flex; }
  .stage-perpetual .p-drawer-wrapper {
    position: relative; }

.alert-agreementBox .ab-title {
  font-size: 0.48rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: var(--active);
  text-align: left; }

.alert-agreementBox .ab-desc {
  font-size: 0.37333rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: var(--mainFont);
  margin-top: 0.66667rem;
  text-align: left; }

.alert-agreementBox .ab-buttonBox {
  display: flex;
  justify-content: space-between; }
  .alert-agreementBox .ab-buttonBox div {
    width: 3.2rem; }

.alert-agreementBox .ab-btn {
  font-size: 0.42667rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  background: var(--active);
  border-radius: 3px;
  color: #141416;
  margin-top: 0.66667rem;
  height: 1.06667rem;
  line-height: 1.06667rem;
  text-align: center; }
  .alert-agreementBox .ab-btn.cancel {
    background: var(--bg18);
    color: var(--mainFont); }
