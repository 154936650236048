@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-SLSPView {
  background: var(--bg16);
  border-radius: 0.64rem 0.64rem 0px 0px;
  padding: 0 0.4rem 0.53333rem; }
  .module-SLSPView .slspv-header {
    height: 1.49333rem;
    line-height: 1.49333rem;
    text-align: center;
    border-bottom: 1px solid var(--border3);
    position: relative; }
    .module-SLSPView .slspv-header .slspv-header-title {
      font-size: 0.42667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: var(--mainFont); }
    .module-SLSPView .slspv-header .slspv-header-close {
      position: absolute;
      right: 0.4rem;
      top: 0.53333rem;
      fill: #777E90; }
  .module-SLSPView .slspv-descBox {
    display: flex;
    background: rgba(255, 209, 64, 0.1);
    border-radius: 0.10667rem;
    padding: 0.26667rem 0.4rem;
    margin-top: 0.53333rem; }
    .module-SLSPView .slspv-descBox .slspv-descBox-image {
      width: 0.37333rem;
      height: 0.37333rem; }
      .module-SLSPView .slspv-descBox .slspv-descBox-image svg {
        width: 100%; }
    .module-SLSPView .slspv-descBox .slspv-descBox-desc {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: var(--active);
      line-height: 0.4rem;
      margin-left: 0.26667rem; }
  .module-SLSPView .slspv-infoBox {
    display: flex;
    justify-content: space-between;
    margin-top: 0.4rem; }
    .module-SLSPView .slspv-infoBox .slspv-infoBox-title {
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: var(--mainFont10); }
    .module-SLSPView .slspv-infoBox .slspv-infoBox-content {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500; }
  .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox {
    display: flex;
    margin-top: 0.4rem; }
    .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox {
      flex: 6;
      margin-right: 0.4rem; }
      .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-title {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: var(--mainFont10); }
      .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-input {
        background: var(--bg18);
        height: 1.01333rem;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;
        border-radius: 0.10667rem;
        margin-top: 0.26667rem; }
        .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-input .slspv-inputDataBox-configBox-priceBox-input-text {
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: var(--mainFont10);
          flex: 1; }
        .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-input input {
          background: transparent;
          border: 0;
          flex: 1;
          color: var(--mainFont);
          font-family: 'DIN,DIN-Medium'; }
        .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-input input::placeholder {
          color: var(--mainFont10); }
        .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-input span {
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #777E90; }
        .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-priceBox .slspv-inputDataBox-configBox-priceBox-input .disable {
          color: var(--mainFont10); }
    .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-typeBox {
      flex: 4; }
      .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-typeBox .slspv-inputDataBox-configBox-typeBox-title {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: var(--mainFont10); }
      .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-typeBox .slspv-inputDataBox-configBox-typeBox-dd {
        margin-top: 0.26667rem; }
        .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-typeBox .slspv-inputDataBox-configBox-typeBox-dd .dv-option {
          justify-content: flex-start; }
          .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox .slspv-inputDataBox-configBox-typeBox .slspv-inputDataBox-configBox-typeBox-dd .dv-option .dv-option-selected {
            margin-left: 0.26667rem; }
  .module-SLSPView .slspv-inputDataBox .slspv-inputDataBox-configBox-desc {
    margin-top: 0.26667rem;
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: var(--mainFont10); }
  .module-SLSPView .slspv-submit {
    font-size: 0.42667rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    height: 1.06667rem;
    color: #141416;
    background-color: var(--active);
    border-radius: 0.08rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.53333rem; }
  .module-SLSPView .red {
    color: var(--r1); }
  .module-SLSPView .green {
    color: var(--f1); }
  .module-SLSPView .white {
    color: var(--mainFont); }
