@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

html,
body,
#root {
  height: 100%;
  font-size: 0.34667rem;
  color: var(--t2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; }

.adapt-root {
  height: calc(100vh - var(--browser-address-bar, 0px)) !important; }

.appRoot {
  position: relative; }
  .appRoot .load {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }

.safeView {
  margin-top: 1.06667rem;
  position: relative;
  height: 100%; }

.app-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* bottom: -1px; */
  /* transform: translateY(-1px); */
  opacity: 0.999;
  overflow-y: scroll; }

body::-webkit-scrollbar {
  display: none; }

body,
a {
  color: var(--t2); }
  body a:visited,
  a a:visited {
    color: var(--t2); }

#root > div {
  height: 100vh;
  overflow-y: scroll; }

body {
  background-color: var(--bg1); }

.am-navbar {
  background-color: var(--bg1);
  color: var(--t1);
  width: 100%; }

.active {
  color: var(--active); }

.form {
  margin-left: 0.66667rem;
  margin-right: 0.66667rem; }
  .form svg {
    font-size: 0.42667rem; }
  .form h3 {
    color: var(--t1);
    font-size: 0.64rem;
    font-weight: normal;
    margin-top: 1.06667rem;
    margin-bottom: 1.06667rem; }
  .form .ipt {
    margin-top: 0.53333rem; }
  .form .close svg {
    fill: var(--active); }
  .form .btn {
    padding: 0;
    margin: 0;
    line-height: 1.33333rem;
    background-color: var(--active);
    border-radius: 0.05333rem;
    width: 100%;
    color: var(--bg1);
    font-size: 0.45333rem;
    border: 0 none; }
  .form .tip {
    text-align: right;
    line-height: 1.33333rem; }
    .form .tip a {
      color: var(--t2); }
  .form .mobile-icon {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form .mobile-icon span {
      margin-right: 0.13333rem; }
    .form .mobile-icon span:nth-child(3) {
      border-right: 1px solid var(--t2);
      padding-right: 0.21333rem;
      line-height: 0.37333rem;
      height: 0.37333rem; }
      .form .mobile-icon span:nth-child(3) svg {
        font-size: 0.26667rem; }

.toggle {
  position: absolute;
  width: 100%;
  bottom: 0.8rem;
  text-align: center; }
  .toggle span {
    color: var(--active); }

.am-navbar-title {
  color: var(--f5);
  font-weight: bold;
  font-size: 0.48rem; }

.am-stepper-input-wrap {
  height: 1.12rem; }

.am-stepper {
  height: 1.12rem; }

.am-stepper-handler, .am-stepper-handler-up-inner, .am-stepper-handler-down-inner {
  height: 1.12rem;
  width: 1.12rem;
  line-height: 1.12rem; }

.am-navbar-right {
  font-size: 0.4rem; }

.am-tabs-default-bar {
  background-color: transparent !important; }

.am-tabs-default-bar-tab {
  color: var(--t1); }

.am-tabs-default-bar-tab-active {
  color: var(--active) !important; }

.am-tabs-default-bar-underline {
  display: none !important; }

.am-tabs-default-bar-tab::after {
  width: 20% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: transparent !important; }

.am-tabs-default-bar-tab-active::after {
  background-color: var(--active) !important;
  height: 2px !important; }

.ant-switch-checked {
  background-color: var(--active); }

.am-picker-col-mask {
  background-image: linear-gradient(to bottom, rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.95), rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.6)), linear-gradient(to top, rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.95), rgba(var(--night-mask-black), var(--night-mask-black), var(--night-mask-black), 0.6)) !important; }

.am-picker-popup-item {
  color: var(--mainFont) !important; }

.am-picker-col {
  background-color: var(--bg2); }

.am-picker-col-item {
  color: var(--mainFont) !important;
  font-family: 'DIN,DIN-Medium'; }

.am-list-item {
  padding-left: 0 !important; }

.am-list-item .am-input-label.am-input-label-5 {
  margin-right: 0.26667rem; }

.am-accordion-content-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.am-modal-mask {
  z-index: 100; }

.am-modal-wrap {
  z-index: 100; }

.action {
  position: relative; }
  .action .loading {
    position: absolute;
    left: 35%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); }

.toggle {
  position: absolute;
  width: 100%;
  bottom: 0.8rem;
  text-align: center; }

.ml5 {
  margin-left: 0.13333rem; }

.ml10 {
  margin-left: 0.26667rem; }

.ml15 {
  margin-left: 0.4rem; }

.mt5 {
  margin-top: 0.13333rem; }

.mt10 {
  margin-top: 0.26667rem; }

.mt15 {
  margin-top: 0.4rem; }

.mr5 {
  margin-right: 0.13333rem; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

p {
  margin: 0; }

i, em {
  font-style: normal; }

::-webkit-scrollbar {
  display: none; }

:global .am-action-sheet-button-list .am-action-sheet-destructive-button {
  background-color: var(--fff-bg); }

:global .am-action-sheet-button-list .am-action-sheet-button-list-item {
  background-color: var(--fff-bg);
  height: 1.70667rem;
  line-height: 1.70667rem;
  font-size: 0.42667rem;
  overflow: hidden; }

:global .am-action-sheet-button-list .am-action-sheet-cancel-button {
  color: var(--t2); }

:global .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
  background: var(--t5) !important; }

:global .am-list-body::before, :global .am-list-body::after {
  height: 0 !important; }

:global .fiatCell {
  padding-left: 0.26667rem; }

:global .am-action-sheet {
  background: var(--active) !important; }

:global .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
  background: #1e2c37 !important; }

:global .am-action-sheet {
  background: var(--active) !important; }

:global .am-action-sheet-button-list .am-action-sheet-cancel-button-mask {
  background: #1e2c37 !important; }

:global .my-radio .am-radio {
  padding: 2.5px;
  border: 1px solid var(--t5);
  border-radius: 50%;
  margin-right: 0.13333rem;
  color: var(--f9);
  line-height: 0.37333rem; }

:global .am-list-body::before,
:global .am-list-body::after {
  background-color: var(--line-bg) !important; }

:global .react-dropdown-select-content {
  color: var(--mainFont); }

:global .react-dropdown-select-dropdown {
  width: 100% !important;
  top: 0.93333rem !important;
  border: none !important; }

:global .fiatCell {
  height: 0.96rem;
  display: flex;
  align-items: center;
  background-color: var(--bg2);
  color: #858e9d; }

:global .fiatCellActive {
  color: var(--active); }

:global(.am-list-view-scrollview-content) {
  position: relative !important; }

:global(.am-picker-popup-header) {
  background-color: var(--bg2); }
  :global(.am-picker-popup-header)::after {
    background-color: var(--line-bg) !important; }

:global(.am-picker-col-indicator)::before, :global(.am-picker-col-indicator)::after {
  background-color: var(--line-bg) !important; }

:global(.am-accordion .am-accordion-item .am-accordion-header) {
  font-size: 0.4rem;
  font-weight: 500;
  background-color: var(--fff-bg);
  height: 1.54667rem;
  line-height: 1.54667rem;
  color: var(--f5); }
  :global(.am-accordion .am-accordion-item .am-accordion-header)::after {
    background-color: var(--input-bg) !important; }

:global(.am-accordion .am-accordion-item .am-accordion-header i) {
  top: 20px; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box) {
  font-size: 0.4rem;
  font-weight: 400;
  background-color: var(--night-bg2, #f5f5f5);
  line-height: 24px;
  color: var(--f6); }
  :global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box)::before {
    background-color: var(--input-bg) !important; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraph) {
  text-align: left;
  width: 100%;
  font-size: 0.4rem;
  line-height: 24px;
  color: var(--f6); }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraphTitle) {
  text-align: left;
  width: 100%;
  font-size: 0.4rem;
  line-height: 24px;
  color: var(--f6);
  margin-top: 0.4rem; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraph2) {
  text-align: left;
  width: 100%;
  font-size: 0.34667rem;
  line-height: 24px;
  color: var(--f6);
  margin-top: 0.4rem;
  text-align: left; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .tableLine) {
  width: 100%;
  height: 0.4rem; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .paragraph3) {
  text-align: left;
  width: 100%;
  font-size: 0.4rem;
  line-height: 24px;
  color: var(--f6);
  margin: 0.4rem 0; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box table) {
  width: 100%; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box table tr td) {
  font-size: 0.32rem;
  border: 1px solid var(--t5); }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box table tr .center) {
  text-align: center; }

:global(.am-list-body) {
  background-color: var(--bg1); }

:global(.am-accordion .am-accordion-item .am-accordion-header) {
  color: var(--mainFont);
  font-weight: 500 !important; }

:global(.am-list-body)::before {
  background-color: var(--line-bg) !important; }

:global(.am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.select-down) {
  margin-left: -0.13333rem; }

:global(.slider-frame) {
  border-radius: 0.13333rem; }

:global(.am-stepper) {
  height: 1.17333rem; }

:global(.am-stepper-input-wrap) {
  height: 1.17333rem !important;
  border: 0 !important; }

:global(.am-stepper-handler) {
  border: 0; }

:global(.am-stepper-handler-up) {
  width: 1.12rem !important;
  height: 1.12rem !important;
  color: transparent !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url("../assets/svg/newIcon/handler-up.svg");
  background-size: cover; }

:global(.am-stepper-handler-down) {
  width: 1.12rem !important;
  height: 1.12rem !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: transparent !important;
  background: url("../assets/svg/newIcon/handler-down.svg");
  background-size: cover; }

:global(.am-accordion .am-accordion-item .am-accordion-header) {
  font-size: 0.4rem;
  font-weight: bold;
  background-color: var(--fff-bg);
  height: 1.54667rem;
  line-height: 1.54667rem;
  color: var(--mainFont); }
  :global(.am-accordion .am-accordion-item .am-accordion-header)::after {
    background-color: var(--night-line-bg, #ddd) !important; }

:global(.am-accordion .am-accordion-item .am-accordion-header i) {
  top: 20px; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box) {
  font-size: 0.37333rem;
  font-weight: 400;
  color: var(--f6);
  background-color: var(--night-bg2, #f5f5f5);
  text-align: left; }
  :global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box) p {
    text-align: left; }
  :global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box):after {
    background-color: var(--night-line-bg, #f5f5f5) !important; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box p) {
  text-align: left;
  width: 100%;
  line-height: 24px;
  color: var(--f6);
  text-indent: 2em; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .title) {
  text-align: left;
  width: 100%;
  line-height: 0.53333rem;
  margin: 0.26667rem 0; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .title2) {
  text-align: left;
  width: 100%;
  line-height: 0.53333rem; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .title3) {
  text-align: left;
  width: 100%;
  line-height: 0.53333rem;
  text-indent: 2em; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box .li) {
  text-align: left;
  width: 100%;
  line-height: 0.66667rem; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box span) {
  width: 350px;
  margin: 0.53333rem auto; }

:global(.am-accordion .am-accordion-item .am-accordion-content .am-accordion-content-box span img) {
  width: 100%; }

:global(.am-accordion)::before {
  background-color: var(--line-bg) !important; }

:global(.am-stepper) {
  height: 1.17333rem; }

:global(.am-stepper-input-wrap) {
  height: 1.17333rem !important;
  border: 0 !important; }

:global(.am-stepper-handler) {
  border: 0; }

:global(.am-stepper-handler-up) {
  width: 1.12rem !important;
  height: 1.12rem !important;
  color: transparent !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: url("../assets/svg/newIcon/handler-up.svg");
  background-size: cover; }

:global(.am-stepper-handler-down) {
  width: 1.12rem !important;
  height: 1.12rem !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: transparent !important;
  background: url("../assets/svg/newIcon/handler-down.svg");
  background-size: cover; }

:global(.am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.am-checkbox.am-checkbox-checked .am-checkbox-inner) {
  border: 2px solid white;
  border-radius: 4px; }

:global(.content) {
  padding: 0; }

:global(.am-list-body) {
  background-color: var(--bg1); }

:global(.slider-slide span) {
  font-weight: bold; }

:global(.am-popover .am-popover-item) {
  background-color: var(--bg2);
  color: var(--mainFont); }

:global(.am-popover .am-popover-item.am-popover-item-active) {
  background-color: var(--bg2) !important;
  color: var(--mainFont); }

:global(.right) :global(a) :global(.C-Svg) :global(svg) :global(#gq) {
  fill: var(--night-font4, #202020); }

:global(.center) {
  font-size: 0.37333rem; }

:global(.select-down) {
  font-size: 0.37333rem;
  font-weight: 400;
  margin-left: -0.13333rem; }

:global(.am-popover-inner-wrapper) {
  background-color: var(--bg2) !important; }

:global(.am-popover-arrow) {
  background-color: var(--bg2) !important; }

.module-header .center .bskTabs {
  font-size: 0.37333rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.05333rem;
  font-weight: normal; }
  .module-header .center .bskTabs .tabItem {
    padding: 0.10667rem 0.29333rem;
    text-align: center;
    background-color: var(--night-bg3, #E5E5E5);
    color: var(--f6); }
    .module-header .center .bskTabs .tabItem.tabItemActive {
      background: var(--active);
      color: var(--t6); }

@keyframes alertAni {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  z-index: 99; }
  #alert div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    font-size: 0.37333rem;
    font-weight: 400;
    background-color: yellow;
    border-radius: 0.10667rem;
    text-align: center;
    animation: alertAni 500ms; }

@media only screen and (max-width: 1080px), only screen and (max-device-width: 1080px) {
  html,
  body {
    font-size: 108px !important; } }

@media only screen and (max-width: 960px), only screen and (max-device-width: 960px) {
  html,
  body {
    font-size: 96px !important; } }

@media only screen and (max-width: 750px), only screen and (max-device-width: 750px) {
  html,
  body {
    font-size: 75px !important; } }

@media only screen and (max-width: 640px), only screen and (max-device-width: 640px) {
  html,
  body {
    font-size: 64px !important; } }

@media only screen and (max-width: 600px), only screen and (max-device-width: 600px) {
  html,
  body {
    font-size: 60px !important; } }

@media only screen and (max-width: 540px), only screen and (max-device-width: 540px) {
  html,
  body {
    font-size: 54px !important; } }

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  html,
  body {
    font-size: 48px !important; } }

@media only screen and (max-width: 428px), only screen and (max-device-width: 428px) {
  html,
  body {
    font-size: 42.8px !important; } }

@media only screen and (max-width: 414px), only screen and (max-device-width: 414px) {
  html,
  body {
    font-size: 41.4px !important; } }

@media only screen and (max-width: 400px), only screen and (max-device-width: 400px) {
  html,
  body {
    font-size: 40px !important; } }

@media only screen and (max-width: 390px), only screen and (max-device-width: 390px) {
  html,
  body {
    font-size: 39px !important; } }

@media only screen and (max-width: 375px), only screen and (max-device-width: 375px) {
  html,
  body {
    font-size: 37.5px !important; } }

@media only screen and (max-width: 360px), only screen and (max-device-width: 360px) {
  html,
  body {
    font-size: 36px !important; } }

@media only screen and (max-width: 320px), only screen and (max-device-width: 320px) {
  html,
  body {
    font-size: 32px !important; } }

@media only screen and (max-width: 240px), only screen and (max-device-width: 240px) {
  html,
  body {
    font-size: 24px !important; } }

:root {
  --active: #ffd140;
  --nActive: #ffb628;
  --nextActive: #48CDEE;
  --fff: #ffffff;
  --fff-bg: var(--night-bg2, #ffffff);
  --t1: #d8d8d8;
  --t2: #666666;
  --t3: #b6bdc6;
  --t4: #eeeff0;
  --t5: #cccccc;
  --t6: #101010;
  --t7: #b6bcc7;
  --t8: var(--night-bg1, #ebecf0);
  --t9: var(--night-placeHolder, #b6bcc7);
  --t10: var(--night-font9, #b6bcc7);
  --t11: #777E90;
  --r1: var(--color-fall);
  --f1: var(--color-raise);
  --f2: #314134;
  --f3: #2bad66;
  --f4: #9fb0c8;
  --f5: var(--night-font1, #333333);
  --f6: #848e9c;
  --f7: #000000;
  --f8: var(--night-line-bg, #f7f7f7);
  --f9: #999999;
  --f10: #a0acbc;
  --f11: #b5b5b5;
  --f12: #1b262d;
  --f13: #353d49;
  --f14: #353945;
  --f15: var(--night-font1, #fff);
  --f16: #B6BCC7;
  --ft: var(--night-ft, rgba(0, 0, 0, 0.04));
  --br: var(--night-bg2, #e8eaef);
  --bg1: var(--night-bg3, #f7f7f7);
  --bg2: var(--night-bg2, #ffffff);
  --bg3: var(--night-bg3, #fff);
  --bg12: var(--night-bg12, #fff);
  --bg13: var(--night-bg12, #f8f9fc);
  --bg14: var(--night-bg13, #fff);
  --line-bg: var(--night-line-bg, #eeeff2);
  --line-bg2: var(--night-line-kuang, #ebecf0);
  --line-bg3: var(--night-bg3, #ffffff);
  --line-bg4: var(--night-bg13, #eeeff2);
  --line-bg5: var(--night-font10, #eeeff2);
  --line-bg6: var(--night-font9, #b6bcc7);
  --foot-bg: var(--night-foot, #ffffff);
  --input-bg: var(--night-inputBg, #eeeeee);
  --input-bg1: var(--night-inputBg, #ffffff);
  --order-bg: var(--night-inputBg, #fef6e3);
  --search-input-bg: var(--night-inputBg, #ebecf0);
  --gray1: var(--night-gray1, #e6e8ec);
  --bg4: #688494;
  --bg5: #5891fa;
  --bg6: var(--night-line-bg, #eeeff2);
  --bg7: #dfeaff;
  --bg9: var(--night-inputBg, #f4f5f7);
  --bg10: var(--night-bg1, #f8f8f8);
  --bg11: var(--night-bg11, #fcf8ef);
  --bg15: var(--night-bg14, #f8f9fc);
  --bg16: var(--night-bg14, #fff);
  --bg17: var(--night-bg14, #eeeff2);
  --bg18: var(--night-bg15, #eeeff2);
  --l1: rgba(88, 113, 144, 0.3);
  --switchBg: var(--night-switchBg, #e9e9eb);
  --bar: var(--night-bg13, #eeeff2);
  --disBtn-bg: var(--night-dis-btn, #ebecf0);
  --mainFont: var(--night-font1, #1e2129);
  --mainFont2: var(--night-line-kuang, #ffffff);
  --mainFont3: var(--night-font4, #858e9d);
  --mainFont4: var(--night-font6, #1e2129);
  --mainFont5: var(--night-font6, #777e90);
  --mainFont6: var(--night-font7, #1e2129);
  --mainFont7: var(--night-font6, #1e2129);
  --mainFont8: var(--night-font6, #1c1e24);
  --mainFont9: var(--night-font9, #848e9c);
  --mainFont10: var(--night-font9, #b6bcc7);
  --mainFontActive: var(--night-font1, #1e2129);
  --mainFontTxt: var(--night-font6, #1c1e24);
  --tradePlaceHolder: var(--night-border1, #b6bcc7);
  --tradeClose: var(--night-closeColor, #b6bcc7);
  --headerFont: var(--night-font2, #000);
  --fc2: var(--night-font3, #333);
  --fc5: var(--night-font9, #b6bcc7);
  --fc6: #f0f0f0;
  --lc1: #f7f7f7;
  --lc2: #eef1fb;
  --lc3: #f7f7f7;
  --lc4: #2f3d45;
  --lc5: #202b3f;
  --lc6: #222d48;
  --rb: var(--night-rb, #feecee);
  --gb: var(--night-gb, #e9f8f2);
  --border1: var(--night-border1, #f2f3f5);
  --border2: var(--night-border1, #b6bcc7);
  --border3: var(--night-border1, #eeeff2);
  --border-light: #F8F9FC;
  --blue: #4677d7;
  --ma5: #4677d7;
  --ma10: #f69c6a;
  --ma30: #cd85ff;
  --btn-active-color: #141416;
  --tab-bar-color: var(--night-tabBar, #EEEFF2);
  --pMFont: var(--font,'PingFangSC,PingFangSC-Medium');
  --pRFont: var(--font2,'PingFangSC,PingFangSC-Regular');
  --pSFont: var(--font3,'PingFangSC,PingFangSC-Semibold');
  --light-primary: #1E2129;
  --light-bg: #F8F9FC;
  --light-secondary: #EEEFF2;
  --pbg1: #22252E;
  --pbg2: #121212;
  --pbg3: #1B1E24;
  --pbg4: #272A34;
  --pbg5: #2F3038;
  --pbg6: #777D90;
  --pbg7: #1E2128;
  --pFont1: #777D90;
  --pFont2: #141416;
  --pFont3: #343844;
  --pFont4: #E6E7EC;
  --pFont5: #fff;
  --pFont6: #131316;
  --pFont7: #4E5767;
  --pLine1: #1E2128;
  --pLine2: #23262F;
  --pLine3: #121212;
  --pLine4: #777D90; }

@font-face {
  font-family: "DIN,DIN-Bold";
  src: url("./DIN-Bold.otf");
  font-weight: 700;
  font-display: swap; }

@font-face {
  font-family: "DIN,DIN-Medium";
  src: url("./DIN-Medium.otf");
  font-weight: 500;
  font-display: swap; }

@font-face {
  font-family: "DIN,DIN-Regular";
  src: url("./DIN-Regular.otf");
  font-weight: 100;
  font-display: swap; }

@font-face {
  font-family: "IBMPlexSans-Bold";
  src: url("./IBMPlexSans-Bold.ttf");
  font-weight: 700;
  font-display: swap; }

@font-face {
  font-family: "IBMPlexSans-Medium";
  src: url("./IBMPlexSans-Medium.ttf");
  font-weight: 500;
  font-display: swap; }

@font-face {
  font-family: "IBMPlexSans-Regular";
  src: url("./IBMPlexSans-Regular.ttf");
  font-weight: 100;
  font-display: swap; }

body {
  font-family: "PingFangSC-Regular,PingFang SC";
  height: 100vh;
  display: flex;
  flex-direction: column; }

.adapt-body {
  height: calc(100vh - var(--browser-address-bar, 0px)); }
