@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-createPrediction {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--pbg2); }
  .stage-createPrediction .cp-main {
    flex: 1;
    overflow: auto; }
    .stage-createPrediction .cp-main .cp-singeBox {
      padding: 0 0.4rem;
      margin-top: 0.4rem; }
      .stage-createPrediction .cp-main .cp-singeBox .cp-singeBox-title {
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1);
        font-size: 0.37333rem; }
      .stage-createPrediction .cp-main .cp-singeBox .cp-singeBox-row {
        display: flex;
        margin-top: 0.2rem; }
        .stage-createPrediction .cp-main .cp-singeBox .cp-singeBox-row .cp-singeBox-row-btn {
          flex: 1;
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont1);
          background: var(--pbg1);
          font-size: 0.37333rem;
          margin-right: 0.26667rem;
          height: 0.90667rem;
          line-height: 0.90667rem;
          text-align: center; }
          .stage-createPrediction .cp-main .cp-singeBox .cp-singeBox-row .cp-singeBox-row-btn.active {
            color: var(--pFont2);
            background: var(--active); }
          .stage-createPrediction .cp-main .cp-singeBox .cp-singeBox-row .cp-singeBox-row-btn:last-child {
            margin-right: 0; }
    .stage-createPrediction .cp-main .cp-tagBox {
      padding: 0 0.4rem;
      margin-top: 0.4rem; }
      .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-row {
        display: flex;
        justify-content: space-between; }
        .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-row .cp-tagBox-chooseBox-row-title {
          color: var(--pFont1);
          font-size: 0.37333rem;
          font-family: 'DIN,DIN-Medium'; }
        .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-row .cp-tagBox-chooseBox-row-btn {
          display: flex; }
          .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-row .cp-tagBox-chooseBox-row-btn .cp-tagBox-chooseBox-row-btn-text {
            color: var(--active);
            font-size: 0.37333rem;
            font-family: 'DIN,DIN-Medium'; }
          .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-row .cp-tagBox-chooseBox-row-btn .cp-tagBox-chooseBox-row-btn-image {
            fill: var(--active);
            margin-left: 0.26667rem;
            width: 0.18667rem; }
            .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-row .cp-tagBox-chooseBox-row-btn .cp-tagBox-chooseBox-row-btn-image svg {
              width: 100%; }
      .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-chooseBox .cp-tagBox-chooseBox-desc {
        color: var(--pFont1);
        font-size: 0.26667rem;
        font-family: 'DIN,DIN-Medium'; }
      .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-customBox .cp-tagBox-customBox-desc {
        font-family: 'DIN,DIN-Medium';
        font-size: 0.26667rem;
        color: var(--pFont1); }
      .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-customBox .cp-tagBox-customBox-inputBox {
        height: 0.82667rem;
        background: var(--pbg1);
        margin-top: 0.2rem;
        padding: 0 0.4rem; }
        .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-customBox .cp-tagBox-customBox-inputBox input {
          height: 100%;
          width: 100%;
          background: transparent;
          border: 0;
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont4); }
          .stage-createPrediction .cp-main .cp-tagBox .cp-tagBox-customBox .cp-tagBox-customBox-inputBox input::placeholder {
            color: var(--pFont3); }
    .stage-createPrediction .cp-main .cp-composeBox {
      padding: 0 0.4rem; }
      .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.13333rem; }
        .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox .cp-titleBox-title {
          font-size: 0.37333rem;
          color: var(--pFont1);
          font-family: 'DIN,DIN-Medium'; }
          .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox .cp-titleBox-title span {
            font-size: 0.26667rem;
            margin-left: 0.13333rem; }
        .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox .cp-titleBox-choose {
          display: flex; }
          .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox .cp-titleBox-choose .cp-titleBox-choose-text {
            font-size: 0.37333rem;
            color: var(--active);
            font-family: 'DIN,DIN-Medium'; }
          .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox .cp-titleBox-choose .cp-titleBox-choose-image {
            fill: var(--active);
            width: 0.18667rem;
            margin-left: 0.2rem; }
            .stage-createPrediction .cp-main .cp-composeBox .cp-titleBox .cp-titleBox-choose .cp-titleBox-choose-image svg {
              width: 100%; }
    .stage-createPrediction .cp-main .cp-amountBox {
      padding: 0 0.4rem;
      margin-top: 0.4rem; }
      .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-row {
        display: flex;
        justify-content: space-between; }
        .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-row .cp-amountBox-row-title {
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont1); }
        .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-row .cp-amountBox-row-min {
          font-family: 'DIN,DIN-Medium';
          color: var(--pFont1); }
      .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount {
        display: flex;
        background: var(--pbg1);
        margin-top: 0.2rem;
        height: 0.90667rem; }
        .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount .cp-amountBox-amount-btn {
          display: flex;
          align-items: center; }
          .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount .cp-amountBox-amount-btn:first-child {
            margin-left: 0.4rem; }
          .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount .cp-amountBox-amount-btn:last-child {
            margin-right: 0.4rem; }
        .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount .cp-amountBox-amount-inputBox {
          height: 100%;
          flex: 1; }
          .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount .cp-amountBox-amount-inputBox input {
            background: transparent;
            border: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            font-family: 'DIN,DIN-Medium';
            font-size: 0.37333rem;
            color: var(--pFont4); }
            .stage-createPrediction .cp-main .cp-amountBox .cp-amountBox-amount .cp-amountBox-amount-inputBox input::placeholder {
              color: var(--pFont3); }
    .stage-createPrediction .cp-main .cp-dateBox {
      padding: 0 0.4rem;
      margin-top: 0.4rem; }
      .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-title {
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1);
        font-size: 0.37333rem; }
      .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row {
        display: flex;
        margin-top: 0.2rem; }
        .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row .cp-dateBox-row-dateBtn {
          height: 0.90667rem;
          line-height: 0.90667rem;
          background: var(--pbg1);
          flex: 1;
          margin-right: 0.26667rem;
          padding: 0 0.4rem; }
          .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row .cp-dateBox-row-dateBtn .cp-dateBox-dateBtn-row-text {
            font-family: 'DIN,DIN-Medium';
            color: var(--pFont4);
            font-size: 0.37333rem;
            margin-right: 0.13333rem; }
            .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row .cp-dateBox-row-dateBtn .cp-dateBox-dateBtn-row-text.placeholder {
              color: var(--pFont3); }
          .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row .cp-dateBox-row-dateBtn .cp-dateBox-dateBtn-row-image {
            width: 0.33333rem; }
            .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row .cp-dateBox-row-dateBtn .cp-dateBox-dateBtn-row-image img {
              width: 100%; }
          .stage-createPrediction .cp-main .cp-dateBox .cp-dateBox-row .cp-dateBox-row-dateBtn:last-child {
            margin-right: 0rem; }
    .stage-createPrediction .cp-main .cp-sourceBox {
      padding: 0 0.4rem;
      margin-top: 0.4rem; }
      .stage-createPrediction .cp-main .cp-sourceBox .cp-sourceBox-title {
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1);
        font-size: 0.37333rem; }
      .stage-createPrediction .cp-main .cp-sourceBox .cp-sourceBox-inputBox {
        background: var(--pbg1);
        margin-top: 0.2rem;
        padding: 0 0.4rem; }
        .stage-createPrediction .cp-main .cp-sourceBox .cp-sourceBox-inputBox input {
          height: 0.90667rem;
          background: transparent;
          border: none;
          color: var(--pFont4);
          width: 100%;
          font-family: 'DIN,DIN-Medium';
          font-size: 0.37333rem; }
          .stage-createPrediction .cp-main .cp-sourceBox .cp-sourceBox-inputBox input::placeholder {
            color: var(--pFont3); }
    .stage-createPrediction .cp-main .cp-totalBox {
      display: flex;
      justify-content: space-between;
      margin: 0 0.4rem;
      border-top: 1px solid var(--pLine2);
      margin-top: 0.4rem; }
      .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-left {
        display: flex;
        align-items: flex-end; }
        .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-left .cp-totalBox-left-deposit {
          color: var(--active);
          font-family: 'DIN,DIN-Medium';
          font-size: 0.37333rem; }
      .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right {
        margin-top: 0.26667rem; }
        .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right .cp-totalBox-right-block {
          display: flex;
          align-items: flex-end;
          justify-content: space-between; }
          .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right .cp-totalBox-right-block .cp-totalBox-right-block-title {
            color: var(--pFont1);
            font-size: 0.26667rem;
            font-family: 'DIN,DIN-Medium';
            margin-right: 0.26667rem; }
          .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right .cp-totalBox-right-block .cp-totalBox-right-block-content {
            display: flex;
            font-family: 'DIN,DIN-Medium';
            color: var(--pFont4); }
            .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right .cp-totalBox-right-block .cp-totalBox-right-block-content.balance {
              color: var(--r1); }
            .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right .cp-totalBox-right-block .cp-totalBox-right-block-content span {
              width: 0.32rem;
              display: block;
              margin-left: 0.26667rem; }
              .stage-createPrediction .cp-main .cp-totalBox .cp-totalBox-right .cp-totalBox-right-block .cp-totalBox-right-block-content span img {
                width: 100%; }
    .stage-createPrediction .cp-main .cp-optionBox {
      padding: 0 0.4rem;
      margin-top: 0.32rem; }
      .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-top {
        display: flex;
        justify-content: space-between; }
        .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-top .cp-optionBox-top-title {
          font-size: 0.37333rem;
          color: var(--pFont1);
          font-family: 'DIN,DIN-Medium'; }
        .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-top .cp-optionBox-top-infoBox {
          display: flex; }
          .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-top .cp-optionBox-top-infoBox .cp-optionBox-top-infoBox-image {
            width: 0.32rem;
            margin-right: 0.13333rem; }
            .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-top .cp-optionBox-top-infoBox .cp-optionBox-top-infoBox-image img {
              width: 100%; }
          .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-top .cp-optionBox-top-infoBox .cp-optionBox-top-infoBox-text {
            font-size: 0.37333rem;
            color: var(--pFont1);
            font-family: 'DIN,DIN-Medium'; }
      .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-bottom {
        display: flex;
        margin-top: 0.26667rem; }
        .stage-createPrediction .cp-main .cp-optionBox .cp-optionBox-bottom .cp-optionBox-bottom-add {
          font-size: 0.37333rem;
          color: var(--pFont1);
          font-family: 'DIN,DIN-Medium';
          background: var(--pbg1);
          padding: 0.18667rem 0.48rem;
          border-radius: 0.04rem; }
    .stage-createPrediction .cp-main .cp-buttonRow {
      display: flex;
      margin-top: 0.66667rem;
      margin-bottom: 0.53333rem;
      padding: 0 0.4rem; }
      .stage-createPrediction .cp-main .cp-buttonRow .cp-submit {
        border-radius: 3px;
        height: 0.90667rem;
        line-height: 0.90667rem;
        text-align: center;
        background: var(--active);
        font-family: 'DIN,DIN-Medium';
        font-size: 0.37333rem;
        color: var(--pFont2);
        flex: 1;
        margin-left: 0.13333rem; }
      .stage-createPrediction .cp-main .cp-buttonRow .cp-save {
        background: var(--pbg1);
        color: var(--pFont1);
        font-family: 'DIN,DIN-Medium';
        font-size: 0.37333rem;
        flex: 1;
        margin-right: 0.13333rem;
        height: 0.90667rem;
        line-height: 0.90667rem;
        text-align: center; }
    .stage-createPrediction .cp-main .sc-info {
      display: inline-block;
      margin-left: 0.13333rem;
      margin-right: 0.13333rem; }
      .stage-createPrediction .cp-main .sc-info.special {
        margin-left: -0.45333rem; }

.systemTagModal .am-modal-content {
  background: transparent;
  position: relative; }

.puzzle-tips {
  background: var(--pbg3);
  margin: auto;
  width: 90%;
  min-height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.26667rem; }
  .puzzle-tips .pt-title {
    height: 1.49333rem;
    line-height: 1.49333rem;
    font-family: 'DIN,DIN-Medium';
    font-size: 0.48rem;
    color: var(--active);
    text-align: left;
    padding-left: 0.66667rem;
    border-bottom: 1px solid var(--pLine2); }
  .puzzle-tips .pt-content {
    font-size: 0.37333rem;
    font-family: 'DIN,DIN-Medium';
    color: var(--pFont4);
    word-break: break-word;
    padding: 0 0.66667rem;
    margin-top: 0.4rem; }
  .puzzle-tips .pt-btn {
    font-size: 0.37333rem;
    font-family: 'DIN,DIN-Medium';
    color: var(--pFont6);
    background: var(--active);
    height: 0.90667rem;
    border-radius: 0.04rem;
    margin: 0.4rem 0.66667rem 0.53333rem 0.66667rem;
    text-align: center;
    line-height: 0.90667rem; }

.puzzle-tag {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.4rem; }
  .puzzle-tag .pt-cellBox {
    display: flex;
    margin-top: 0.2rem;
    margin-right: 0.13333rem;
    background: var(--pbg1); }
    .puzzle-tag .pt-cellBox .pt-cellBox-cell {
      display: flex;
      align-items: center;
      padding: 0.10667rem 0.13333rem; }
      .puzzle-tag .pt-cellBox .pt-cellBox-cell .pt-cellBox-cell-image {
        width: 0.32rem;
        margin-right: 0.13333rem; }
        .puzzle-tag .pt-cellBox .pt-cellBox-cell .pt-cellBox-cell-image img {
          width: 100%; }
      .puzzle-tag .pt-cellBox .pt-cellBox-cell .pt-cellBox-cell-title {
        font-size: 0.26667rem;
        font-family: 'DIN,DIN-Medium';
        color: var(--pFont1); }
    .puzzle-tag .pt-cellBox .pt-cellBox-remove {
      width: 0.32rem;
      display: flex;
      align-items: center;
      margin-right: 0.13333rem; }
      .puzzle-tag .pt-cellBox .pt-cellBox-remove img {
        width: 100%; }

.module-createdPicker {
  background: var(--pbg3);
  margin: auto;
  width: 90%;
  min-height: 4rem;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 0.26667rem;
  overflow: hidden;
  display: flex;
  flex-direction: column; }
  .module-createdPicker .cp-title {
    height: 1.49333rem;
    line-height: 1.49333rem;
    font-family: 'DIN,DIN-Medium';
    font-size: 0.48rem;
    color: var(--active);
    text-align: left;
    padding-left: 0.66667rem;
    border-bottom: 1px solid var(--pLine2); }
  .module-createdPicker .cp-main {
    overflow: auto; }
  .module-createdPicker .cp-group {
    margin-top: 0.53333rem;
    border-bottom: 1px solid var(--pLine2); }
    .module-createdPicker .cp-group .cp-group-title {
      font-size: 0.37333rem;
      color: var(--pFont1);
      font-family: 'DIN,DIN-Medium';
      text-align: left;
      padding: 0 0.66667rem; }
    .module-createdPicker .cp-group .cp-group-list {
      margin-top: 0.4rem; }
      .module-createdPicker .cp-group .cp-group-list .cp-group-list-cellBox {
        padding: 0.26667rem 0.66667rem; }
        .module-createdPicker .cp-group .cp-group-list .cp-group-list-cellBox .cp-group-list-cellBox-cell .cp-group-list-cellBox-cell-title {
          font-size: 0.37333rem;
          color: var(--pFont4);
          font-family: 'DIN,DIN-Medium';
          text-align: left; }
        .module-createdPicker .cp-group .cp-group-list .cp-group-list-cellBox.active {
          background: var(--pbg1); }
  .module-createdPicker .cp-btnBox {
    border-top: 1px solid var(--pLine2);
    height: 1.86667rem;
    display: flex;
    align-items: center; }
    .module-createdPicker .cp-btnBox .cp-btnBox-btn {
      height: 0.90667rem;
      border-radius: 0.04rem;
      background: var(--active);
      margin: 0 0.66667rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'DIN,DIN-Medium';
      font-size: 0.42667rem;
      color: var(--pFont6);
      width: 100%; }

.puzzle-option {
  margin-top: 0.2rem; }
  .puzzle-option .o-cellBox {
    display: flex;
    margin-top: 0.26667rem; }
    .puzzle-option .o-cellBox .o-cellBox-cell {
      border-left: 0.21333rem solid;
      border-radius: 0.04rem;
      flex: 2; }
      .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc {
        background: var(--pbg1); }
        .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc:nth-child(n-1) {
          border: 1px solid var(--pFont2); }
        .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc .o-cellBox-cell-desc-row {
          display: flex;
          align-items: center;
          height: 0.90667rem; }
          .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc .o-cellBox-cell-desc-row .o-cellBox-cell-desc-row-lang span {
            font-family: 'DIN,DIN-Medium';
            font-size: 0.26667rem;
            color: var(--pFont1);
            background: var(--pbg2);
            padding: 0.05333rem 0.13333rem;
            margin-left: 0.26667rem; }
          .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc .o-cellBox-cell-desc-row .o-cellBox-cell-desc-row-inputBox {
            margin-left: 0.26667rem; }
            .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc .o-cellBox-cell-desc-row .o-cellBox-cell-desc-row-inputBox input, .puzzle-option .o-cellBox .o-cellBox-cell .o-cellBox-cell-desc .o-cellBox-cell-desc-row .o-cellBox-cell-desc-row-inputBox > div {
              background: transparent;
              border: 0;
              font-family: 'DIN,DIN-Medium';
              font-size: 0.37333rem;
              color: var(--pFont4);
              width: 100%;
              display: inline-block;
              width: 100%; }
    .puzzle-option .o-cellBox .o-cellBox-amount {
      margin-left: 0.2rem;
      flex: 1;
      background: var(--pbg1); }
      .puzzle-option .o-cellBox .o-cellBox-amount input, .puzzle-option .o-cellBox .o-cellBox-amount > div {
        background: transparent;
        border: 0;
        font-family: 'DIN,DIN-Medium';
        font-size: 0.37333rem;
        color: var(--pFont4);
        width: 100%;
        height: 100%;
        text-align: center;
        display: inline-block;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .puzzle-option .o-cellBox .o-cellBox-closeBox {
      width: 0.32rem;
      display: flex;
      margin-left: 0.26667rem; }
      .puzzle-option .o-cellBox .o-cellBox-closeBox img {
        width: 100%; }

.puzzle-compose {
  background: var(--pbg1); }
  .puzzle-compose .c-cellBox:nth-child(n-1) {
    border-bottom: 1px solid var(--pLine3); }
  .puzzle-compose .c-cellBox .c-cellBox-cell {
    display: flex; }
    .puzzle-compose .c-cellBox .c-cellBox-cell .c-cellBox-cell-langBox .c-cellBox-cell-langBox-lang {
      font-size: 0.26667rem;
      color: var(--pFont1);
      font-family: 'DIN,DIN-Medium';
      background: var(--pbg2);
      padding: 0.05333rem 0.13333rem;
      margin-left: 0.26667rem;
      margin-top: 0.26667rem; }
    .puzzle-compose .c-cellBox .c-cellBox-cell .c-cellBox-cell-textArea {
      width: 100%;
      margin-top: 0.26667rem;
      margin-left: 0.13333rem; }
      .puzzle-compose .c-cellBox .c-cellBox-cell .c-cellBox-cell-textArea textarea {
        resize: none;
        background: transparent;
        border: 0;
        width: 100%;
        font-size: 0.37333rem;
        color: var(--pFont4);
        font-family: 'DIN,DIN-Medium'; }
