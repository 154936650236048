@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.components-input {
  display: flex;
  width: 100%;
  border-radius: 0.05333rem;
  overflow: hidden;
  position: relative;
  height: 1.17333rem;
  line-height: 1.17333rem;
  align-items: center;
  border-bottom: 0.02667rem solid var(--l1); }
  .components-input strong {
    font-weight: normal; }
  .components-input i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: inherit; }
    .components-input i img,
    .components-input i svg {
      color: var(--t1);
      fill: var(--t1); }
  .components-input .b-input {
    width: 100%;
    position: relative; }
    .components-input .b-input .placeholder {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -0;
      color: var(--t2); }
    .components-input .b-input input {
      width: 100%;
      margin-left: 10px;
      background-color: transparent;
      border: 0 none;
      color: var(--t1); }
    .components-input .b-input .rightContent {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
