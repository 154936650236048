@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.depthView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; }
  .depthView .marginInfo {
    margin-bottom: 0.26667rem; }
    .depthView .marginInfo .marginTitle {
      font-size: 0.32rem;
      line-height: 0.32rem;
      font-weight: 400;
      font-family: var(--pRFont);
      display: inline;
      color: var(--mainFont10);
      border-bottom: 1px var(--mainFont10) dashed; }
    .depthView .marginInfo .timeInfo {
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 500;
      font-family: 'DIN,DIN-Medium';
      color: var(--mainFontActive);
      margin-top: 0.05333rem;
      white-space: nowrap; }
  .depthView .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.26667rem; }
    .depthView .title .titleTxt {
      font-size: 0.32rem;
      line-height: 0.32rem;
      font-weight: 400;
      font-family: var(--pRFont);
      color: var(--mainFont10); }
      .depthView .title .titleTxt:last-child {
        text-align: end; }
      .depthView .title .titleTxt p:last-child {
        margin-top: 0.16rem; }
  .depthView .depthArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
    .depthView .depthArea .depItem {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 0.48rem; }
      .depthView .depthArea .depItem p {
        font-size: 0.32rem;
        line-height: 0.32rem;
        font-weight: 400;
        font-family: 'DIN,DIN-Regular';
        color: var(--mainFontActive);
        z-index: 2; }
      .depthView .depthArea .depItem .sellCount {
        color: var(--r1); }
      .depthView .depthArea .depItem .buyCount {
        color: var(--f1); }
      .depthView .depthArea .depItem .bgBar {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: var(--bg12);
        scale: -1; }
      .depthView .depthArea .depItem.green {
        background: #02c0761a; }
      .depthView .depthArea .depItem.red {
        background: rgba(248, 73, 96, 0.2); }
  .depthView .curPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: 'DIN,DIN-Medium';
    margin: 0.21333rem 0; }
    .depthView .curPrice .priceTxt {
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: 700;
      margin-bottom: 0.13333rem; }
    .depthView .curPrice .localPrice {
      font-size: 0.32rem;
      line-height: 0.32rem;
      font-weight: 500;
      line-height: 0.37333rem;
      color: var(--mainFont10);
      border-bottom: 1px var(--mainFont10) dashed; }
    .depthView .curPrice .green {
      color: var(--f1); }
    .depthView .curPrice .red {
      color: var(--r1); }
  .depthView .configBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 0.26667rem; }
    .depthView .configBox .showMore {
      display: flex;
      justify-content: flex-end;
      background: var(--bg18);
      padding: 0.05333rem; }
    .depthView .configBox .configButton {
      flex: 1;
      background: var(--bg18);
      margin-right: 0.66667rem;
      color: var(--mainFont);
      font-size: 0.29333rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      padding-left: 0.26667rem;
      height: 0.48rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0.26667rem; }
      .depthView .configBox .configButton .buttonImage {
        width: 0.26667rem;
        height: 0.18667rem; }
        .depthView .configBox .configButton .buttonImage svg {
          width: 100%; }

.popup {
  width: 90%;
  border-radius: 0.16rem;
  background-color: var(--fff-bg);
  padding: 0 0.72rem 0.66667rem 0.72rem; }
  .popup .title {
    font-size: 0.48rem;
    font-weight: 400;
    color: var(--active);
    margin: 0.53333rem 0;
    margin-bottom: 0.4rem;
    line-height: 0.48rem; }
  .popup .subTitle {
    font-size: 0.32rem;
    font-weight: 400;
    color: var(--f6);
    line-height: 0.48rem; }
  .popup .popContent {
    margin-top: 0.32rem;
    font-size: 0.37333rem;
    line-height: 0.37333rem; }
    .popup .popContent p {
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 400;
      line-height: 0.48rem;
      color: var(--night-font1, var(--f5)); }
  .popup .popFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.26667rem; }
    .popup .popFooter .warnTips {
      align-self: flex-start;
      font-size: 0.32rem;
      font-weight: 400;
      color: var(--f6);
      margin-bottom: 0.26667rem;
      display: flex; }
      .popup .popFooter .warnTips p {
        margin-left: 0.13333rem; }
    .popup .popFooter .confirmBtn {
      width: 100%;
      line-height: 1.01333rem;
      border-radius: 0.10667rem;
      margin-top: 0.26667rem;
      text-align: center;
      background: var(--active);
      font-size: 0.4rem;
      font-weight: 500;
      color: var(--btn-active-color); }

:global .am-modal-content {
  background-color: var(--bg2);
  padding-top: 0 !important; }
  :global .am-modal-content .am-modal-body {
    padding: 0 !important; }
  :global .am-modal-content .am-modal-button {
    color: var(--mainFontActive); }
    :global .am-modal-content .am-modal-button::before {
      top: auto !important;
      bottom: 0 !important;
      background-color: var(--mainFont10) !important; }
    :global .am-modal-content .am-modal-button:last-child::before {
      background-color: transparent !important; }
  :global .am-modal-content .am-modal-button-active {
    background-color: transparent !important; }

:global .depth-modal .am-modal-content {
  background: transparent !important;
  display: flex;
  align-items: center; }
  :global .depth-modal .am-modal-content .am-modal-body {
    background: var(--bg18);
    width: 90%;
    height: auto !important;
    min-height: 5.33333rem;
    margin: 0 auto; }
    :global .depth-modal .am-modal-content .am-modal-body .dm-box {
      padding: 0.53333rem; }
      :global .depth-modal .am-modal-content .am-modal-body .dm-box .dm-box-title {
        font-size: 0.48rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: var(--active);
        text-align: left; }
      :global .depth-modal .am-modal-content .am-modal-body .dm-box .dm-box-desc {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: var(--mainFont);
        margin-top: 0.66667rem;
        text-align: left; }
      :global .depth-modal .am-modal-content .am-modal-body .dm-box .dm-box-button {
        font-size: 0.42667rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        background: var(--active);
        border-radius: 3px;
        color: #141416;
        margin-top: 0.66667rem;
        height: 1.06667rem;
        line-height: 1.06667rem; }
