@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.page {
  font-family: PingFangSC, PingFangSC-Regular;
  width: 100%;
  overflow: hidden;
  position: relative; }
  .page .circle1 {
    width: 7.46667rem;
    position: absolute;
    top: -1.06667rem;
    right: -3.6rem;
    rotate: 9.6deg; }
    .page .circle1 img {
      width: 100%; }
  .page .circle2 {
    width: 7.46667rem;
    position: absolute;
    top: 9.06667rem;
    left: -3.86667rem;
    rotate: 9.6deg; }
    .page .circle2 img {
      width: 100%; }
  .page .tCon {
    margin-top: 1.17333rem;
    position: relative;
    padding: 0.32rem 0.69333rem; }
    .page .tCon .t1, .page .tCon .t2 {
      display: flex;
      font-size: 0.72rem;
      font-weight: bolder;
      color: var(--mainFont); }
    .page .tCon .t3 {
      width: fit-content;
      font-size: 0.29333rem;
      color: #7C7E87;
      text-decoration: underline; }
  .page .ticketCon {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem 0.53333rem;
    margin-top: 0.32rem;
    padding: 0.53333rem 0.69333rem; }
    .page .ticketCon .ticket {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #00CBFD;
      border-radius: 0.26667rem;
      padding: 0.26667rem 0.26667rem; }
      .page .ticketCon .ticket .amount {
        margin-top: 0.37333rem;
        font-size: 0.64rem;
        font-weight: bolder;
        color: #00CBFD; }
      .page .ticketCon .ticket .currency {
        text-align: center;
        font-size: 0.37333rem;
        margin-top: -0.10667rem; }
      .page .ticketCon .ticket .name {
        position: absolute;
        top: 0;
        left: 50%;
        width: 80%;
        padding: 0.13333rem 0;
        border-radius: 0.8rem;
        font-size: 0.32rem;
        white-space: nowrap;
        text-align: center;
        color: black;
        background: linear-gradient(90deg, #00F2ED 0%, #00C6FD 55%, #00A5FF 100%);
        transform: translateX(-50%) translateY(-50%); }
      .page .ticketCon .ticket .triangle {
        position: absolute;
        right: 0.02667rem;
        bottom: 0.02667rem;
        border-bottom-right-radius: 0.16rem;
        border-left: 1.2rem solid transparent;
        border-bottom: 0.93333rem solid #333333;
        z-index: 1; }
      .page .ticketCon .ticket .count {
        position: absolute;
        right: 0.16rem;
        bottom: 0.08rem;
        z-index: 2;
        color: white;
        font-size: 0.26667rem; }
  .page .infoCon {
    margin: 0.53333rem 0 0.64rem 0;
    padding: 0 0.69333rem; }
    .page .infoCon .titleCon {
      margin: auto;
      width: fit-content;
      border-bottom: 1px solid grey;
      margin-bottom: 0.21333rem; }
      .page .infoCon .titleCon .title {
        font-size: 0.48rem;
        color: #FFF;
        margin-bottom: 0.05333rem;
        font-weight: 500; }
        .page .infoCon .titleCon .title span {
          color: #49CDEE; }
    .page .infoCon .subtitle {
      font-size: 0.42667rem;
      font-weight: bolder;
      color: var(--mainFont); }
      .page .infoCon .subtitle span {
        color: #00F2ED; }
    .page .infoCon .userAmount {
      font-size: 0.34667rem;
      color: var(--mainFont);
      margin-bottom: 0.10667rem; }
      .page .infoCon .userAmount span {
        color: #00F2ED;
        font-weight: 500; }
    .page .infoCon .requireCon {
      font-size: 0.28rem;
      white-space: nowrap; }
      .page .infoCon .requireCon .amount {
        color: 888888;
        margin-right: 0.21333rem; }
      .page .infoCon .requireCon .more {
        color: #00A5FF;
        text-decoration: underline;
        font-weight: bold; }
  .page .boxes {
    min-height: 420px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-content: flex-start;
    gap: 5px;
    position: relative;
    margin-bottom: 0.64rem; }
    .page .boxes .loadingArea {
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex; }

@keyframes mySpin {
  to {
    transform: rotate(360deg); } }
      .page .boxes .loadingArea .spin {
        margin: auto;
        animation: mySpin 1s infinite linear; }
    .page .boxes .box {
      margin: auto;
      margin-top: 12px;
      width: 100%; }
      .page .boxes .box .prize {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15px;
        font-size: .33rem;
        font-weight: bold;
        color: #FFF;
        white-space: nowrap; }
        .page .boxes .box .prize img {
          width: 0.26667rem;
          margin-left: 2px; }
      .page .boxes .box .username {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15px;
        font-size: 8px;
        color: #7C7E87;
        white-space: nowrap; }
      .page .boxes .box .b {
        position: relative;
        width: 50px;
        height: 50px;
        margin: auto; }
        .page .boxes .box .b > img {
          width: 100%;
          height: 100%; }
        .page .boxes .box .b .unopen {
          position: relative; }
          .page .boxes .box .b .unopen img {
            position: absolute;
            top: -20px;
            left: -10px;
            width: 65px;
            height: 70px; }
  .page .banner {
    width: 100%;
    position: relative;
    overflow: hidden; }
    .page .banner img {
      width: 100%;
      object-fit: contain; }
    .page .banner .textBlock {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.26667rem;
      color: white;
      padding: 0 0.26667rem;
      white-space: nowrap; }
      .page .banner .textBlock .left .bigText {
        font-size: 0.42667rem;
        font-weight: bolder; }
      .page .banner .textBlock .left .smallText {
        white-space: wrap; }
      .page .banner .textBlock .btn {
        border: 1px solid #FFF;
        border-radius: 0.10667rem;
        font-size: 0.32rem;
        padding: 0.13333rem 0.26667rem; }
  .page .faqCon {
    padding: 0.4rem 0.42667rem;
    color: var(--mainFont);
    margin-top: 0.53333rem;
    margin-bottom: 2.66667rem; }
    .page .faqCon .title {
      font-size: 0.42667rem;
      color: var(--mainFont); }
    .page .faqCon details {
      padding: 0.42667rem 0;
      border-bottom: 1px solid #333333; }
      .page .faqCon details:not([open]) {
        animation-name: fold-in;
        animation-duration: .3s; }
      .page .faqCon details summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.4rem 0;
        font-size: 0.37333rem; }
      .page .faqCon details summary::-webkit-details-marker,
      .page .faqCon details summary::marker {
        content: "";
        display: none; }
      .page .faqCon details summary::after {
        content: url("../../../assets/svg/newIcon/arrow_down.svg");
        width: 0.32rem;
        height: 0.4rem;
        font-size: 0.42667rem;
        margin: 0 0.21333rem;
        cursor: pointer; }
      .page .faqCon details .content {
        color: #7C7E87;
        font-size: 0.32rem; }
      .page .faqCon details.last {
        border-bottom: none; }
    .page .faqCon details[open] summary::after {
      content: url("../../../assets/svg/newIcon/arrow_up.svg"); }
    .page .faqCon details[open] .content {
      animation-name: fold-out;
      animation-duration: .3s; }

@keyframes fold-out {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fold-in {
  0% {
    margin-bottom: 0.42667rem; }
  100% {
    margin-bottom: 0; } }
  .page.light {
    background-color: #F2F4F6;
    color: #0A0C18 !important; }
    .page.light .header {
      background-color: #F2F4F6; }
    .page.light .title span {
      color: #526AEA; }
    .page.light .faqCon {
      background-color: #EBEBEB;
      color: #0A0C18; }
      .page.light .faqCon .title {
        color: #0A0C18; }
      .page.light .faqCon details {
        background-color: #EBEBEB;
        color: #0A0C18;
        border-bottom: 0.5px solid rgba(191, 191, 191, 0.5); }
        .page.light .faqCon details.last {
          border-bottom: none; }
        .page.light .faqCon details summary::after {
          content: url("../../../assets/svg/newIcon/arrow_down_light.svg"); }
      .page.light .faqCon details[open] summary::after {
        content: url("../../../assets/svg/newIcon/arrow_up_light.svg"); }

.popup, .popupA {
  background-color: #2A2A2A;
  border-radius: 0.32rem;
  padding: 0.26667rem 0.93333rem 0.53333rem 0.93333rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: fixed;
  font-family: PingFangSC, PingFangSC-Regular;
  max-height: 70vh;
  margin: auto 0.53333rem;
  position: relative; }
  .popup .present, .popupA .present {
    position: relative;
    height: 5.86667rem;
    width: 5.86667rem; }
    .popup .present img, .popupA .present img {
      position: absolute;
      left: 0%;
      width: 100%; }
    .popup .present.animationEnd img, .popupA .present.animationEnd img {
      top: 8%; }
  .popup .congrats, .popupA .congrats {
    color: #FFF;
    font-size: 0.42667rem;
    margin-top: 0.26667rem;
    margin-bottom: 0.21333rem; }
  .popup .desc, .popupA .desc {
    font-size: 0.32rem; }
    .popup .desc u, .popupA .desc u {
      color: #FFF; }
    .popup .desc span, .popupA .desc span {
      color: #FFF; }
  .popup .sorry, .popupA .sorry {
    color: #FFF;
    font-size: 0.42667rem; }
    .popup .sorry img, .popupA .sorry img {
      padding-bottom: 0.26667rem; }
  .popup .error, .popupA .error {
    font-size: 0.32rem;
    color: #EE8E48; }
  .popup .cross, .popupA .cross {
    position: absolute;
    top: 0%;
    right: 5%; }

.popupA {
  padding: 0.53333rem;
  line-height: 0.48rem;
  color: #B6B0B1;
  font-size: 0.32rem; }
  .popupA .cross {
    position: absolute;
    top: 2%;
    right: 5%; }
  .popupA .title {
    margin-top: 0.26667rem;
    font-size: 0.42667rem;
    padding-bottom: 0.53333rem; }
  .popupA .rulesCon {
    overflow: scroll; }
    .popupA .rulesCon ol {
      list-style-type: none;
      padding: 0;
      text-align: left; }
      .popupA .rulesCon ol li {
        display: flex;
        gap: 5px;
        line-height: 0.58667rem; }

.loadingDots {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
  width: fit-content; }

.loadingDot {
  width: var(--dot-size, 10px);
  height: var(--dot-size, 10px);
  border-radius: 50%;
  margin: 0 var(--dot-spacing, 5px);
  background-color: var(--dot-color, #00CBFD);
  animation: loadingDotPulse 1s infinite ease-in-out; }

.loadingDot:nth-child(1) {
  animation-delay: 0s; }

.loadingDot:nth-child(2) {
  animation-delay: 0.2s; }

.loadingDot:nth-child(3) {
  animation-delay: 0.4s; }

@keyframes loadingDotPulse {
  0%, 80%, 100% {
    transform: scale(1); }
  50% {
    transform: scale(1.5); } }
