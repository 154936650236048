@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-cppv {
  background: var(--bg16);
  border-radius: 0.64rem 0.64rem 0px 0px;
  padding: 0 0.4rem;
  display: flex;
  flex-direction: column; }
  .module-cppv .cppv-header {
    height: 1.49333rem;
    line-height: 1.49333rem;
    text-align: center;
    border-bottom: 1px solid var(--border3); }
    .module-cppv .cppv-header .cppv-header-title {
      font-size: 0.42667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: var(--mainFont); }
    .module-cppv .cppv-header .cppv-header-close {
      position: absolute;
      right: 0.4rem;
      top: 0.53333rem;
      fill: #777E90; }
  .module-cppv .cppv-inputDataBox {
    margin-top: 0.4rem; }
    .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox {
      display: flex; }
      .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox {
        flex: 6;
        margin-right: 0.4rem; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox .cppv-inputDataBox-configBox-priceBox-title {
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #777E90; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox .cppv-inputDataBox-configBox-priceBox-input {
          background: var(--bg18);
          height: 1.01333rem;
          display: flex;
          align-items: center;
          padding: 0 0.4rem;
          border-radius: 0.10667rem;
          margin-top: 0.26667rem; }
          .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox .cppv-inputDataBox-configBox-priceBox-input .cppv-inputDataBox-configBox-priceBox-input-text {
            font-size: 0.37333rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: var(--mainFont10);
            flex: 1; }
          .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox .cppv-inputDataBox-configBox-priceBox-input input {
            background: transparent;
            border: 0;
            flex: 1;
            color: var(--mainFont);
            font-family: 'DIN,DIN-Medium'; }
          .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox .cppv-inputDataBox-configBox-priceBox-input ::-webkit-input-placeholder {
            color: var(--mainFont10); }
          .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-priceBox .cppv-inputDataBox-configBox-priceBox-input span {
            font-size: 0.37333rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #777E90; }
      .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-typeBox {
        flex: 4; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-typeBox .cppv-inputDataBox-configBox-typeBox-title {
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #777E90; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-typeBox .cppv-inputDataBox-configBox-typeBox-dd {
          margin-top: 0.26667rem; }
          .module-cppv .cppv-inputDataBox .cppv-inputDataBox-configBox .cppv-inputDataBox-configBox-typeBox .cppv-inputDataBox-configBox-typeBox-dd .dv-option {
            justify-content: flex-start;
            margin-left: 0.4rem; }
    .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput {
      margin-top: 0.53333rem; }
      .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-hint {
        color: #F5465D;
        margin-top: 0.13333rem; }
      .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-title {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #777E90; }
      .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-input {
        background: var(--bg18);
        height: 1.17333rem;
        display: flex;
        align-items: center;
        padding: 0 0.4rem;
        border-radius: 0.10667rem;
        margin-top: 0.26667rem; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-input.red {
          border: 1px solid #F5465D; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-input input {
          background: transparent;
          border: 0;
          flex: 1; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-input ::-webkit-input-placeholder {
          color: #353945; }
        .module-cppv .cppv-inputDataBox .cppv-inputDataBox-volumeInput .cppv-inputDataBox-volumeInput-input span {
          font-size: 0.37333rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #777E90; }
  .module-cppv .cppv-percentBox {
    display: flex;
    justify-content: space-between;
    margin-top: 0.26667rem; }
    .module-cppv .cppv-percentBox .cppv-percentBox-block {
      flex: 1;
      margin-right: 7px; }
      .module-cppv .cppv-percentBox .cppv-percentBox-block:nth-child(5) {
        margin-right: 0px; }
      .module-cppv .cppv-percentBox .cppv-percentBox-block .cppv-percentBox-block-line {
        height: 0.26667rem;
        width: 100%;
        background: #23262F; }
        .module-cppv .cppv-percentBox .cppv-percentBox-block .cppv-percentBox-block-line.active {
          background: #FFD140; }
      .module-cppv .cppv-percentBox .cppv-percentBox-block .cppv-percentBox-block-text {
        font-size: 0.32rem;
        font-family: 'DIN,DIN-Medium';
        font-weight: 500;
        color: #777E90;
        text-align: center;
        margin-top: 0.16rem; }
  .module-cppv .cppv-infoBox {
    display: flex;
    justify-content: space-between;
    margin-top: 0.4rem; }
    .module-cppv .cppv-infoBox .cppv-infoBox-title {
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: var(--mainFont10); }
    .module-cppv .cppv-infoBox .cppv-infoBox-content {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500; }
  .module-cppv .cppv-submit {
    font-size: 0.42667rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    height: 1.06667rem;
    color: #141416;
    background-color: var(--active);
    border-radius: 0.08rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.53333rem;
    margin-bottom: 0.53333rem; }
  .module-cppv .red {
    color: var(--r1); }
  .module-cppv .green {
    color: var(--f1); }
  .module-cppv .white {
    color: var(--mainFont); }
