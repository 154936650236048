@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.paginationContainer {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #E7E9EC; }
  .paginationContainer .paginationItem {
    width: 0.85333rem;
    margin: 0 0.05333rem;
    padding: 0.17067rem 0.21333rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.32rem; }
    .paginationContainer .paginationItem.dots:hover {
      background-color: transparent;
      cursor: default; }
    .paginationContainer .paginationItem:hover {
      background-color: #2729316b;
      cursor: pointer; }
    .paginationContainer .paginationItem.selected {
      background-color: #272931; }
    .paginationContainer .paginationItem .arrow::before {
      position: relative;
      content: '';
      display: inline-block;
      width: 0.18667rem;
      height: 0.18667rem;
      border-right: 0.0512rem solid #E7E9EC;
      border-top: 0.0512rem solid #E7E9EC; }
    .paginationContainer .paginationItem .arrow.left {
      transform: rotate(-135deg) translate(-50%); }
    .paginationContainer .paginationItem .arrow.right {
      transform: rotate(45deg); }
    .paginationContainer .paginationItem.disabled {
      pointer-events: none; }
      .paginationContainer .paginationItem.disabled .arrow::before {
        border-right: 0.0512rem solid rgba(0, 0, 0, 0.43);
        border-top: 0.0512rem solid rgba(0, 0, 0, 0.43); }
      .paginationContainer .paginationItem.disabled:hover {
        background-color: transparent;
        cursor: default; }
