@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.stage-cAddBank {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .stage-cAddBank .cab-main {
    flex: 1;
    margin-top: 1.6rem; }
    .stage-cAddBank .cab-main .cab-main-block {
      margin: 0 0.4rem 0.8rem; }
      .stage-cAddBank .cab-main .cab-main-block .cab-main-block-title {
        font-size: 0.4rem;
        font-weight: 500;
        color: var(--mainFontActive); }
      .stage-cAddBank .cab-main .cab-main-block .cab-main-block-inputBox {
        margin-top: 0.4rem;
        background: var(--bg6);
        border-radius: 0.10667rem; }
        .stage-cAddBank .cab-main .cab-main-block .cab-main-block-inputBox .cab-main-block-inputBox-name, .stage-cAddBank .cab-main .cab-main-block .cab-main-block-inputBox input {
          background: transparent;
          border: 0;
          outline: none;
          box-shadow: none;
          color: #777E90;
          height: 1.01333rem; }
        .stage-cAddBank .cab-main .cab-main-block .cab-main-block-inputBox .cab-main-block-inputBox-name {
          margin-left: 0.29333rem;
          display: flex;
          align-items: center; }
        .stage-cAddBank .cab-main .cab-main-block .cab-main-block-inputBox input::placeholder {
          color: #353945; }
    .stage-cAddBank .cab-main .cab-main-tips {
      display: flex;
      background: var(--bg2);
      border-radius: 0.10667rem;
      margin: 0 0.4rem; }
      .stage-cAddBank .cab-main .cab-main-tips .cab-main-tips-left {
        width: 0.37333rem;
        height: 0.37333rem;
        margin-top: 0.4rem;
        margin-left: 0.4rem; }
        .stage-cAddBank .cab-main .cab-main-tips .cab-main-tips-left svg {
          width: 100%; }
      .stage-cAddBank .cab-main .cab-main-tips .cab-main-tips-right {
        margin-right: 0.4rem;
        margin-left: 0.26667rem; }
        .stage-cAddBank .cab-main .cab-main-tips .cab-main-tips-right .cab-main-tips-right-title {
          color: var(--active);
          font-size: 0.32rem;
          font-weight: 400;
          margin-top: 0.34667rem; }
        .stage-cAddBank .cab-main .cab-main-tips .cab-main-tips-right .cab-main-tips-right-content {
          font-size: 0.32rem;
          color: #777E90;
          font-weight: 400;
          margin-top: 0.26667rem;
          margin-bottom: 0.4rem; }
  .stage-cAddBank .cab-bottom {
    margin-bottom: 1.06667rem;
    padding: 0 0.4rem;
    margin-top: 2.4rem; }
    .stage-cAddBank .cab-bottom .cab-bottom-desc {
      color: #777E90;
      font-size: 0.32rem;
      font-weight: 400;
      line-height: 0.48rem; }
    .stage-cAddBank .cab-bottom .cab-bottom-btn {
      height: 1.06667rem;
      line-height: 1.06667rem;
      background: var(--active);
      text-align: center;
      font-size: 0.42667rem;
      font-weight: 500;
      margin-top: 0.8rem;
      margin-bottom: 1.06667rem;
      border-radius: 0.08rem; }
