@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-additionMarginView {
  background: var(--bg16);
  border-radius: 0.64rem 0.64rem 0px 0px;
  padding: 0 0.4rem 0.53333rem; }
  .module-additionMarginView .amv-header {
    height: 1.49333rem;
    line-height: 1.49333rem;
    text-align: center;
    border-bottom: 1px solid var(--border3);
    position: relative; }
    .module-additionMarginView .amv-header .amv-header-title {
      font-size: 0.42667rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #E6E8EC; }
    .module-additionMarginView .amv-header .amv-header-close {
      position: absolute;
      right: 0.4rem;
      top: 0.53333rem;
      fill: #777E90; }
  .module-additionMarginView .amv-marginBox {
    display: flex;
    margin-top: 0.26667rem; }
    .module-additionMarginView .amv-marginBox .amv-marginBox-dropdown {
      margin-right: 0.4rem; }
    .module-additionMarginView .amv-marginBox .amv-marginBox-row {
      display: flex;
      flex: 1;
      align-items: center;
      background: var(--bg18);
      border-radius: 0.10667rem; }
      .module-additionMarginView .amv-marginBox .amv-marginBox-row .amv-marginBox-row-inputBox {
        flex: 1; }
      .module-additionMarginView .amv-marginBox .amv-marginBox-row .amv-marginBox-row-desc {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #FFD140;
        margin-right: 0.26667rem; }
  .module-additionMarginView .amv-infoBox .amv-infoBox-row {
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-between; }
    .module-additionMarginView .amv-infoBox .amv-infoBox-row .amv-infoBox-row-title {
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 500;
      color: #777E90;
      line-height: 1; }
    .module-additionMarginView .amv-infoBox .amv-infoBox-row .amv-infoBox-row-content {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      font-weight: 500;
      color: var(--mainFont);
      line-height: 1; }
  .module-additionMarginView .amv-submit {
    margin-top: 0.8rem;
    height: 1.06667rem;
    line-height: 1.06667rem;
    text-align: center;
    font-size: 0.42667rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #141416;
    background: #FFD140;
    border-radius: 0.08rem; }

.module-popoverSelectView {
  background: var(--bg18);
  height: 1.01333rem;
  display: flex;
  padding: 0 0.4rem; }
  .module-popoverSelectView .psv-block {
    display: flex;
    align-items: center; }
    .module-popoverSelectView .psv-block .psv-block-title {
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: var(--mainFont); }
    .module-popoverSelectView .psv-block .psv-block-image {
      width: 0.21333rem;
      height: 0.16rem;
      margin-left: 0.42667rem; }
      .module-popoverSelectView .psv-block .psv-block-image svg {
        width: 100%;
        height: 100%; }
  .module-popoverSelectView .am-popover-inner {
    background: #23262F; }
    .module-popoverSelectView .am-popover-inner .am-popover-inner-wrapper {
      background: #23262F; }
      .module-popoverSelectView .am-popover-inner .am-popover-inner-wrapper .am-popover-item {
        background: #23262F; }
        .module-popoverSelectView .am-popover-inner .am-popover-inner-wrapper .am-popover-item .am-popover-item-container::before {
          background: transparent !important; }
