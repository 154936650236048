@keyframes modalShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    top: -180px;
  }
  to {
    top: 0;
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  animation: modalShow forwards 100ms linear;

  .content {
    width: 100%;
    //height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: contentShow forwards 250ms ease-out;
  }

  @keyframes footerShow {
    0% {
      bottom: -180px;
    }
    100% {
      bottom: 0;
    }
  }

  .footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    animation: footerShow forwards 250ms ease-out;
  }
}
