@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-dropdownView {
  background: var(--bg18);
  border-radius: 0.10667rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 2rem; }
  .module-dropdownView .dv-option {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center; }
    .module-dropdownView .dv-option .dv-option-selected {
      height: 1.01333rem;
      line-height: 1.01333rem;
      color: var(--mainFont);
      font-size: 0.37333rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      display: flex; }
      .module-dropdownView .dv-option .dv-option-selected .dv-option-selected-img {
        width: 0.4rem;
        margin-left: 0.4rem;
        margin-right: 0.13333rem;
        display: flex; }
        .module-dropdownView .dv-option .dv-option-selected .dv-option-selected-img img {
          width: 100%; }
    .module-dropdownView .dv-option .dv-option-image {
      width: 0.26667rem;
      height: 0.18667rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 10px;
      margin: auto 0.37333rem auto auto; }
      .module-dropdownView .dv-option .dv-option-image svg {
        width: 100%;
        fill: #777E90; }
  .module-dropdownView .dv-list {
    position: absolute;
    background: var(#23262F, #E6E8EC);
    top: 1.01333rem;
    width: 98%;
    display: none;
    z-index: 1;
    left: 1%; }
    .module-dropdownView .dv-list .dv-list-cell {
      background: var(--bg16);
      height: 1.01333rem;
      line-height: 1.01333rem;
      color: var(--mainFont);
      display: flex;
      align-items: center;
      justify-content: center; }
      .module-dropdownView .dv-list .dv-list-cell .dv-list-cell-text {
        font-size: 0.37333rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400; }
      .module-dropdownView .dv-list .dv-list-cell .dv-list-cell-img {
        display: flex;
        width: 0.4rem;
        height: 0.4rem;
        margin-left: 0.4rem;
        margin-right: 0.13333rem; }
        .module-dropdownView .dv-list .dv-list-cell .dv-list-cell-img img {
          width: 100%; }
    .module-dropdownView .dv-list .dv-list-cell.active {
      color: var(--active); }
  .module-dropdownView .dv-list.show {
    display: block; }
  .module-dropdownView .disabled {
    color: var(--mainFont10); }
