@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0.53333rem;
  flex: 1; }
  .mainPage .orderTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0rem 0.26667rem; }
    .mainPage .orderTitle .pageTabs {
      width: 58%;
      font-size: 0.4rem;
      line-height: 0.4rem;
      font-weight: 600;
      color: var(--mainFontActive); }
  .mainPage .cancelBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0.26667rem 0rem;
    border-top: 0.02667rem solid var(--bg6);
    border-bottom: 0.02667rem solid var(--bg6); }
    .mainPage .cancelBar .hideBox {
      display: flex;
      align-items: center; }
      .mainPage .cancelBar .hideBox img {
        width: 0.4rem;
        margin-right: 0.26667rem; }
      .mainPage .cancelBar .hideBox .hideTxt {
        font-size: 0.32rem;
        line-height: 0.32rem;
        font-weight: 400;
        font-family: var(--pRFont);
        color: var(--mainFont10); }
    .mainPage .cancelBar .cancelBtn {
      padding: 0.16rem 0.32rem;
      font-size: 0.37333rem;
      line-height: 0.37333rem;
      font-weight: 400;
      font-family: var(--pRFont);
      color: var(--active);
      background-color: var(--bg6);
      display: flex; }
  .mainPage .positionBox {
    flex: 1;
    min-height: 6.66667rem;
    width: 100%; }
  .mainPage .listCon {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    width: 100%;
    padding-bottom: 0.53333rem;
    min-height: 6.66667rem;
    position: relative; }
    .mainPage .listCon .listItem {
      display: flex;
      padding: 0.26667rem 0rem;
      border-bottom: 0.02667rem solid var(--bg6); }
      .mainPage .listCon .listItem .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%; }
        .mainPage .listCon .listItem .info.buy {
          color: var(--f1); }
        .mainPage .listCon .listItem .info.sell {
          color: var(--r1); }
        .mainPage .listCon .listItem .info p {
          display: flex;
          align-items: flex-start; }
        .mainPage .listCon .listItem .info .progressArea {
          margin-top: 0.26667rem;
          position: relative;
          font-size: 0.34667rem;
          line-height: 0.34667rem;
          font-weight: 500;
          font-family: var(--pMFont); }
          .mainPage .listCon .listItem .info .progressArea .bigCircle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            justify-content: center;
            mask: radial-gradient(transparent 0.50667rem, var(--bg6) 0.50667rem);
            width: 1.17333rem;
            height: 1.17333rem;
            overflow: hidden;
            border-radius: 50%;
            position: relative; }
            .mainPage .listCon .listItem .info .progressArea .bigCircle .left {
              width: 50%;
              height: 100%;
              background: #24B39B;
              transform-origin: 100% 50%;
              position: absolute;
              left: 0;
              z-index: 0; }
            .mainPage .listCon .listItem .info .progressArea .bigCircle .right {
              width: 50%;
              height: 100%;
              background: #24B39B;
              transition: transform 1s linear;
              transform-origin: 0% 50%;
              position: absolute;
              right: 0;
              z-index: 2; }
            .mainPage .listCon .listItem .info .progressArea .bigCircle .bottomLeft {
              width: 50%;
              height: 100%;
              background: #ea430f;
              position: absolute;
              left: 0;
              z-index: -1; }
            .mainPage .listCon .listItem .info .progressArea .bigCircle .bottomRight {
              width: 50%;
              height: 100%;
              background: #ea430f;
              position: absolute;
              right: 0;
              z-index: 1; }
          .mainPage .listCon .listItem .info .progressArea .info {
            width: 1.17333rem;
            height: 1.17333rem;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            font-size: 0.29333rem;
            line-height: 0.29333rem;
            font-weight: 500;
            font-family: 'DIN,DIN-Medium';
            color: #02c076; }
      .mainPage .listCon .listItem .orderInfo {
        flex: 1;
        display: flex;
        flex-direction: column; }
        .mainPage .listCon .listItem .orderInfo .titleBar {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.26667rem;
          height: 0.50667rem;
          align-items: center; }
          .mainPage .listCon .listItem .orderInfo .titleBar p {
            font-size: 0.34667rem;
            line-height: 0.34667rem;
            font-weight: 500;
            font-family: 'DIN,DIN-Medium';
            color: var(--mainFont10);
            display: flex;
            align-items: center; }
            .mainPage .listCon .listItem .orderInfo .titleBar p:first-child {
              font-size: 0.37333rem;
              color: var(--mainFontActive); }
          .mainPage .listCon .listItem .orderInfo .titleBar .tag {
            font-size: 0.29333rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #fff;
            display: inline-block;
            border-radius: 0.05333rem;
            margin-right: 0.13333rem;
            padding: 0 0.13333rem; }
            .mainPage .listCon .listItem .orderInfo .titleBar .tag.buy {
              background: var(--f1); }
            .mainPage .listCon .listItem .orderInfo .titleBar .tag.sell {
              background: var(--r1); }
        .mainPage .listCon .listItem .orderInfo .info {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 0.26667rem; }
          .mainPage .listCon .listItem .orderInfo .info .block {
            font-size: 0.29333rem;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #fff;
            display: inline-block;
            border-radius: 0.05333rem;
            margin-right: 0.13333rem;
            padding: 0 0.13333rem; }
            .mainPage .listCon .listItem .orderInfo .info .block.buy {
              background: var(--f1); }
            .mainPage .listCon .listItem .orderInfo .info .block.sell {
              background: var(--r1); }
        .mainPage .listCon .listItem .orderInfo .infoArea {
          display: flex;
          justify-content: space-between;
          align-items: flex-end; }
          .mainPage .listCon .listItem .orderInfo .infoArea .detail {
            display: flex;
            flex-direction: column; }
            .mainPage .listCon .listItem .orderInfo .infoArea .detail p {
              font-size: 0.37333rem;
              line-height: 0.37333rem;
              font-weight: 400;
              font-family: var(--pRFont);
              color: var(--mainFont10);
              white-space: nowrap; }
              .mainPage .listCon .listItem .orderInfo .infoArea .detail p:not(:last-child) {
                margin-bottom: 0.13333rem; }
              .mainPage .listCon .listItem .orderInfo .infoArea .detail p i {
                font-family: 'DIN,DIN-Medium';
                font-weight: 500;
                color: var(--mainFontActive);
                margin-left: 0.26667rem; }
          .mainPage .listCon .listItem .orderInfo .infoArea .btn {
            padding: 0.16rem 0.32rem;
            font-size: 0.34667rem;
            line-height: 0.34667rem;
            font-weight: 400;
            font-family: var(--pRFont);
            color: var(--active);
            background-color: var(--bg6); }
    .mainPage .listCon .holdListItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 0.53333rem 0rem 0; }
      .mainPage .listCon .holdListItem .holdTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 0.21333rem; }
        .mainPage .listCon .holdListItem .holdTitle .dirBtn, .mainPage .listCon .holdListItem .holdTitle .timesTag {
          font-size: 0.29333rem;
          line-height: 0.29333rem;
          font-weight: 400;
          font-family: var(--pRFont);
          color: #ffffff;
          padding: 0.06667rem 0.13333rem;
          border-radius: 0.05333rem; }
        .mainPage .listCon .holdListItem .holdTitle .buy {
          background-color: #02C076; }
        .mainPage .listCon .holdListItem .holdTitle .sell {
          background-color: #F84960; }
        .mainPage .listCon .holdListItem .holdTitle .currency {
          font-size: 0.42667rem;
          line-height: 0.42667rem;
          font-weight: 500;
          font-family: 'DIN,DIN-Medium';
          color: var(--mainFontActive);
          margin: 0 0.26667rem; }
        .mainPage .listCon .holdListItem .holdTitle .timesTag {
          background-color: var(--active);
          color: var(--btn-active-color); }
      .mainPage .listCon .holdListItem .holdResult {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between; }
        .mainPage .listCon .holdListItem .holdResult span:last-child {
          text-align: right; }
        .mainPage .listCon .holdListItem .holdResult p {
          font-size: 0.32rem;
          line-height: 0.32rem;
          font-weight: 400;
          font-family: var(--pRFont);
          color: var(--mainFont10); }
          .mainPage .listCon .holdListItem .holdResult p:last-child {
            font-size: 0.48rem;
            line-height: 0.48rem;
            font-weight: 500;
            font-family: 'DIN,DIN-Medium';
            margin-top: 0.21333rem; }
        .mainPage .listCon .holdListItem .holdResult .titleBar {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.26667rem; }
          .mainPage .listCon .holdListItem .holdResult .titleBar p {
            font-size: 0.34667rem;
            line-height: 0.34667rem;
            font-weight: 500;
            font-family: 'DIN,DIN-Medium';
            color: var(--mainFont10); }
            .mainPage .listCon .holdListItem .holdResult .titleBar p:first-child {
              font-size: 0.37333rem;
              color: var(--mainFontActive); }
        .mainPage .listCon .holdListItem .holdResult .infoArea {
          display: flex;
          justify-content: space-between;
          align-items: flex-end; }
          .mainPage .listCon .holdListItem .holdResult .infoArea .detail {
            display: flex;
            flex-direction: column; }
            .mainPage .listCon .holdListItem .holdResult .infoArea .detail p {
              font-size: 0.37333rem;
              line-height: 0.37333rem;
              font-weight: 400;
              font-family: var(--pRFont);
              color: var(--mainFont10);
              white-space: nowrap; }
              .mainPage .listCon .holdListItem .holdResult .infoArea .detail p:not(:last-child) {
                margin-bottom: 0.13333rem; }
              .mainPage .listCon .holdListItem .holdResult .infoArea .detail p i {
                font-family: 'DIN,DIN-Medium';
                font-weight: 500;
                color: var(--mainFontActive);
                margin-left: 0.26667rem; }
          .mainPage .listCon .holdListItem .holdResult .infoArea .btn {
            padding: 0.16rem 0.32rem;
            font-size: 0.34667rem;
            line-height: 0.34667rem;
            font-weight: 400;
            font-family: var(--pRFont);
            color: var(--active);
            background-color: var(--bg6); }
        .mainPage .listCon .holdListItem .holdResult .raise p:last-child {
          color: #02c076; }
        .mainPage .listCon .holdListItem .holdResult .fall p:last-child {
          color: #f84960; }
      .mainPage .listCon .holdListItem .holdDetail {
        margin: 0.4rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap; }
        .mainPage .listCon .holdListItem .holdDetail .detailItem {
          width: 33%; }
          .mainPage .listCon .holdListItem .holdDetail .detailItem:nth-child(-n+3) {
            margin-bottom: 0.4rem; }
          .mainPage .listCon .holdListItem .holdDetail .detailItem p {
            font-size: 0.32rem;
            line-height: 0.32rem;
            font-weight: 400;
            font-family: var(--pRFont);
            color: var(--mainFont10); }
            .mainPage .listCon .holdListItem .holdDetail .detailItem p:last-child {
              color: var(--mainFontActive);
              font-weight: 500;
              margin-top: 0.10667rem; }
          .mainPage .listCon .holdListItem .holdDetail .detailItem:nth-child(3n) {
            display: flex;
            flex-direction: column;
            align-items: flex-end; }
      .mainPage .listCon .holdListItem .btnArea {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between; }
        .mainPage .listCon .holdListItem .btnArea span {
          padding: 0.18667rem 0.57333rem;
          font-size: 0.34667rem;
          line-height: 0.34667rem;
          font-weight: 400;
          font-family: var(--pRFont);
          background-color: var(--bg6);
          color: var(--mainFontActive);
          border-radius: 0.05333rem; }
    .mainPage .listCon .no_login {
      position: absolute;
      top: 2.13333rem;
      left: 50%;
      transform: translateX(-50%); }
      .mainPage .listCon .no_login a {
        text-decoration: underline;
        color: var(--active);
        margin: 0 0.13333rem; }

.mainPage.light .cancelBar .cancelBtn {
  color: #23262F;
  background-color: var(--active); }

.mainPage.light .listCon .listItem {
  display: flex;
  padding: 0.26667rem 0rem;
  border-bottom: 0.02667rem solid var(--bg6); }
  .mainPage.light .listCon .listItem .orderInfo .infoArea .btn {
    color: #23262F;
    background-color: var(--active); }
