@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.module-guide {
  background: var(--pbg3);
  margin: 0 0.88rem;
  max-width: 90%;
  min-height: 14.28rem;
  display: flex;
  flex-direction: column; }
  .module-guide .g-img0 {
    width: 6.73333rem;
    margin: 0.66667rem auto 0; }
    .module-guide .g-img0 img {
      width: 100%; }
  .module-guide .g-img1 {
    width: 4.62667rem;
    margin: 0.66667rem auto 0; }
    .module-guide .g-img1 img {
      width: 100%; }
  .module-guide .g-img2 {
    width: 4.53333rem;
    margin: 0.66667rem auto 0; }
    .module-guide .g-img2 img {
      width: 100%; }
  .module-guide .g-img3 {
    width: 4.81333rem;
    margin: 0.66667rem auto 0; }
    .module-guide .g-img3 img {
      width: 100%; }
  .module-guide .g-desc {
    font-size: 0.37333rem;
    font-family: 'DIN,DIN-Medium';
    color: var(--pFont5);
    margin: 0.8rem 0.66667rem 0; }
  .module-guide .g-space {
    flex: 1; }
  .module-guide .g-dotBox {
    display: flex;
    margin: auto; }
    .module-guide .g-dotBox .g-dotBox-dot {
      background: var(--pbg5);
      width: 0.16rem;
      height: 0.16rem;
      border-radius: 0.16rem;
      margin-right: 0.13333rem; }
      .module-guide .g-dotBox .g-dotBox-dot.active {
        background: var(--pbg6); }
  .module-guide .g-btnBox {
    margin: 0.66667rem 0.66667rem 0; }
    .module-guide .g-btnBox .g-btnBox-btn {
      font-size: 0.37333rem;
      font-family: 'DIN,DIN-Medium';
      height: 0.90667rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .module-guide .g-btnBox .g-btnBox-btn.next {
        color: var(--pFont6);
        background: var(--active); }
      .module-guide .g-btnBox .g-btnBox-btn.done {
        color: var(--active); }
