@charset "UTF-8";
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/***/
/**主背景*/
/**新设计稿主背景*/
/**次级框背景*/
/**头部背景*/
/**线条背景*/
/**底部栏(固定)背景*/
/***/
/***/
/***/
/**头部tabbar颜色*/
/***/
/**字体*/
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .root_include_ru {
    margin-top: 1.06667rem; }
  .footer {
    padding-bottom: 0.26667rem !important; } }

.btnTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: 0.10667rem;
  padding: 0.05333rem;
  font-size: 0.4rem;
  font-weight: 500;
  font-family: var(--pMFont); }
  .btnTabs .btnTabItem, .btnTabs .btnActiveItem {
    padding: 0.18667rem 0.32rem;
    color: var(--mainFont10);
    white-space: nowrap; }
    .btnTabs .btnTabItem .styleEn, .btnTabs .btnActiveItem .styleEn {
      font-family: 'DIN,DIN-Medium'; }
    .btnTabs .btnTabItem .styleZn, .btnTabs .btnActiveItem .styleZn {
      font-family: var(--pMFont); }
  .btnTabs .btnActiveItem {
    color: var(--mainFontActive);
    background-color: var(--bg6); }

.bskTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: inherit; }
  .bskTabs .tabItem, .bskTabs .tabItemActive {
    position: relative;
    color: var(--f6);
    display: flex;
    align-items: center;
    white-space: nowrap; }
    .bskTabs .tabItem img, .bskTabs .tabItemActive img {
      margin-left: 0.10667rem; }
    .bskTabs .tabItem::after, .bskTabs .tabItemActive::after {
      content: "";
      position: absolute;
      bottom: 0rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.4rem;
      background-color: var(--active);
      height: 0.08rem;
      opacity: 0; }
  .bskTabs .tabItemActive {
    font-weight: bold;
    color: var(--mainFont7); }
    .bskTabs .tabItemActive::after {
      content: "";
      position: absolute;
      bottom: 0rem;
      left: 50%;
      transform: translateX(-50%);
      width: 0.4rem;
      background-color: var(--active);
      height: 0.08rem;
      opacity: 1;
      transition: opacity 0.5s;
      bottom: -0.26667rem; }
  .bskTabs .dipositTab {
    color: var(--mainFont7);
    background: var(--active); }
  .bskTabs.lightTheme .tabItem, .bskTabs.lightTheme .tabItemActive {
    color: var(--t7); }
  .bskTabs.lightTheme .tabItemActive {
    color: #000; }
  .bskTabs .extraMsgStyle {
    border-radius: 50%;
    margin-left: 0.08rem;
    background-color: var(--mainFont);
    color: var(--night-tipsDisplay, #777e90);
    padding-left: 0.05333rem;
    padding-right: 0.05333rem;
    padding-top: 0.02667rem;
    padding-bottom: 0.02667rem;
    font-family: 'DIN,DIN-Regular';
    font-size: 0.26667rem;
    line-height: 0.26667rem;
    font-weight: 400; }
  .bskTabs .hide {
    display: none;
    visibility: hidden; }

.slider {
  width: 90%;
  padding-left: 0 !important;
  overflow-x: auto; }
  .slider span {
    width: max-content;
    padding: 0 0.13333rem; }
  .slider span:first-child {
    padding-left: 0; }
